import React, { useState, useEffect } from "react";
import {
  Link,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import {
  makeStyles,
  Grid,
  Button,
  Modal,
  Paper
} from "@material-ui/core";
import cogoToast from "cogo-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faTrashAlt,
  faTimes,
  faPlus,
  faUserCheck,
  faUserTimes,
  faUserGraduate,
  faUser,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import Avatar from "react-avatar";

import "./UserProfile.scss";
import UsersAPI from "api/users";
import SurgeriesAPI from "api/surgeries";
import SurgeriesSvc from "service/surgeries";
import Util from "service/util";
import SurgeriesGrid from "components/userprofile/SurgeriesGrid";
import SurgeriesTab from "components/userprofile/SurgeriesTab";
import SubscriptionsTab from "components/userprofile/SubscriptionsTab";

import defImg from "images/cover-photos/default.png";
import rotatorcuff from "images/cover-photos/surgery-cover-01.png";
import labrum from "images/cover-photos/surgery-cover-02.png";
import acl from "images/cover-photos/surgery-cover-03.png";
import meniscus from "images/cover-photos/surgery-cover-04.png";

const roles = {
  user: "Viewer",
  broadcaster: "Broadcaster",
};

const UserProfile = (props) => {
  const [profile, setProfile] = useState(null);
  const [surgeries, setSurgeries] = useState({ upcoming: null, recent: null, all : null });
  const classes = useStyles();
  const isLoggedIn = profile && profile.user_id ? true : false;

  const apis = {
    user: UsersAPI.getViewerProfile,
    broadcaster: UsersAPI.getBroadcasterProfile,
  };

  const getProfile = () => {
    apis[props.role]
      .apply(this, [props.match.params.id])
      .then(({ data, err }) => {
        if (err) {
          setProfile({});
          cogoToast.error("Problem getting profile information");
          console.error("Error getting profile info: ", err);
          return;
        }

        setProfile(data);
      });
  };

  const getSurgeries = () => {
    SurgeriesAPI.listForViewer(null, props.match.params.id).then((res) => {
      const { data, err } = res;

      if (err) {
        setSurgeries({ upcoming: [], recent: [], all : [] });
        console.error("Error getting surgeries: ", err);
        cogoToast.error("Problem getting surgeries");
        return;
      }

      const upcoming = data ? SurgeriesSvc.getUpcomingSurgeries(data) : [];
      const recent = data ? SurgeriesSvc.getPreviousSurgeries(data) : [];
      const recentRev = recent ? recent.reverse() : [];
      const all = [].concat(upcoming).concat(recentRev);
      setSurgeries({ upcoming, recent: recentRev, all });
    });
  };

  useEffect(() => {
    if (!props.role) {
      return;
    }

    getProfile();
    getSurgeries();
  }, [props.match.params.id]);

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  const handleShowDelete = () => {
    setIsDeleteVisible(true);
  };

  const handleHideDelete = () => {
    setIsDeleteVisible(false);
  };

  const handleConfirmDelete = () => {
    const userType = roles[props.role];

    UsersAPI.remove(props.match.params.id).then(({ err }) => {
      if (err) {
        cogoToast.error(`Problem deleting this ${userType}`);
        return;
      }

      cogoToast.success(`Deleted the ${userType}`);

      const to = props.role === "user" ? "/app/viewers" : "/app/broadcasters";
      props.history.push(to);
    });
  };

  const [isActivateConfirmVisible, setIsActivateConfirmVisible] = useState(
    false
  );

  const handleShowActivateConfirm = () => {
    setIsActivateConfirmVisible(true);
  };

  const handleHideActivateConfirm = () => {
    setIsActivateConfirmVisible(false);
  };

  const handleConfirmActivate = () => {
    UsersAPI.activateBroadcaster({
      id: props.match.params.id,
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
    }).then((response) => {
      if (!response) {
        cogoToast.error("There was a problem activating this broadcaster");
        console.error("Problem activating broadcaster: no response");
        return;
      }

      const { err } = response;

      if (err) {
        const errMsg =
          err.data && err.data.Error
            ? err.data.Error
            : "Problem activating broadcaster";
        cogoToast.error(errMsg, { hideAfter: 4 });
        console.error("Problem activating broadcaster: ", err);
        return;
      }

      handleHideActivateConfirm();
      cogoToast.success("Broadcaster activated", { hideAfter: 4 });
      getProfile();
    });
  };

  const [isDeactivateConfirmVisible, setIsDeactivateConfirmVisible] = useState(
    false
  );

  const handleShowDeactivateConfirm = () => {
    setIsDeactivateConfirmVisible(true);
  };

  const handleHideDeactivateConfirm = () => {
    setIsDeactivateConfirmVisible(false);
  };

  const handleConfirmDeactivate = () => {
    UsersAPI.deactivateBroadcaster({ id: props.match.params.id }).then(
      (response) => {
        if (!response) {
          cogoToast.error("There was a problem deactivating this broadcaster");
          console.error("Problem deactivating broadcaster: no response");
          return;
        }

        const { err } = response;

        if (err) {
          const errMsg =
            err.data && err.data.Error
              ? err.data.Error
              : "Problem deactivating broadcaster";
          cogoToast.error(errMsg, { hideAfter: 4 });
          console.error("Problem deactivating broadcaster: ", err);
          return;
        }

        handleHideDeactivateConfirm();
        cogoToast.success("Broadcaster deactivated", { hideAfter: 4 });
        getProfile();
      }
    );
  };

  const [isPromoteVisible, setIsPromoteVisible] = useState(false);

  const handleShowPromote = () => {
    setIsPromoteVisible(true);
  };

  const handleHidePromote = () => {
    setIsPromoteVisible(false);
  };

  const handleConfirmPromote = () => {
    UsersAPI.promoteUser(props.match.params.id).then((response) => {
      if (!response) {
        cogoToast.error(
          "There was a problem promoting this user to broadcaster"
        );
        console.error("Problem promoting user to broadcaster: no response");
        return;
      }

      const { err } = response;

      if (err) {
        const errMsg =
          err.data && err.data.Error
            ? err.data.Error
            : "Problem promoting user";
        cogoToast.error(errMsg, { hideAfter: 4 });
        console.error("Problem promoting user: ", err);
        return;
      }

      handleHidePromote();
      cogoToast.success(
        `User promoted to broadcaster. Don't forget to activate the account (go to Broadcasters page)`,
        { hideAfter: 8 }
      );
      getProfile();
    });
  };

  const [isDowngradeVisible, setIsDowngradeVisible] = useState(false);

  const handleShowDowngrade = () => {
    setIsDowngradeVisible(true);
  };

  const handleHideDowngrade = () => {
    setIsDowngradeVisible(false);
  };

  const handleConfirmDowngrade = () => {
    UsersAPI.downgradeUser(props.match.params.id).then((response) => {
      if (!response) {
        cogoToast.error(
          "There was a problem downgrading this broadcaster to viewer"
        );
        console.error(
          "Problem downgrading this broadcaster to viewer: no response"
        );
        return;
      }

      const { error } = response;

      if (error) {
        const errMsg =
          error.data && error.data.Error
            ? error.data.Error
            : "Problem downgrading user";
        cogoToast.error(errMsg, { hideAfter: 4 });
        console.error("Problem downgrading user: ", error);
        return;
      }

      handleHideDowngrade();
      cogoToast.success("Broadcaster downgraded to viewer", { hideAfter: 8 });
      props.history.push("/app/broadcasters");
    });
  };

  const handleFollowing = (isFollowing) => {
    // If not logged in, redirect to register
    if (!isLoggedIn) {
      props.history.push(`/signup?lp=app/b/${profile._id}`);
      return;
    }

    let api = isFollowing ? UsersAPI.unFollow : UsersAPI.follow;

    api.apply(this, [profile._id]).then((response) => {
      if (!response || response.err) {
        const m = isFollowing
          ? "Problem unsubscribing from broadcaster"
          : "Problem subscribing from broadcaster";
        cogoToast.error(m, { hideAfter: 3 });
        return;
      }

      getProfile();
    });
  };

  const renderFollowBtn = () => {
    let isFollowing = false;

    if (profile.followers && profile.followers.includes(profile.user_id)) {
      isFollowing = true;
    }

    return (
      <Button
        className={isFollowing ? classes.unFollowBtn : classes.followBtn}
        variant="contained"
        size="small"
        onClick={() => handleFollowing(isFollowing)}
      >
        <span>
          {isFollowing ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )}
          <span
            style={{
              marginLeft: "5px",
            }}
          >
            {isFollowing ? "Unsubscribe" : "Subscribe"}
          </span>
        </span>
      </Button>
    );
  };

  const renderAdminModals = () => {
    return (
      <React.Fragment>
        {/* Promotion confirmation modal */}
        {profile && isActivateConfirmVisible && (
          <Modal
            open={true}
            onClose={handleHideActivateConfirm}
            className={classes.deleteModal}
          >
            <Paper elevation={0} className={classes.deleteModalPaper}>
              <p
                style={{
                  marginBottom: 0,
                }}
              >
                Are you sure you want to activate
              </p>
              <p>
                {profile.firstName} {profile.lastName}?
              </p>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.deleteModalActionBtn}
                  onClick={handleConfirmActivate}
                >
                  Yes, activate this broadcaster
                </Button>
              </div>

              <span
                className={classes.deleteModalDismiss}
                onClick={handleHideActivateConfirm}
              >
                No, dismiss
              </span>
            </Paper>
          </Modal>
        )}

        {/* Deactivation confirmation modal */}
        {profile && isDeactivateConfirmVisible && (
          <Modal
            open={true}
            onClose={handleHideDeactivateConfirm}
            className={classes.deleteModal}
          >
            <Paper elevation={0} className={classes.deleteModalPaper}>
              <p
                style={{
                  marginBottom: 0,
                }}
              >
                Are you sure you want to deactivate
              </p>
              <p>
                {profile.firstName} {profile.lastName}?
              </p>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.deleteModalActionBtn}
                  onClick={handleConfirmDeactivate}
                >
                  Yes, deactivate this broadcaster
                </Button>
              </div>

              <span
                className={classes.deleteModalDismiss}
                onClick={handleHideDeactivateConfirm}
              >
                No, dismiss
              </span>
            </Paper>
          </Modal>
        )}

        {/* Delete confirmation modal */}
        {isDeleteVisible && (
          <Modal
            open={true}
            onClose={handleHideDelete}
            className={classes.deleteModal}
          >
            <Paper elevation={0} className={classes.deleteModalPaper}>
              <p
                style={{
                  marginBottom: 0,
                }}
              >
                Are you sure you want to delete
              </p>
              <p>
                {profile.firstName} {profile.lastName}?
              </p>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.deleteModalActionBtn}
                  onClick={handleConfirmDelete}
                >
                  Yes, delete this user
                </Button>
              </div>

              <span
                className={classes.deleteModalDismiss}
                onClick={handleHideDelete}
              >
                No, dismiss
              </span>
            </Paper>
          </Modal>
        )}

        {/* Promotion confirmation modal */}
        {isPromoteVisible && (
          <Modal
            open={true}
            onClose={handleHideDelete}
            className={classes.deleteModal}
          >
            <Paper elevation={0} className={classes.deleteModalPaper}>
              <p
                style={{
                  marginBottom: 0,
                }}
              >
                Are you sure you want to promote
              </p>
              <p>
                {profile.firstName} {profile.lastName}
                to broadcaster?
              </p>
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                NOTE: Once you do, don't forget to go to
                <strong>Broadcasters</strong>
                page to activate their account
              </p>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.deleteModalActionBtn}
                  onClick={handleConfirmPromote}
                >
                  Yes, promote this user
                </Button>
              </div>

              <span
                className={classes.deleteModalDismiss}
                onClick={handleHidePromote}
              >
                No, dismiss
              </span>
            </Paper>
          </Modal>
        )}

        {/* Downgrade confirmation modal */}
        {isDowngradeVisible && (
          <Modal
            open={true}
            onClose={handleHideDelete}
            className={classes.deleteModal}
          >
            <Paper elevation={0} className={classes.deleteModalPaper}>
              <p
                style={{
                  marginBottom: 0,
                }}
              >
                Are you sure you want to downgrade
              </p>
              <p>{`${profile.firstName} ${profile.lastName} to viewer?`}</p>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.deleteModalActionBtn}
                  onClick={handleConfirmDowngrade}
                >
                  Yes, downgrade this user
                </Button>
              </div>

              <span
                className={classes.deleteModalDismiss}
                onClick={handleHideDowngrade}
              >
                No, dismiss
              </span>
            </Paper>
          </Modal>
        )}
      </React.Fragment>
    );
  };

  const renderAdminButtons = () => {
    return (
      <React.Fragment>
        <section className={classes.adminActions}>
          {props.role === "broadcaster" && profile.roleStatus === "inactive" && (
            <Button
              className={classes.promoteBtn}
              variant="contained"
              size="small"
              onClick={handleShowActivateConfirm}
            >
              <span>
                <FontAwesomeIcon icon={faUserCheck} />
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  Activate Broadcaster
                </span>
              </span>
            </Button>
          )}

          {props.role === "broadcaster" && profile.roleStatus === "active" && (
            <Button
              className={classes.promoteBtn}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#125473",
              }}
              onClick={handleShowDeactivateConfirm}
            >
              <span>
                <FontAwesomeIcon icon={faUserTimes} />
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  Deactivate Broadcaster
                </span>
              </span>
            </Button>
          )}

          {props.role === "broadcaster" && (
            <Button
              className={classes.promoteBtn}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ca7b05",
              }}
              onClick={handleShowDowngrade}
            >
              <span>
                <FontAwesomeIcon icon={faUser} />
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  Downgrade to Viewer
                </span>
              </span>
            </Button>
          )}

          {props.role === "user" &&
            profile.roleStatus === "broadcaster_requested" && (
              <Button
                className={classes.promoteBtn}
                variant="contained"
                size="small"
                onClick={handleShowPromote}
              >
                <span>
                  <FontAwesomeIcon icon={faUserGraduate} />
                  <span
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    Promote to Broadcaster
                  </span>
                </span>
              </Button>
            )}

          <Button
            className={classes.deleteBtn}
            variant="contained"
            size="small"
            onClick={handleShowDelete}
          >
            <span>
              <FontAwesomeIcon icon={faTrashAlt} />
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                Delete {roles[props.role]}
              </span>
            </span>
          </Button>
        </section>
      </React.Fragment>
    );
  };

  if (!profile) {
    return (
      <div>
        <p>Loading profile information...</p>
      </div>
    );
  }

  if (profile && !profile.firstName) {
    return (
      <div>
        <p>
          There was a problem getting user profile information. Please try
          again.
        </p>
      </div>
    );
  }

  const showGoBack = false;

  const renderPublicMessage = () => {
    return (
      <div className="PublicMessage">
        <h1>Sign Up to See Full Profile</h1>
        <Button
          variant="contained"
          color="primary"
          className={classes.deleteModalActionBtn}
          onClick={() => props.history.push(`/signup?lp=app/b/${profile._id}`)}
        >
          Sign Up
        </Button>
      </div>
    );
  };

  const Overview = () => (
    <div style={{padding: "10px 30px", background: "#F1F6F8", height: '100%'}}>
      <h3 style={{margin: "15px 0 0 10px"}}>Surgeries</h3>
      <SurgeriesGrid
        data={surgeries.all}
        type="upcoming"
        page="user-profile"
      />
    </div>
  );
  const Surgeries = () => <div style={{padding: "10px 30px", background: "#F1F6F8", height: '100%'}}>
    <SurgeriesTab surgeries={surgeries} current_user={props.user} user_id={props.match.params.id}/>
  </div>;
  const Subscriptions = () => (
    <div style={{padding: "10px 30px", background: "#F1F6F8", height: '100%'}}><SubscriptionsTab current_user={props.user} user_id={props.match.params.id} profile={profile}/></div>
    )

  const Tabs = () => {
    return (
      <div style={{height: '100%'}}>
        <div className="UserNavbar">
          <Link to={`/app/${props.match.params.userKind}/${props.match.params.id}`}>Overview</Link>
          <Link to={`/app/${props.match.params.userKind}/${props.match.params.id}/surgeries`}>
            Surgeries
          </Link>
          <Link to={`/app/${props.match.params.userKind}/${props.match.params.id}/subscriptions`}>
            Subscriptions
          </Link>
        </div>

        <Switch>
          <Route exact path={`/app/${props.match.params.userKind}/${props.match.params.id}`}>
            <Overview />
          </Route>
          <Route path={`/app/${props.match.params.userKind}/${props.match.params.id}/surgeries`}>
            <Surgeries />
          </Route>
          <Route path={`/app/${props.match.params.userKind}/${props.match.params.id}/subscriptions`}>
            <Subscriptions />
          </Route>
        </Switch>
      </div>
    );
  };

  const renderSubsCount = () => {
    if (!profile || !profile.followers) {
      return <span>No Subscribers</span>;
    }

    const c = profile.followers.length;

    return <span>
      {c} {c === 1 ? 'Subscriber' : 'Subscribers' }
    </span>;
  }

  return (
    <div className="UserProfilePage">
      <Grid container className="UserProfilePage-Header">
        {showGoBack ? (
          <Grid item xs={12}>
            <span
              onClick={() => props.history.go(-1)}
              className="hover_color-blue cursor-pointer"
              style={{
                display: "inline-block",
                marginBottom: "30px",
                fontSize: "14px",
              }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                  marginRight: "10px",
                }}
              />
              Go Back
            </span>
          </Grid>
        ) : null}

        <Grid item xs={12} md={2}>
          <span className="UserAvatar-Wrapper">
            <Avatar
              src={Util.getDisplayableAvatar(profile.profileImg)}
              className="UserAvatar"
              name={profile.firstName + " " + profile.lastName}
              round={true}
            />
            {(profile && profile.role === 'broadcaster') ? <FontAwesomeIcon
                icon={faVideo}
              /> : null}
          </span>
        </Grid>
        <Grid item xs={12} md={8} className="UserBio">
          <section>
            <h4>{Util.getFullNameWithTitle(profile)}</h4>
            <h5>{profile.position}</h5>
            <p>{profile.bio}</p>
            <div className="UserBio-Subscribers">
              {props.user.role !== "admin" &&
                profile.role === "broadcaster" &&
                profile._id !== profile.user_id &&
                renderFollowBtn()}
              {renderSubsCount()}
            </div>
          </section>
        </Grid>
        {props.user.role === "admin" ? (
          <Grid item xs={12} md={2} className="UserAdminActions">
            {renderAdminButtons()}
          </Grid>
        ) : null}
      </Grid>

      {!isLoggedIn ? renderPublicMessage() : <Tabs />}

      {renderAdminModals()}
    </div>
  );
};

export default withRouter(UserProfile);

const useStyles = makeStyles((theme) => ({
  instructionText: {
    marginTop: "-10px",
    fontSize: "16px",
    marginBottom: "40px",
  },
  adminActions: {
    width: '200px',
    float: "right",
  },
  promoteBtn: {
    width: '200px',
    color: "#fff",
    textTransform: "none",
    padding: "5px 30px",
    marginRight: "20px",
    background: "#0da3d9",
    "&:hover": {
      background: "#0d8bb9",
    },
  },
  deleteBtn: {
    color: "#fff",
    textTransform: "none",
    padding: "5px 30px",
    background: "#e22f22",
    "&:hover": {
      background: "red",
    },
  },
  deleteModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteModalPaper: {
    maxWidth: "500px",
    padding: "30px 50px",
    textAlign: "center",
  },
  deleteModalActionBtn: {
    margin: "40px 0 20px 0",
    color: "#fff",
    textTransform: "none",
    padding: "10px 40px",
  },
  deleteModalDismiss: {
    fontSize: "16px",
    color: "#7b7b7b",
    cursor: "pointer",
    "&:hover": {
      color: "#444",
    },
  },
  followBtn: {
    float: "right",
    background: "#22a8db",
    border: "2px solid #22a8db",
    padding: "7px 40px",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      background: "#22a1db",
    },
    "&:focus": {
      outline: "none",
    },
  },
  unFollowBtn: {
    float: "right",
    background: "transparent",
    border: "2px solid #22a8db",
    padding: "7px 40px",
    color: "#22a8db",
    textTransform: "none",
    "&:hover": {
      background: "#22a1db",
      color: "#fff",
    },
    "&:focus": {
      outline: "none",
    },
  },
}));
