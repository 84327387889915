import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DashboardSidebar from "../components/DashboardSidebar";
import Avatar from "react-avatar";

class AdminUsers extends Component {
  state = {
    userList: []
  };

  componentDidMount = () => {
    this.getUserList();
  };

  getUserList = () => {
    axios.get("/api/admin/users").then(res => {
      this.setState({
        userList: res.data
      });
    });
  };

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== "admin") {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      );
    } else {
      return (
        <div className="dashboard--has-sidebar">
          <DashboardSidebar />
          <div className="dashboard">
            <main className="dashboard wrapper">
              <section className="section">
                <header className="header">
                  <h1 className="title">Users</h1>
                </header>
                <div className="content pane">
                  <ul className="users">
                    <li className="user users__table-header">
                      <div className="user__img" />
                      <div className="user__name bold">Name</div>
                      <div className="user__email">Email</div>
                      <div className="user__groups">
                        # of Users: {this.state.userList.length}
                      </div>
                    </li>
                    {this.state.userList.map(function(user, i) {
                      return (
                        <li className="user" key={user._id}>
                          <Avatar
                            name={user.firstName + user.lastName}
                            size="40"
                            className="user__img"
                            round={true}
                          />
                          <div className="user__name bold">
                            {user.firstName} {user.lastName}
                          </div>
                          <div className="user__email">{user.email}</div>
                          <div className="user__groups">
                            <Link to={`/user/profile/${user._id}`}>
                              View
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            </main>
          </div>
        </div>
      );
    }
  }
}

export default AdminUsers;
