import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserDashboardSidebar from '../components/UserDashboardSidebar';
import axios from 'axios';
import moment from 'moment';


class Dashboard extends Component {
  state = {
    registeredSurgeries: [],
    latestSurgeries: []
  }

  componentDidMount = () => {
    this.getSurgeries();
  }

  getSurgeries = () => {
    axios.get(`/api/user/registeredsurgeries`)
      .then(res => {
        this.setState({ registeredSurgeries: res.data })
      })
    axios.get(`/api/admin/dashboard/recentsurgeries`)
      .then((res) => {
        this.setState({ latestSurgeries: res.data });
      })
  }

  render() {
    if (!this.props.state.loggedIn) {
      return (
        <div className="main-view">
          <p>Dashboard Page</p>
          <p>Please Log In</p>
        </div>
      )
    }
    return (
      <div className='dashboard--has-sidebar'>
        <UserDashboardSidebar
          userInfo={this.props.state}
          {...this.props}
        />
        <div className='dashboard'>
          <main className='wrapper'>
            <section className='section'>
              <div className='content'>
                <div className="admin-dashboard__grid">
                  <div className="latest-posts">
                    <header className="d-flex w-100 justify-content-between align-items-center mb-4">
                      <h1 className='title mb-0 h4'>Latest Surgeries</h1>
                      <Link to="/surgeries" className="button button--ghost button--ghost--dark">View All</Link>
                    </header>
                    <div className="list-group">
                      {this.state.latestSurgeries.length ?
                        this.state.latestSurgeries.map(surgery => (
                          <Link to={`/surgeries/${surgery._id}`} href="#" key={surgery._id} className="list-group-item list-group-item-action">
                            <div className="d-flex w-100 justify-content-between">
                              <h6 className="mb-1">{surgery.topic}</h6>
                              <small>{moment(surgery.start_time).calendar()}</small>
                            </div>
                            <small>{surgery.doctor}</small>
                          </Link>
                        ))
                        :
                        <div className="list-group-item list-group-item-action registered-surgeries--empty">
                          <div className="registered-surgeries--empty__container">
                            <h6 className="m-4">Currently, There Are No Upcoming Surgeries</h6>
                          </div>
                        </div>
                      }
                    </div>

                  </div>
                  <div className="latest-surgeries registered-surgeries">
                    <header className="d-flex w-100 justify-content-between align-items-center mb-4">
                      <h1 className='title mb-0 h4'>Registered Surgeries</h1>
                      <Link to="/dashboard/registered" className="button button--ghost button--ghost--dark">View All</Link>
                    </header>
                    <div className="list-group">
                      {this.state.registeredSurgeries.length ?
                        this.state.registeredSurgeries.map(surgery => (
                          <Link key={surgery._id} to={`/surgeries/${surgery._id}`} className="list-group-item list-group-item-action">
                            <div className="d-flex w-100 justify-content-between">
                              <h6 className="mb-1">{surgery.topic}</h6>
                              <small>{moment(surgery.start_time).calendar()}</small>
                            </div>
                            <small>{surgery.doctor}</small>
                          </Link>
                        ))
                        :
                        <div className="list-group-item list-group-item-action registered-surgeries--empty">
                          <div className="registered-surgeries--empty__container">
                            <h6 className="mb-1">You Are Not Registered To Any Surgeries</h6>
                            <Link to="/surgeries" className="button button--rounded">Get Started</Link>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    )
  }
}

export default Dashboard;