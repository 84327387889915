import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button
} from '@material-ui/core';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons'
// import Avatar from 'react-avatar';

import SectionTitle from 'components/dashboard/SectionTitle';
import EmptyArea from 'components/dashboard/EmptyArea';
import SurgeriesAPI from 'api/surgeries';
import Util from 'service/util';
import TimezoneSvc from 'service/timezones';

export default(props) => {
    const classes = useStyles();

    const [surgeries,
        setSurgeries] = useState(null);
    const [page,
        setPage] = React.useState(0);
    const pageSize = props.pageSize || 7;

    const getSurgeries = () => {
        SurgeriesAPI
            .list(props.userRole, props.type)
            .then(response => {
                const {data, err} = response;

                if (err) {
                    setSurgeries([]);
                    console.error('Error getting surgeries: ', err);
                    cogoToast.error('There was a problem getting surgeries');
                    return;
                }

                setSurgeries(data);
            });
    }

    useEffect(() => {
        getSurgeries();
    }, []);

    const [surgeryToDelete,
        setSurgeryToDelete] = useState(null);

    const handleDelete = (surgery) => {
        setSurgeryToDelete(surgery);
    }

    const handleCloseDeleteConfirmation = () => {
        setSurgeryToDelete(null);
    }

    const handleConfirmDelete = () => {
        SurgeriesAPI
            .remove('broadcaster', surgeryToDelete._id)
            .then(({err}) => {
                if (err) {
                    cogoToast.error('Problem deleting this surgery');
                    return;
                }

                setSurgeryToDelete(null);
                cogoToast.success('Surgery deleted');
                getSurgeries();
            });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div>
            <SectionTitle title={props.title}/> {/*  */}

            {(!surgeries) && <EmptyArea title="Loading surgeries"/>}

            {(Array.isArray(surgeries) && surgeries.length === 0) && <EmptyArea {...getEmptyAreaProps(props.userRole, props.type)}/>}

            {(Array.isArray(surgeries) && surgeries.length > 0) && <React.Fragment>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {(props.broadcaster) && <TableCell>Performing Surgeon</TableCell>}
                                <TableCell>Procedure</TableCell>
                                <TableCell size="small">Date</TableCell>
                                {(props.type === 'upcoming' || props.type === 'upcoming-others' || props.type === 'registered') && <TableCell size="small">Time</TableCell>}
                                <TableCell align="center" size="small">{(props.type === 'upcoming' || props.type === 'upcoming-others' || props.type === 'registered')
                                        ? 'Registered'
                                        : 'Viewers'}</TableCell>
                                {(props.playback) && <TableCell align="center" size="small">
                                    Playback
                                </TableCell>}                                        
                                <TableCell align="right" size="small"></TableCell>                                
                                {(props.deletable) && <TableCell align="right"></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {surgeries
                                .slice(page * pageSize, page * pageSize + pageSize)
                                .map((s) => {
                                const tzID = s.timezone || 'America/Los_Angeles'
                                return (
                                    <TableRow key={s._id} className={classes.tableRow}>
                                        {(props.broadcaster) && <TableCell component="th" scope="row">
                                            {/* <Avatar
                                                src={Util.getDisplayableAvatar(s.broadcaster.profileImg)}
                                                name={`${s.broadcaster.firstName} ${s.broadcaster.lastName}`}
                                                round={true}
                                                style={{
                                                marginRight: '5px'
                                            }}
                                                size='35'/>  */}
                                            {Util.getFullNameWithTitle(s.broadcaster)}
                                        </TableCell>}
                                        <TableCell component="th" scope="row">
                                            {s.topic}
                                        </TableCell>
                                        <TableCell size="small">{moment
                                                .tz(s.start_time, s.timezone)
                                                .format('M/D/YY')}</TableCell>
                                        {(props.type === 'upcoming' || props.type === 'upcoming-others' || props.type === 'registered') && <TableCell size="small">
                                            <div className={classes.timeDetail}>{moment
                                                    .tz(s.start_time, s.timezone)
                                                    .format('h:mm A')}</div>
                                            {tzID && <div className={classes.tableRowDetail}>{TimezoneSvc.getNameByID(tzID)}</div>}
                                        </TableCell>}
                                        <TableCell align="center" size="small">{getRegisteredUsersCount(s.registered_users)}</TableCell>
                                        {(props.playback) && <TableCell align="center" size="small">{renderPlayback(s)}</TableCell>}
                                        <TableCell
                                            align="right"
                                            size="small"
                                            className="cursor-pointer hover_color-blue"
                                            onClick={() => props.onEditClick(s)}>{(props.editable)
                                                ? 'Edit Details'
                                                : 'View Details'}</TableCell>
                                        {(props.deletable) && <TableCell
                                            align="right"
                                            className="cursor-pointer hover_color-red"
                                            onClick={() => handleDelete(s)}>Delete</TableCell>}
                                    </TableRow>
                                )})}
                        </TableBody>
                    </Table>

                    <TablePagination
                        className={classes.tablePagination}
                        component="div"
                        count={surgeries.length}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        page={page}
                        onChangePage={handleChangePage}/>
                </TableContainer>
            </React.Fragment>}

            {/* Delete confirmation modal */}
            {(surgeryToDelete && surgeryToDelete._id) && <Modal
                open={true}
                onClose={handleCloseDeleteConfirmation}
                className={classes.deleteModal}>
                <Paper elevation={0} className={classes.deleteModalPaper}>
                    <p style={{
                        marginBottom: 0
                    }}>Are you sure you want to delete</p>
                    <p>{surgeryToDelete.topic}?</p>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.deleteModalActionBtn}
                            onClick={handleConfirmDelete}>
                            Yes, delete this surgery
                        </Button>
                    </div>

                    <span
                        className={classes.deleteModalDismiss}
                        onClick={handleCloseDeleteConfirmation}>No, dismiss</span>
                </Paper>
            </Modal>}
        </div>
    );
}

const getRegisteredUsersCount = (u) => {
    return (u && u.length)
        ? u.length
        : '-';
}

const getEmptyAreaProps = (role, type) => {
    if (type === 'upcoming') {
        return {
            title: "No Scheduled Surgeries",
            subTitle: (role === 'broadcaster')
                ? "You have no upcoming surgeries"
                : 'Scheduled surgeries will appear here'
        };
    }

    if (type === 'upcoming-others') {
        return {title: "No Scheduled Surgeries from other Broadcasters", subTitle: "Surgeries scheduled by other broadcasters will appear here"};
    }

    if (type === 'past') {
        return {title: "No Archived Surgeries", subTitle: "Previously aired surgeries will appear here"};
    }

    if (role === 'user' && type === 'registered') {
        return {title: "No Registered Surgeries", subTitle: "Surgeries you register for will appear here"};
    }

    return {};
}

const renderPlayback = (surgery) => {
    if(!surgery) {
        return null;
    }

    if(!surgery.youtubeLink) {
        return <div style={{color: '#d04646'}}>
            <FontAwesomeIcon icon={faTimesCircle}/> no video
        </div>
    }

    if(surgery.youtubeLink) {
        return <div style={{color: '#5bd046'}}>
            <FontAwesomeIcon icon={faCheckCircle}/>
        </div>
    }    
}

const useStyles = makeStyles({
    tableContainer: {
        marginBottom: 25,
        paddingBottom: 55, //to make space for pagination
        fontSize: '12px !important',
        minHeight: 300,
        position: 'relative'
    },
    tablePagination: {
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    tableRow: {
        '&:hover': {
            backgroundColor: '#fafafa'
        }
    },
    timeDetail: {
        display: 'inline-block',
        overflow: 'hidden'
    },
    tableRowDetail: {
        marginLeft: '5px',
        fontSize: '10px',
        color: '#363d56',
        border: '1px solid #c1c1c1',
        borderRadius: '50px',
        padding: '3px 7px',
        maxWidth: '95px',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    deleteModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    deleteModalPaper: {
        maxWidth: '500px',
        padding: '30px 50px',
        textAlign: 'center'
    },
    deleteModalActionBtn: {
        margin: '40px 0 20px 0',
        color: '#fff',
        textTransform: 'none',
        padding: '10px 40px'
    },
    deleteModalDismiss: {
        fontSize: '16px',
        color: '#7b7b7b',
        cursor: 'pointer',
        '&:hover': {
            color: '#444'
        }
    }
});