import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Grid, makeStyles, Modal, Button, Paper} from '@material-ui/core';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faUserMd,
    faCheck,
    faCircle,
    faSquare,
    faPen,
    faComment,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import Avatar from 'react-avatar';

import EmptyArea from 'components/dashboard/EmptyArea';
import SendInvitesBtn from 'components/dashboard/SendInvitesBtn';
import PlayYouTubeBtn from 'components/dashboard/PlayYouTubeBtn';
import SurgeryFeedbackModal from 'components/dashboard/SurgeryFeedbackModal';
import RegisteredViewersModal from 'components/dashboard/RegisteredViewersModal';
import SurgeryAPI from 'api/surgeries';
import UsersAPI from 'api/users';
import Util from 'service/util';
import TimezoneSvc from 'service/timezones';
import UsersSvc from 'service/users';

import defImg from 'images/cover-photos/default.png';
import rotatorcuff from 'images/cover-photos/surgery-cover-01.png';
import labrum from 'images/cover-photos/surgery-cover-02.png';
import acl from 'images/cover-photos/surgery-cover-03.png';
import meniscus from 'images/cover-photos/surgery-cover-04.png';

const images = {
    defImg,
    rotatorcuff,
    labrum,
    acl,
    meniscus
};

const Surgery = (props) => {
    const [isLoading,
        setIsLoading] = useState(false);
    const [surgery,
        setSurgery] = useState(null);
    const [isRegistered,
        setIsRegistered] = useState(false);
    const [joinInterval,
        setJoinInterval] = useState(null);
    const [surgeryState,
        setSurgeryState] = useState('upcoming');
    const [isRegConfirmationVisible,
        setIsRegConfirmationVisible] = useState(false);
    const [isFeedbackVisible,
        setIsFeedbackVisible] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        getSurgery(props.match.params.id);

        return () => {
            if (joinInterval) {
                clearJoinInterval();
            }
        }
    }, []);

    const getSurgery = (ID) => {
        setIsLoading(true);

        SurgeryAPI
            .get('user', ID)
            .then(response => {
                setIsLoading(false);
                if(!response) {
                    cogoToast.error('Problem getting the surgery');
                    return;
                }

                const {data, err} = response;

                if (err) {
                    cogoToast.error('Problem getting the surgery');
                    return;
                }

                let s = data;
                if (s.youtubeLink) {
                    s.videoID = Util.extractIDfromYTUrl(s.youtubeLink);
                }

                setSurgery(s);
                handleSurgeryState(s);
            });
    }

    const clearJoinInterval = () => {
        clearInterval(joinInterval);
    }

    const handleSurgeryState = (surgery) => {
        if (!surgery) {
            return;
        }

        const start = moment.tz(surgery.start_time, surgery.timezone);
        const end = moment
            .tz(surgery.start_time, surgery.timezone)
            .add(surgery.duration, 'minutes');
        const now = moment().tz(surgery.timezone);

        if (now.isAfter(end)) {
            setSurgeryState('complete');
        } else if (now.isBetween(start, end)) {
            setSurgeryState('live');
        } else if (start.isBefore(moment().tz(surgery.timezone).add(10.1, 'minutes'))) {
            setSurgeryState('soon');
        }

        const ir = (surgery.registered_users.includes(surgery.user_id))
            ? true
            : false;
        setIsRegistered(ir);

        if (ir && start.isAfter(now)) {
            const ji = setInterval(() => {
                const n = moment().tz(surgery.timezone);
                if (start.isBefore(moment().tz(surgery.timezone).add(10.1, 'minutes') && surgeryState !== 'soon')) {
                    setSurgeryState('soon');
                } else if (n.isBetween(start, end) && surgeryState !== 'live') {
                    setSurgeryState('live');
                } else if (n.isAfter(end) && surgeryState !== 'complete') {
                    setSurgeryState('complete');
                    clearJoinInterval();
                }
            }, 1000);

            setJoinInterval(ji);
        }
    }

    const handleRegistration = () => {
        if (isRegistered) {
            handleUnregister();
            return;
        }

        handleRegister();
    }

    const handleRegister = () => {
        const startTime = moment.tz(surgery.start_time, surgery.timezone);
        let surgeryTime = startTime.format('h:mm A');

        if (surgery.timezone) {
            surgeryTime += ` (${TimezoneSvc.getNameByID(surgery.timezone)})`
        }

        surgery.dateFormatted = startTime.format('MMMM D YYYY');
        surgery.timeFormatted = surgeryTime;

        SurgeryAPI
            .register({surgeryInfo: surgery, userEmail: props.user.email})
            .then(response => {
                const {err} = response;

                if (err) {
                    cogoToast.error('Problem registering for this surgery, please try again', {hideAfter: 4});
                    console.error('Problem registering : ', err);
                    return;
                }

                getSurgery(props.match.params.id);
                if (surgeryState !== 'live') {
                    setIsRegConfirmationVisible(true);
                }
            });
    }

    const handleHideRegConfirmation = () => {
        setIsRegConfirmationVisible(false);
    }

    const handleUnregister = () => {
        SurgeryAPI
            .unregister({surgeryInfo: surgery, userEmail: props.user.email})
            .then(response => {
                const {err} = response;

                if (err) {
                    cogoToast.error('Problem unregistering from this surgery, please try again', {hideAfter: 4});
                    console.error('Problem unregistering : ', err);
                    return;
                }

                cogoToast.success('Unregistered from surgery', {hideAfter: 4});
                getSurgery(props.match.params.id);
            });
    }

    const handleJoin = () => {
        const w = window.open(surgery.join_url, '_blank');
        if (w) {
            w.focus();
        }
    }

    const handleStartSurgery = () => {
        SurgeryAPI
            .updateBroadcastStatus(surgery._id, 200)
            .then(response => {
                const {err} = response;

                if (err) {
                    cogoToast.error('Problem starting the surgery');
                    return;
                }

                const w = window.open(surgery.start_url, '_blank');
                if (w) {
                    w.focus();
                }

                getSurgery(surgery._id);
            });
    }

    const handleEndSurgery = () => {
        SurgeryAPI
            .updateBroadcastStatus(surgery._id, 300)
            .then(response => {
                const {err} = response;

                if (err) {
                    cogoToast.error('Problem stoping the surgery');
                    return;
                }

                getSurgery(surgery._id);
                handleShowFeedback();
            });
    }

    const handleShowFeedback = () => {
        setIsFeedbackVisible(true);
    }

    const handleHideFeedback = () => {
        setIsFeedbackVisible(false);
    }

    const handleViewProfile = () => {
        props
            .history
            .push('/app/b/' + surgery.broadcaster.id);
    }

    const handleEdit = () => {
        props
            .history
            .push('/app/edit-surgery/' + surgery._id);
    }

    const handleFollow = () => {
        UsersAPI
            .follow(surgery.broadcaster.id)
            .then(response => {
                if (!response || response.err) {
                    cogoToast.error('Problem subscribing to broadcaster', {hideAfter: 3});
                    return;
                }

                getSurgery(props.match.params.id);
            });
    }

    const isLeaveFeedbackVisible = () => {
        if ((surgeryState === 'complete' || surgery.broadcast_status === 300) && ((props.user.role === 'user' && isRegistered) || (surgery.user_id === surgery.broadcaster.id))) {
            return true;
        }

        return false;
    }

    const renderActions = () => {
        const isWatchable = (surgeryState === 'upcoming' || surgeryState === 'complete')
            ? false
            : true;
        const canRegister = (props.user.role !== 'admin' && surgeryState !== 'complete' && surgery.user_id !== surgery.broadcaster.id)
            ? true
            : false;

        return (
            <React.Fragment>
                {(surgery.user_id === surgery.broadcaster.id) && <Button
                    variant="contained"
                    color="primary"
                    className={classes.registerBtn}
                    onClick={handleEdit}>
                    <FontAwesomeIcon
                        icon={faPen}
                        style={{
                        marginRight: '5px'
                    }}/>
                    Edit Surgery
                </Button>}

                {(surgery.user_id === surgery.broadcaster.id && surgeryState !== 'complete' && surgery.broadcast_status !== 300) && <SendInvitesBtn
                    surgery={surgery}
                    isFullName={true}
                    style={{
                    padding: '5px 25px',
                    fontSize: '15px',
                    marginRight: '10px'
                }}/>}

                {(surgery.user_id === surgery.broadcaster.id && surgeryState !== 'complete' && (!surgery.broadcast_status || surgery.broadcast_status === 100)) && <Button
                    className={classes.startBtn}
                    variant='contained'
                    color='primary'
                    onClick={handleStartSurgery}>
                    <FontAwesomeIcon
                        icon={faCircle}
                        color="red"
                        style={{
                        marginRight: '5px'
                    }}/>
                    Start Surgery
                </Button>}

                {(surgery.user_id === surgery.broadcaster.id && surgery.broadcast_status === 200) && <Button
                    className={classes.startBtn}
                    variant='contained'
                    color='primary'
                    onClick={handleEndSurgery}>
                    <FontAwesomeIcon
                        icon={faSquare}
                        color="red"
                        style={{
                        marginRight: '5px'
                    }}/>
                    End Surgery
                </Button>}

                {((surgeryState === 'complete' || surgery.broadcast_status === 300) && surgery.videoID) && <PlayYouTubeBtn videoID={surgery.videoID}/>}

                {(isLeaveFeedbackVisible()) && <Button
                    className={classes.registerBtn}
                    variant='contained'
                    color='primary'
                    onClick={handleShowFeedback}>
                    <FontAwesomeIcon
                        icon={faComment}
                        color="white"
                        style={{
                        marginRight: '5px'
                    }}/>
                    Leave Feedback
                </Button>}

                {(canRegister && !(surgeryState === 'complete' || surgery.broadcast_status === 300)) && <Button
                    variant="contained"
                    color="primary"
                    className={classes.registerBtn}
                    onClick={handleRegistration}>{isRegistered
                        ? 'Unregister'
                        : 'Register'}</Button>}

                {(isRegistered) && <Button
                    variant="contained"
                    color="primary"
                    disabled={!isWatchable}
                    className={classes.registerBtn}
                    style={{
                    marginLeft: '15px',
                    backgroundColor: isWatchable
                        ? '#0dc535'
                        : '#b3b3b3'
                }}
                    onClick={handleJoin}>Watch Surgery</Button>}
            </React.Fragment>
        );
    }

    const renderSubscriptionBtn = () => {
        if (props.user.role === 'admin' || !surgery || !surgery.broadcaster || !surgery.broadcaster.followers || (surgery.user_id === surgery.broadcaster.id)) {
            return null;
        }

        const isFollower = surgery
            .broadcaster
            .followers
            .includes(surgery.user_id);

        return (
            <Button
                disabled={isFollower}
                variant="contained"
                color="primary"
                onClick={() => {
                if (!isFollower) {
                    handleFollow()
                }
            }}
                className={isFollower
                ? classes.followingBtn
                : classes.followBtn}>
                {isFollower && <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                    marginRight: '5px'
                }}/>}
                {isFollower
                    ? 'Subscribed'
                    : 'Subscribe'}
            </Button>
        );
    }

    const renderRegisteredViewers = () => {
        if (surgery && surgery.registered_users && surgery.registered_users.length === 0) {
            return <React.Fragment>
                    <FontAwesomeIcon
                        icon={faUsers}
                        style={{
                        marginRight: '10px'
                    }}/>
                    No users registered
            </React.Fragment>;
        }

        const viewerCount = surgery.registered_users.length;
        let isAdminOrCreator = false;

        const currUser = UsersSvc.getCurrent();
        if (props.user.role === 'admin' || (currUser && currUser.id === surgery.broadcaster.id)) {
            isAdminOrCreator = true
        }

        if (isAdminOrCreator) {
            return <RegisteredViewersModal count={viewerCount} id={surgery._id}/>;
        } else {
            return <React.Fragment>
                    <FontAwesomeIcon
                        icon={faUsers}
                        style={{
                        marginRight: '10px'
                    }}/>
                    {`${viewerCount} users registered`}
            </React.Fragment>
        }

    }

    const renderRegistrationBanner = () => {
        const isSurgeryCreator = (surgery.user_id === surgery.broadcaster.id)
            ? true
            : false;
        let {note, isComplete} = getBannerNote(surgeryState, isRegistered, props.user.role, isSurgeryCreator, surgery.broadcast_status);

        if (!note) {
            return null;
        }

        return (
            <div
                className={classes.registrationBanner}
                style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: isComplete
                    ? '#717171'
                    : '#00a2e5'
            }}>
                {(surgeryState === 'live') && <FontAwesomeIcon
                    className="pulse"
                    icon={faCircle}
                    color='red'
                    style={{
                    fontSize: '25px',
                    marginRight: '15px'
                }}/>}
                {note}
            </div>
        );
    }

    let startTime = '';
    let airTime = '';
    let broadcasterNameWithTitle = '';

    if (surgery) {
        startTime = moment.tz(surgery.start_time, surgery.timezone);
        airTime = <span className={classes.airTime}>
            Airs&nbsp;
            <strong className={classes.airTimeDetail}>{startTime.format('MMMM D, YYYY')}</strong>
            &nbsp;at&nbsp;
            <strong className={classes.airTimeDetail}>{startTime.format('h:mm A')}</strong>
            {surgery.timezone && `, ${TimezoneSvc.getNameByID(surgery.timezone)}`}
            &nbsp;
            <br></br>
            Duration:&nbsp;
            <time>{Math.floor(surgery.duration / 60)}
                hr{" "} {surgery.duration % 60}
                min</time>
        </span>;

        broadcasterNameWithTitle = Util.getFullNameWithTitle(surgery.broadcaster, true);
    }

    var urlParams = new URLSearchParams(props.location.search)
    const isGoBackVisible = (urlParams.has('new'))
        ? false
        : true;

    return (
        <div>
            {isLoading && <EmptyArea title="Loading surgery details..."/>}

            {(!isLoading && !surgery) && <EmptyArea title="Problem getting surgery details"/>}

            {(!isLoading && surgery && surgery._id) && <React.Fragment>
                <section className={classes.header}>
                    {renderRegistrationBanner()}

                    <Grid container className={classes.headerContainer} spacing={2}>
                        <Grid item xs={12} md={7}>
                            {isGoBackVisible && <span
                                onClick={() => props.history.go(-1)}
                                className="hover_color-blue cursor-pointer"
                                style={{
                                display: 'inline-block',
                                marginBottom: '30px',
                                fontSize: '14px'
                            }}>
                                <FontAwesomeIcon
                                    icon={faChevronLeft}
                                    style={{
                                    marginRight: '10px'
                                }}/>
                                Go Back</span>}

                            <div>
                                <h1 className={classes.title}>{surgery.topic}</h1>
                                <p
                                    style={{
                                    marginBottom: 0
                                }}>{airTime}</p>
                                <div className={classes.broadcastInfo}>
                                    <section className={classes.broadcastInfoDetailName}>
                                        <FontAwesomeIcon
                                            icon={faUserMd}
                                            style={{
                                            marginRight: '10px'
                                        }}/>{broadcasterNameWithTitle}</section>

                                    <section>{renderRegisteredViewers()}</section>
                                </div>

                                {renderActions()}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <img
                                src={images[surgery.image] || defImg}
                                className={classes.surgeryImg}
                                alt="Surgery graphic"/>
                        </Grid>
                    </Grid>
                </section>

                <Grid container className={classes.bodyContainer} spacing={4}>
                    <Grid item xs={12} md={7}>
                        <h5 className={classes.bodyTitle}>About the surgery</h5>
                        <div className={classes.description}>
                            <p>
                                {surgery.description || <i>No description</i>}
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <h5 className={classes.bodyTitle}>Surgeon</h5>
                        <div className={classes.broadcasterInfo}>
                            <section
                                style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <div>
                                    <Avatar
                                        className={classes.broadcastInfoAvatar}
                                        src={Util.getDisplayableAvatar(surgery.broadcaster.profileImg)}
                                        name={surgery.broadcaster.fullName}
                                        size="60"
                                        round={true}/>
                                    <span className={classes.broadcastInfoName} onClick={handleViewProfile}>
                                        {broadcasterNameWithTitle}
                                    </span>
                                </div>

                                {renderSubscriptionBtn()}
                            </section>

                            <p className={classes.broadcasterInfoBio}>{surgery.broadcaster.bio}</p>
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>}

            {/* Registration confirmation modal */}
            {(isRegConfirmationVisible) && <Modal
                open={true}
                onClose={handleHideRegConfirmation}
                className={classes.confirmModal}>
                <Paper elevation={0} className={classes.confirmModalPaper}>
                    <p>Thank you for registering.</p>
                    <p>An e-mail confirmation will be sent.</p>
                    <p>Please return to this page on</p>
                    <p>
                        <strong>{startTime.format('MMMM D YYYY')}</strong>
                        &nbsp;at&nbsp;
                        <strong>{startTime.format('h:mm A')}</strong>
                        {surgery.timezone && ` (${TimezoneSvc.getNameByID(surgery.timezone)})`}</p>
                    <p>to watch the surgery.</p>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmModalActionBtn}
                            onClick={handleHideRegConfirmation}>
                            Close
                        </Button>
                    </div>
                </Paper>
            </Modal>}

            <SurgeryFeedbackModal visible={isFeedbackVisible} surgery={surgery} user={UsersSvc.getCurrent()} onHide={handleHideFeedback}/>
        </div>
    );
}

export default withRouter(Surgery);

const useStyles = makeStyles((theme) => ({
    header: {
        margin: '-30px -20px', //negatives of outer grid padding
        background: '#e4eef2',
        padding: '30px 20px'
    },
    headerContainer: {
        alignItems: 'center'
    },
    registrationBanner: {
        margin: '-30px -20px 20px -20px',
        color: '#fff',
        padding: '12px 30px',
        backgroundColor: '#00a2e5',
        fontSize: '15px'
    },
    airTime: {
        color: '#6d6d6d',
        fontWeight: '200',
        fontSize: '16px'
    },
    airTimeDetail: {
        fontWeight: '700'
    },
    title: {
        fontSize: '40px',
        color: '#353d57',
        fontWeight: '700',
        marginTop: '10px',
        textTransform: 'capitalize'
    },
    broadcastInfo: {
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0',
        fontSize: '16px'
    },
    broadcastInfoDetailName: {
        marginRight: '40px',
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            flex: '1'
        }
    },
    registerBtn: {
        color: '#fff',
        textTransform: 'none',
        padding: '5px 25px',
        fontSize: '15px',
        marginRight: '10px',
        [
            theme
                .breakpoints
                .down('sm')
        ]: {
            padding: '10px 50px',
            margin: '10px 0 10px 0 !important',
            width: '100%'
        },
        '&:focus': {
            outline: 'none'
        }
    },
    startBtn: {
        color: '#fff',
        backgroundColor: '#1d2122',
        padding: '5px 25px',
        fontSize: '15px',
        marginRight: '10px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0a0a0a'
        },
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            padding: '10px 50px',
            marginBottom: '10px',
            width: '100%'
        }
    },
    surgeryImg: {
        width: '100%',
        borderRadius: '20px'
    },
    bodyTitle: {
        marginTop: '30px',
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            marginTop: '20px'
        }
    },
    description: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        marginTop: '10px',
        minHeight: '350px',
        padding: '25px 70px 25px 30px',
        fontSize: '18px',
        whiteSpace: 'pre-wrap'
    },
    broadcasterInfo: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        marginTop: '10px',
        padding: '25px 30px'
    },
    broadcastInfoAvatar: {
        marginRight: '10px'
    },
    broadcastInfoName: {
        fontSize: '18px',
        '&:hover': {
            cursor: 'pointer',
            color: '#0da3d9'
        }
    },
    broadcasterInfoBio: {
        whiteSpace: 'pre-wrap',
        fontSize: '16px',
        marginTop: '20px'
    },
    followingBtn: {
        background: 'transparent !important',
        boxShadow: 'none',
        border: '2px solid #0da3d9',
        color: '#0da3d9 !important',
        textTransform: 'none',
        fontSize: '17px',
        padding: '2px 20px',
        '&:hover': {
            color: '#fff'
        }
    },
    followBtn: {
        background: '#0da3d9 !important',
        boxShadow: 'none',
        border: '2px solid #0da3d9',
        color: '#fff !important',
        textTransform: 'none',
        fontSize: '17px',
        padding: '2px 20px',
        '&:hover': {
            color: '#fff'
        }
    },
    confirmModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    confirmModalPaper: {
        padding: '40px 80px',
        maxWidth: '95%',
        textAlign: 'center',
        fontSize: '17px'
    },
    confirmModalActionBtn: {
        margin: '40px 0 20px 0',
        color: '#fff',
        textTransform: 'none',
        padding: '10px 40px'
    }
}));

const getBannerNote = (surgeryState, isRegistered, userRole, isSurgeryCreator, broadcastStatus) => {
    let note = '';
    let isComplete = false;

    // Default notes
    if (isRegistered) {
        note = 'Thank You For Registering. The "Watch Surgery" button below will become active 1' +
                '0 minutes before the surgery begins.';
    }

    if (isSurgeryCreator) {
        note = 'Viewers will be able to join your surgery broadcast 10 minutes before start time' +
                '.';
    }

    // State-based notes
    if (broadcastStatus >= 300 || surgeryState === 'complete') {
        isComplete = true;

        if (isRegistered && userRole === 'user') {
            note = 'Thank You for Taking Part in the Surgery.';
        } else {
            note = 'The Surgery is Over.';
        }
    } else if (broadcastStatus === 200 || surgeryState === 'live') {
        note = 'The surgery is live now.';
        if (!isSurgeryCreator && isRegistered) {
            note += ' You may join it by clicking on "Watch Surgery" button below.';
        } else if (!isSurgeryCreator && !isRegistered) {
            note += ' Click on "Register" button below to be able to watch it.';
        }
    } else if (surgeryState === 'soon') {
        if (isSurgeryCreator) {
            note = 'The surgery is scheduled to start soon. You may start the broadcast by clicking ' +
                    'on "Start Surgery" button below.';
        } else {
            note = 'The surgery will start soon. You may join the broadcast by clicking on "Watch Su' +
                    'rgery" button below.';
        }
    }

    return {note, isComplete};
}