import React, { useEffect, useState } from "react";
import axios from "axios";

import UserDashboardSidebar from '../../components/UserDashboardSidebar';


export const BroadcasterProfileReadOnly = props => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    bio: "",
    profileImg: ""
  });

  const getUserInfo = () => {
    axios.get(`/api/user/broadcaster/profile/${props.match.params.id}`).then(user => {
      setUserInfo(user.data);
    });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="dashboard--has-sidebar">
      <UserDashboardSidebar {...props} />
      <div className="dashboard">
        <main className="wrapper">
          <section className="section">
            <div className="content">
              <div className="admin-dashboard__grid">
                <div className="latest-posts">
                  <header className="d-flex w-100 justify-content-between align-items-center mb-4">
                    <h1 className="title mb-0 h4">Broadcaster Profile</h1>
                  </header>
                  <div className="form-group form-row">
                    <label className="col-lg-3 control-label">
                      First name:
                    </label>
                    <div className="col-lg-9">
                      <input
                        className="form-control"
                        name="firstName"
                        type="text"
                        value={userInfo.firstName}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-lg-3 control-label">Last name:</label>
                    <div className="col-lg-9">
                      <input
                        className="form-control"
                        name="lastName"
                        type="text"
                        value={userInfo.lastName}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-lg-3 control-label">Dob:</label>
                    <div className="col-lg-9">
                      <input
                        className="form-control"
                        name="dob"
                        type="text"
                        value={userInfo.dob}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-lg-3 control-label">Bio:</label>
                    <div className="col-lg-9">
                      <textarea
                        className="form-control"
                        name="position"
                        type="text"
                        value={userInfo.bio}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};
