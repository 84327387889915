import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import SurgeriesTable from 'components/dashboard/SurgeriesTable';
import SubscribersTable from 'components/dashboard/SubscribersTable';
import UsersTable from 'components/dashboard/UsersTable';

const _IS_TUTORIAL_ENABLED_ = process.env.REACT_APP_IS_TUTORIAL_ENABLED === 'true'? true : false;

const MyDashboard = ({onShowTutorial, history, user}) => {
    const classes = useStyles();

    const handleEdit = (s) => {
        history.push('/app/edit-surgery/' + s._id, {surgery: s});
    }

    const handleViewUser = (u) => {
        history.push('/app/v/' + u._id, {user: u});
    }

    const handleViewBroadcaster = (u) => {
        history.push('/app/b/' + u._id, {user: u});
    }

    const handleViewSurgery = (s) => {
        history.push('/app/surgery/' + s._id, {surgery: s});
    }

    return (
        <div className="MyDashboard-Page">
            <Grid container className={classes.container} spacing={2}>
                {/* Viewer - registered surgeries and recent surgeries */}
                {(user.role === 'user') && <ViewerContent onClickSurgery={handleViewSurgery}/>}

                {(user.role === 'broadcaster') && <BroadcasterContent
                    onShowTutorial={onShowTutorial}
                    onClickEdit={handleEdit}
                    onClickView={handleViewSurgery}
                    onViewUserClick={handleViewUser}
                    userProfile={user}/>}

                {(user.role === 'admin') && <AdminContent
                    onClickEdit={handleViewSurgery}
                    onViewUserClick={handleViewUser}
                    onViewBroadcasterClick={handleViewBroadcaster}/>}
            </Grid>
        </div>
    )
}

export default withRouter(MyDashboard);

/*
    Admin
*/
const AdminContent = ({onClickEdit, onViewUserClick, onViewBroadcasterClick}) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <SurgeriesTable
                    userRole="admin"
                    type="upcoming"
                    title="Scheduled Surgeries"
                    pageSize={7}
                    broadcaster
                    onEditClick={onClickEdit}/>
            </Grid>

            <Grid item xs={12} md={12}>
                <UsersTable
                    type="recent-broadcasters"
                    title="New Broadcasters"
                    email
                    deletable
                    roleStatus
                    onViewClick={onViewBroadcasterClick}
                    pageSize={7}/>
            </Grid>

            <Grid item xs={12} md={12}>
                <UsersTable
                    type="recent"
                    title="New Viewers"
                    email
                    position
                    deletable
                    onViewClick={onViewUserClick}
                    pageSize={7}/>
            </Grid>
        </React.Fragment>
    );
}

/*
    Broadcaster
*/
const BroadcasterContent = ({onShowTutorial, onClickEdit, onClickView, onViewUserClick, userProfile}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid
                item
                xs={12}
                id="myDashboardBroadcasterScheduledSurgeries"
                style={{
                position: 'relative'
            }}>
                {_IS_TUTORIAL_ENABLED_ && <span id="myDashboardBroadcasterShowTutorial" onClick={onShowTutorial} className={classes.tutorialInvoker}><FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{
                    marginRight: '3px'
                }}/>
                    Show tutorial</span>}

                <SurgeriesTable
                    userRole="broadcaster"
                    type="upcoming"
                    title="My Scheduled Surgeries"
                    pageSize={5}
                    broadcaster
                    editable
                    deletable
                    onEditClick={onClickEdit}/>
            </Grid>
            <Grid item xs={12} id="myDashboardBroadcasterOtherSurgeries">
                <SurgeriesTable
                    userRole="broadcaster"
                    type="upcoming-others"
                    title="Scheduled Surgeries"
                    pageSize={5}
                    broadcaster
                    onEditClick={onClickView}/>
            </Grid>
            <Grid item xs={12} id="myDashboardBroadcasterOtherSurgeries">
                <SurgeriesTable
                    userRole="user"
                    type="registered"
                    title="Registered Surgeries"
                    broadcaster
                    onEditClick={onClickView}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <SurgeriesTable
                    userRole="broadcaster"
                    type="past"
                    title="My Past Surgeries"
                    pageSize={5}
                    onEditClick={onClickView}/>
            </Grid>
            <Grid item xs={12} md={6} id="myDashboardBroadcasterSubscribers">
                <SubscribersTable
                    userRole="broadcaster"
                    type="new"
                    title="My New Subscribers"
                    profile
                    showViewAll
                    onViewProfile={onViewUserClick}/>
            </Grid>
        </React.Fragment>
    );
}

/*
    Viewer
*/
const ViewerContent = ({onClickSurgery}) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <SurgeriesTable
                    userRole="user"
                    type="upcoming"
                    title="Scheduled Surgeries"
                    broadcaster
                    onEditClick={onClickSurgery}/>
            </Grid>

            <Grid item xs={12}>
                <SurgeriesTable
                    userRole="user"
                    type="registered"
                    title="Registered Surgeries"
                    broadcaster
                    onEditClick={onClickSurgery}/>
            </Grid>
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    tutorialInvoker: {
        fontSize: '14px',
        position: 'absolute',
        right: '20px',
        top: '10px',
        '&:hover': {
            color: '#0da3d9',
            cursor: 'pointer'
        },
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            display: 'none'
        }
    }
}));