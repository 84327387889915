import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Avatar from "react-avatar";
import { FaUpload } from 'react-icons/fa';
import styled from 'styled-components';
import axios from 'axios';

import { Button } from '../../../components/button/button';
import Modal from "../../../components/Modal";

const Icon = styled(FaUpload)`
  position: absolute;
  right: -.625rem;
  fill: white;
  bottom: 0;
  pointer-events: none;
  background-color: #4670e8;
  width: 2.5rem;
  height: 2.5rem;
  padding: .6rem;
  border-radius: 50%;
`;

const AbsPos = styled.div`
  display: inline-block;
  position: relative;
`;

export const DashboardSidebar = (props) => {
  const {
    firstName,
    lastName,
    profileImg,
  } = props.state || {};
  const fullName = `${firstName} ${lastName}` || null;

  let image = null;
  if (profileImg && profileImg.data) {
    const base64 = btoa(String.fromCharCode.apply(null, profileImg.data.data));
    image = `data:${profileImg.contentType};base64, ${base64}`;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [imgToUpload, setImgToUpload] = useState({});
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleImgUpload = event => {
    setImgToUpload(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("profileImg", imgToUpload);

    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
    };

    axios.post("/api/user/profile/upload", formData, config)
      .then(res => {
        const base64 = btoa(String.fromCharCode.apply(null, res.data.data.data));
        image = `data:${res.data.contentType};base64, ${base64}`;
        toggleModal();
      })
      .catch(err => {
        console.log(err);
      })
  };

  let path = props.match.path.split("/");
  path = path[path.length - 1];

  return (
    <div className='user-dashboard__sidebar'>
      <div className="sidebar__profile">
        <div className="profile__avatar text-center">
          <AbsPos className="mb-4">
            <Avatar src={image} name={fullName} round={true} onClick={path === "profile" ? toggleModal : null}/>
            {path === "profile" ? <Icon/> : null}
          </AbsPos>
          <h2 className="h6">{fullName}</h2>
        </div>
      </div>
      <ul className="dashboard__sidebar__nav">
        <li><NavLink activeClassName='is-active' exact to="/broadcaster/surgeries">Add/Edit Surgeries</NavLink></li>
        <li><NavLink activeClassName='is-active' exact to="/broadcaster/surgeries/past">Past Surgeries</NavLink></li>
        <li><NavLink activeClassName='is-active' to="/broadcaster/followers">Followers</NavLink></li>
        {/* <li><NavLink activeClassName='is-active' to="/broadcaster/groups">Groups</NavLink></li> */}
        <li><NavLink activeClassName='is-active' to="/broadcaster/profile">Profile</NavLink></li>
      </ul>
      <Modal show={isOpen} handleClose={toggleModal}>
        <h4 className="mb-3">Upload Profile Image</h4>
        <form className="display:flex">
          <input className="flex-grow-1" type="file" name="uploadImg" onChange={handleImgUpload}/>
          <Button rounded buttonColor="#4670e8"  className="ml-3" typ="submit" onClick={handleSubmit}>Save</Button>
        </form>
      </Modal>
    </div>
  )
};
