import React from 'react';

import './Modal.css';

const Modal = ({ handleClose, show, children }) => {

  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className='modal-main'>
        <button type="button" className="close" onClick={handleClose} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        {children}

      </section>
    </div>
  );
};

export default Modal;