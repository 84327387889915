import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button,
    Checkbox
} from '@material-ui/core';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';

import SectionTitle from 'components/dashboard/SectionTitle';
import EmptyArea from 'components/dashboard/EmptyArea';
import AddGroupModal from 'components/dashboard/AddGroupModal';
import UsersAPI from 'api/users';
import GroupsAPI from 'api/groups';

export default(props) => {
    const classes = useStyles();

    const [groups,
        setGroups] = useState(null);
    const [page,
        setPage] = React.useState(0);
    const pageSize = props.pageSize || 7;

    const getGroups = async() => {
        let gs = [];
        const fetchGroups = () => {
            GroupsAPI
                .list(props.user)
                .then(response => {
                    const {data, err} = response;

                    if (err) {
                        setGroups([]);
                        console.error('Error getting groups: ', err);
                        cogoToast.error('There was a problem getting groups');
                        return;
                    }

                    const g = gs.concat(data);
                    setGroups(g);
                });
        }

        const fetchRecentViewers = () => {
            UsersAPI
                .getBroadcasterRecentViewers()
                .then(response => {
                    const {data, err} = response;

                    if (err) {
                        console.error('Error getting recent viewers: ', err);
                        cogoToast.error('Problem getting recent viewers');
                        return;
                    }

                    gs.push({
                        _id: 'recentViewersGroup',
                        title: 'Recent Viewers',
                        members: data.viewers
                    });

                    fetchGroups();
                });
        }

        UsersAPI
            .getBroadcasterSubscribers()
            .then(response => {
                const {data, err} = response;

                if (err) {
                    console.error('Error getting your subscribers: ', err);
                    cogoToast.error('Problem getting your subscribers');
                    return;
                }

                gs.push({
                    _id: 'subscribersGroup',
                    title: 'Subscribers',
                    members: data.reverse() //Reverse so newest are first
                });

                fetchRecentViewers();
            });
    }

    useEffect(() => {
        getGroups();
    }, [props.renderControl]);

    const [groupToDelete,
        setGroupToDelete] = useState(null);

    const handleDelete = (group) => {
        if (group.title === 'Subscribers') {
            return;
        }

        setGroupToDelete(group);
    }

    const handleCloseDeleteConfirmation = () => {
        setGroupToDelete(null);
    }

    const handleConfirmDelete = () => {
        GroupsAPI
            .remove(groupToDelete._id)
            .then(({err}) => {
                if (err) {
                    cogoToast.error('Problem deleting this group');
                    return;
                }

                setGroupToDelete(null);
                cogoToast.success('Group deleted');
                getGroups();
            });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const [isAddVisible,
        setIsAddVisible] = useState(false);

    const handleShowAdd = () => {
        setIsAddVisible(true);
    }

    const handleHideAdd = () => {
        setIsAddVisible(false);
    }

    const handleSubmitAdd = (data) => {
        GroupsAPI
            .create(data)
            .then(response => {
                if (!response || response.err) {
                    cogoToast.error('Problem creating the group');
                    return;
                }

                cogoToast.success('Created a new group');
                handleHideAdd();
                getGroups();
            });
    }

    const renderAddBtn = () => {
        return (
            <Button
                variant="contained"
                color="primary"
                className={classes.addBtn}
                onClick={handleShowAdd}>
                <FontAwesomeIcon
                    icon={faPlus}
                    style={{
                    marginRight: '10px'
                }}/>
                Create a Group
            </Button>
        )
    }

    const [selectedGroups,
        setSelectedGroups] = useState(props.selected || []);

    const getGroupIndex = (ID) => {
        if (!selectedGroups || selectedGroups.length === 0) {
            return -1;
        }

        return selectedGroups.findIndex(s => s._id === ID);
    }

    const handleSelect = (subscriber) => {
        let s = selectedGroups;
        const i = getGroupIndex(subscriber._id);

        if (i === -1) {
            s.push(subscriber);
        } else {
            s.splice(i, 1);
        }

        setSelectedGroups([...s]);
        props.onUpdateSelection([...s])
    }

    const handleSelectAll = (event) => {
        const s = event.target.checked
            ? [...groups]
            : [];
        setSelectedGroups(s);
        props.onUpdateSelection(s);
    };

    const rowCount = groups
        ? groups.length
        : 0;
    let numSelected = selectedGroups.length;

    return (
        <div>
            <section className={classes.header}>
                <SectionTitle
                    title={props.title}
                    style={{
                    marginBottom: 0
                }}/> {/*  */}

                {(props.createVisible && Array.isArray(groups) && groups.length > 0) && renderAddBtn()}
            </section>

            <AddGroupModal
                visible={isAddVisible}
                onClose={handleHideAdd}
                onSubmit={handleSubmitAdd}/> {/*  */}

            {(!groups) && <EmptyArea title="Loading groups"/>}

            {(Array.isArray(groups) && groups.length === 0) && <EmptyArea {...getEmptyAreaProps(props.userRole, props.type)}/>}

            {(Array.isArray(groups) && groups.length > 0) && <React.Fragment>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table
                        className={classes.table}
                        style={{
                        ...props.tableStyle
                    }}>
                        <TableHead>
                            <TableRow>
                                {props.selection && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                            checked={rowCount > 0 && numSelected === rowCount}
                                            onChange={handleSelectAll}
                                            color="primary"
                                            inputProps={{
                                            'aria-label': 'select all groups'
                                        }}/>
                                    </TableCell>
                                )}
                                <TableCell>Name</TableCell>
                                <TableCell>Date Created</TableCell>
                                <TableCell>Members</TableCell>
                                {(props.viewable) && <TableCell align="right"></TableCell>}
                                {(props.deletable) && <TableCell align="right"></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groups
                                .slice(page * pageSize, page * pageSize + pageSize)
                                .map((g) => {
                                    const isSelected = getGroupIndex(g._id) !== -1
                                        ? true
                                        : false;

                                    return (
                                        <TableRow key={g._id} className={classes.tableRow}>
                                            {props.selection && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isSelected}
                                                        onClick={() => handleSelect(g)}
                                                        inputProps={{
                                                        'aria-labelledby': g._id
                                                    }}/>
                                                </TableCell>
                                            )}
                                            <TableCell component="th" scope="row">
                                                {g.title}
                                            </TableCell>
                                            <TableCell>{moment(g.start_time).format('M/D/YYYY')}</TableCell>
                                            <TableCell>{getMembersCount(g.members)}</TableCell>
                                            {(props.viewable) && <TableCell
                                                align="right"
                                                className="cursor-pointer hover_color-blue"
                                                onClick={() => props.onView(g)}>View Details and Members</TableCell>}
                                            {(props.deletable && (g._id === 'recentViewersGroup' || g._id === 'subscribersGroup')) && <TableCell align="right"></TableCell>}
                                            {(props.deletable && g._id !== 'recentViewersGroup' && g._id !== 'subscribersGroup') && <TableCell
                                                align="right"
                                                className="cursor-pointer hover_color-red"
                                                onClick={() => handleDelete(g)}>Delete</TableCell>}
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>

                    <TablePagination
                        className={classes.tablePagination}
                        component="div"
                        count={groups.length}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        page={page}
                        onChangePage={handleChangePage}/>
                </TableContainer>
            </React.Fragment>}

            {/* Delete confirmation modal */}
            {(groupToDelete && groupToDelete._id) && <Modal
                open={true}
                onClose={handleCloseDeleteConfirmation}
                className={classes.deleteModal}>
                <Paper elevation={0} className={classes.deleteModalPaper}>
                    <p style={{
                        marginBottom: 0
                    }}>Are you sure you want to delete</p>
                    <p>{groupToDelete.title}?</p>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.deleteModalActionBtn}
                            onClick={handleConfirmDelete}>
                            Yes, delete this group
                        </Button>
                    </div>

                    <span
                        className={classes.deleteModalDismiss}
                        onClick={handleCloseDeleteConfirmation}>No, dismiss</span>
                </Paper>
            </Modal>}
        </div>
    );
}

const getMembersCount = (u) => {
    return (u && u.length)
        ? u.length
        : '-';
}

const getEmptyAreaProps = (role, type) => {
    return {
        title: "No Subscribers and Groups",
        subTitle: "You will be able to create groups of subscribers when other users subscribe to y" +
                "ou"
    };
}

const useStyles = makeStyles({
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    addBtn: {
        color: '#fff',
        float: 'right',
        fontSize: '14px',
        padding: '7px 30px',
        textTransform: 'none',
        '&:focus': {
            outline: 'none'
        }
    },
    tableContainer: {
        marginBottom: 25,
        paddingBottom: 55, //to make space for pagination
        minHeight: 300,
        position: 'relative'
    },
    tablePagination: {
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    tableRow: {
        '&:hover': {
            backgroundColor: '#fafafa'
        }
    },
    tableRowDetail: {
        marginLeft: '10px',
        fontSize: '10px',
        color: '#363d56',
        border: '1px solid #c1c1c1',
        borderRadius: '50px',
        padding: '5px 10px'
    },
    deleteModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    deleteModalPaper: {
        maxWidth: '500px',
        padding: '30px 50px',
        textAlign: 'center'
    },
    deleteModalActionBtn: {
        margin: '40px 0 20px 0',
        color: '#fff',
        textTransform: 'none',
        padding: '10px 40px'
    },
    deleteModalDismiss: {
        fontSize: '16px',
        color: '#7b7b7b',
        cursor: 'pointer',
        '&:hover': {
            color: '#444'
        }
    }
});