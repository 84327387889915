import axios from 'axios';

/*
    Send answers of a surgery broadcast feedback form
*/
const sendAnswers = async(data) => {
    try {
        const response = await axios.post('/api/feedback/send', data);

        if (response.status === 200) {
            return {data: 'ok', err: null};
        }

        throw new Error('Feedback sending failed');
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Get feedback responses
*/
const list = async(data) => {
    try {
        const response = await axios.get('/api/feedback/list');

        if (response.status === 200) {
            return {data: response.data, err: null};
        }

        throw new Error('Problem getting feedback responses');
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

export default {
    sendAnswers,
    list
}