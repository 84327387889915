import React, { Component } from 'react';
import styled from 'styled-components';

import AboutHero from '../images/surgery-tweezers.jpg';
import {breakpoints, theme} from '../theme/';


const Hero = styled.section`
  background-image:  linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,.8)), url(${AboutHero});
	background-size: cover;
	background-position: center center;
  color: #fff;

  @media (min-width: 800px) {
    min-height: min-content;
    height: 60vh;
  }
  @media (min-width: 1200px) {
    height: 80vh;
  }
`

const HeroContent = styled.div`
		padding: 2rem;
    text-align: left;
    background-color: transparent;

		@media (min-width: 800px){
			text-align: left;
			padding: 3rem;
      max-width: 60rem;
      padding-top: 5rem;
    }
    
		h1{
			font-weight: 500;
      margin-bottom: 2rem;
      max-width: 18em;
      margin-bottom: 3rem;
      color: #fff;
      font-size: 2rem;
			@media (min-width: 800px){
				font-size: 3rem;
			}
    }
    
		p{
			@media (min-width: 800px){
				margin-bottom: 2rem;
			}
		}
`

const Mission = styled.section`
  padding: 1rem;
  @media (max-width: 640px) {
    padding: 1rem 2rem;
  }
  @media (min-width: ${breakpoints.mdPlus}) {
    padding: 5rem 0 6rem;
    min-height: max-content;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
  }
  h1{
    color: ${theme.colors.primary};
    @media (min-width: ${breakpoints.mdPlus}) {
      text-align: center;
      margin-bottom: 3rem;
    }
  }
  p{
    font-family: Montserrat, sans-serif;
    font-size: 1rem
    @media (min-width: ${breakpoints.mdPlus}) {
      margin: 0 auto;
      max-width: 40rem;
      text-align: center;
    }
    @media (min-width: ${breakpoints.lgPlus}) {
      font-size: 1.2rem;
      max-width: 70rem;
    
    @media (min-width: ${breakpoints.xl}) {
      font-size: 1.5rem;
    }
  }

`

class About extends Component {

  render () {
    return (
      <div className="about-page">
      <Hero>
        <HeroContent>
          <h1>Improving Healthcare Worldwide with 21st Century Solutions</h1>
          <p>Telesurgery is the future of information dissemination. Enhanced medical practice leads to better patient outcomes.</p>
        </HeroContent>
      </Hero>

      <Mission>
          <h1>Our Mission</h1>
          <p>SurgTime is a telesurgery platform that strives to facilitate surgical excellence around the world by expanding and enhancing access to medical education and interactive resources. We provide a real-time, audiovisual communication portal for surgeons, students, and industry members to engage each other, no matter their geographic location. In so doing, we hope to establish a collaborative medical community that is committed to globalizing modern approaches to surgery through open dialogue and professional expertise.</p>
      </Mission>
      
      <div>
        <img src="/images/surgery-in-progress.jpg" alt="Surgery in progress" />
      </div>

      <div className="section-7 ourpurpose">
        <div className="div-block-15 block15-ourpurpose">
          <h1 className="heading-8">Our Purpose</h1>
          <h5>The SurgTime platform aims to establish a global, academic network of surgeons with
            the goal of bridging gaps in technical expertise via an accessible,
            cost-effective communication pathway.</h5>

          <h2 className="heading-7">U.S Based Surgeons and Residents </h2>
          <p className="paragraph-6">
            In 2011, the Accreditation Council for Graduate Medical Education (ACGME)
            imposed a strict 80-hour per week limit on United States residency
            training programs which reduced resident exposure to patient care and
            scrub-in operations. Estimations show that the cut-back hours summed to
            about a year of surgical residency “lost”. Additionally, The Annals of
            Surgery published a 2013 study showing that 21% of fellowship program
            directors felt new fellows were unprepared for the operating room, and 66%
            of new fellows were deemed unable to operate for 30 unsupervised minutes
            of a major procedure.
          </p>
          <p className="paragraph-6">
            Clearly, many new surgeons do not receive enough individual surgery
            practice during residency in order to adequately perform. At a time when
            medicine is evolving faster than ever before, a surgeon’s skills must keep
            pace despite diminished practice. Telesurgery is the solution, and SurgTime is here to answer the call.
          </p>          
          <h2 className="heading-7">Surgeons Abroad</h2>
          <p className="paragraph-6">
            SurgTime is acutely aware of the scarcity of surgical services in the
            developing world. Only about 6% of the world’s surgeries occur in
            developing nations. This is in part due to the lack of surgical education
            and resources afforded to these surgeons. However, these providers are
            eager to learn, and their patient populations are in need.
          </p>
          <p className="paragraph-7">
            We help surgeons in medically
            underserved regions improve their technical accuracy, thereby enhancing
            the treatment provided to their patients. SurgTime’s virtual platform
            uniquely connects surgeons around the world in real-time, eliminating the
            need for long-distance travel to partake in surgical collaboration. We are
            removing the geographic barriers that prevent the practice of high-quality
            surgical care.
          </p>
          <h2 className="heading-7">Medical Device Representatives</h2>
          <p>
            The number of device-dependent procedures has exponentially expanded in
            recent years while insurers have concurrently shortened their leash on
            rising health care prices. These decisions have forced hospitals and
            device manufacturers to restrict a medical device representative’s
            presence in the operating room.
            <br />
          </p>
          <p className="paragraph-5">
            So how can companies best supply the increasing demand for surgical
            representatives given their finite personnel and resources? Virtual
            interactions through telesurgery allow representatives remote access to
            surgeons. This connection provides increased work flexibility, reduces
            costs, and adheres to hospital regulations.
          </p>
        </div>
      </div>

      <div>
        <img src="/images/new-surgeon-banner.png" alt="Surgery in progress" />
      </div>

      <div className="section-9 join-us">
        <h1 className="heading-9">Join Us</h1>
        <p className="paragraph-8">
          Become a Broadcasting Surgeon for a Public Viewing or a Private Session
        </p>
        <div className="div-block-16">
          <div className="audiencecolumn">
            <h1 className="heading-3 heading-4">Surgeons</h1>
            <div className="max-width-1">
              Display your surgical expertise stemming from years of professional
              experience and practice. There is a global audience that is eager to
              observe and learn from you. Offer them the chance to ask questions or
              provide feedback in real-time while you perform surgery live!
            </div>
            {/* <div className="div-block-20">
              <h4 className="heading-10">Are You a New Surgeon?</h4>
              <div className="text-block-8">
                Take comfort knowing that you have mentors watching who can help you
                through a difficult procedure without being physically present.&nbsp;
              </div>
            </div> */}
            {!this.props.state.loggedIn && <a href="/signup" className="button-2 w-button" style={{color: '#fff'}}>
              Register
            </a>}
          </div>
          <div className="audiencecolumn">
            <h1 className="heading-3 heading-4">Medical Device Representatives</h1>
            <div className="max-width-1">
              Why drive from hospital to hospital when you can maintain relationships
              with surgeons through SurgTime’s virtual portal? Increase your daily
              number of surgeries without having to travel while still playing an
              essential role in the operating room.
            </div>
            <div className="div-block-18" />
            {!this.props.state.loggedIn && <a href="/signup" className="button-2 w-button" style={{color: '#fff'}}>
              Register
            </a>}
          </div>
        </div>
      </div>



      </div>
    )
  }
}

export default About;