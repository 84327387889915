import React, {useState} from 'react';
import {Grid, makeStyles, Button} from '@material-ui/core';
import Avatar from 'react-avatar';
import cogoToast from 'cogo-toast';

import UploadProfileImgModal from 'components/dashboard/UploadProfileImgModal';
import PositionOptions from 'components/PositionOptions';
import Util from 'service/util';

const requiredFields = [
    'firstName',
    'lastName',
    'email',
    'dob',
];

export default (props) => {
    const classes = useStyles();
    const [form,
        setForm] = useState(props.form);
    const [invalidFields, setInvalidFields] = useState({});
    const [passwordError, setPasswordError] = useState(null);
    const [isProfileImgModalVisible, setIsProfileImgModalVisible] = useState(false);

    const handleInputChange = (event) => {
        if(props.mode === 'view-public') {
            return;
        }

        let {type, value, name, checked} = event.target;
        value = type === "checkbox"
            ? checked
            : value;

        setForm({
            ...form,
            ...{
                [name]: value
            }
        });
    }

    const isFormValid = () => {
        let invalid = {};

        requiredFields.map((field) => {
            if(Util.isFieldEmpty(form[field])){
                invalid[field] = 'true';
            }

            return null;
        });

        setInvalidFields(invalid);

        if(Object.keys(invalid).length > 0) {
            return false;
        }

        return true;
    }

    const arePasswordsValid = () => {
        setPasswordError(null);

        if (Util.isFieldEmpty(form.newPassword) && Util.isFieldEmpty(form.confirmPassword)) {
            return true;
        }

        if (Util.isFieldEmpty(form.oldPassword)) {
            setPasswordError(`Old password can't be empty `);
            return false;
        }

        if (form.newPassword.length < 6) {
            setPasswordError('New password must be at least 6 characters long');
            return false;
        }

        if (form.newPassword !== form.confirmPassword) {
            setPasswordError('New password and password confirmation must be the same');
            return false;
        }

        return true;
    }

    const handleShowProfileImgModal = () => {
        if(props.mode === 'view-public') {
            return;
        }

        setIsProfileImgModalVisible(true);
    }

    const handleHideProfileImgModal = () => {
        setIsProfileImgModalVisible(false);
    }

    const handleProfileImgSuccess = (pi) => {
        setForm({
            ...form,
            profileImg: pi
        });

        handleHideProfileImgModal();
        cogoToast.success('Profile picture updated');
        props.onProfileImgUpdate();
    }

    const handleSubmit = (e) => {
        const formValid = isFormValid();
        const passwordsValid = arePasswordsValid();
        if (!formValid || !passwordsValid) {
            return;
        }

        props.onSubmit(form);
    }

    return (
        <React.Fragment>
            <Grid
                container
                className={classes.gridContainer}
                spacing={6}>
                <Grid item xs={12} md={8} className={classes.gridItem}>
                    <form className={classes.formContainer}>
                        <h4 className={classes.sectionTitle}>Personal Information</h4>
                        <div className={classes.verticalItems}>
                            <label htmlFor="form--firstName" style={{marginRight: '30px'}}>
                                <span className={classes.formLabelText}>First name</span>
                                <input
                                    disabled={props.mode === 'view-public'}
                                    id="form--firstName"
                                    className={`${classes.formInput} ${invalidFields['firstName']? '--invalid' : ''}`}
                                    type="text"
                                    name="firstName"
                                    value={form.firstName}
                                    onChange={handleInputChange}
                                    required/>
                                {(invalidFields['firstName']) && <span className={classes.formInputError}>First name can't be empty</span>}
                            </label>


                            <label htmlFor="form--lastName" style={{marginRight: '30px'}}>
                                <span className={classes.formLabelText}>Last name</span>
                                <input
                                    disabled={props.mode === 'view-public'}
                                    id="form--lastName"
                                    className={`${classes.formInput} ${invalidFields['lastName']? '--invalid' : ''}`}
                                    type="text"
                                    name="lastName"
                                    value={form.lastName}
                                    onChange={handleInputChange}
                                    required/>
                                {(invalidFields['lastName']) && <span className={classes.formInputError}>Last name can't be empty</span>}
                            </label>

                            <label htmlFor="form--title">
                                <span className={classes.formLabelText}>Title</span>
                                <select
                                    disabled={props.mode === 'view-public'}
                                    id="form--title"
                                    className={`${classes.formInput} ${invalidFields['title']? '--invalid' : ''}`}
                                    name="title"
                                    value={form.title}
                                    onChange={handleInputChange}
                                    required>
                                    <option disabled defaultChecked>Select a title</option>
                                    <option value="">Other</option>
                                    <option value="Dr.">Dr.</option>
                                    <option value="PA-C">PA-C</option>
                                </select>                                    
                                {(invalidFields['title']) && <span className={classes.formInputError}>Title can't be empty</span>}
                            </label>                            
                        </div>

                        <div className={classes.verticalItems}>
                            <label htmlFor="form--email" style={{marginRight: '30px'}}>
                                <span className={classes.formLabelText}>Email</span>
                                <input
                                    disabled={props.mode === 'view-public'}
                                    id="form--email"
                                    className={`${classes.formInput} ${invalidFields['email']? '--invalid' : ''}`}
                                    type="text"
                                    name="email"
                                    value={form.email}
                                    onChange={handleInputChange}
                                    required/>
                                {(invalidFields['email']) && <span className={classes.formInputError}>Email can't be empty</span>}
                            </label>


                            <label htmlFor="form--dob">
                                <span className={classes.formLabelText}>Date of birth</span>
                                <input
                                    disabled={props.mode === 'view-public'}
                                    id="form--dob"
                                    className={`${classes.formInput} ${invalidFields['dob']? '--invalid' : ''}`}
                                    type="text"
                                    name="dob"
                                    value={form.dob || ''}
                                    onChange={handleInputChange}
                                    required/>
                                {(invalidFields['dob']) && <span className={classes.formInputError}>Date of birth can't be empty</span>}
                            </label>
                        </div>


                        <div className={classes.verticalItems}>
                            <label htmlFor="form--position" style={{marginRight: '30px'}}>
                                <span className={classes.formLabelText}>Position</span>
                                <select
                                    disabled={props.mode === 'view-public'}
                                    id="form--position"
                                    className={`${classes.formInput} ${invalidFields['position']? '--invalid' : ''}`}
                                    type="text"
                                    name="position"
                                    value={form.position}
                                    onChange={handleInputChange}
                                    required>
                                    <option disabled defaultChecked>Select a position</option>
                                    <PositionOptions/>
                                </select>
                                {(invalidFields['position']) && <span className={classes.formInputError}>Position must be be selected</span>}
                            </label>

                            <label></label>
                        </div>

                        {(props.role === 'broadcaster') && <div>
                            <label htmlFor="form--email" style={{width: '100%'}}>
                                <span className={classes.formLabelText}>Bio</span>
                                <textarea
                                    disabled={props.mode === 'view-public'}
                                    id="form--bio"
                                    className={classes.formInput}
                                    name="bio"
                                    value={form.bio}
                                    onChange={handleInputChange}/>
                            </label>
                        </div>}

                        {(props.mode !== 'view-public') &&
                        <React.Fragment>
                            <h4 className={classes.sectionTitle}>Password</h4>

                            <div className={classes.verticalItems}>
                                <label htmlFor="form--oldPassword" style={{marginRight: '30px'}}>
                                    <span className={classes.formLabelText}>Old password</span>
                                    <input
                                        id="form--oldPassword"
                                        className={`${classes.formInput} ${invalidFields['oldPassword']? '--invalid' : ''}`}
                                        type="password"
                                        name="oldPassword"
                                        value={form.oldPassword || ''}
                                        onChange={handleInputChange}
                                        required/>
                                </label>
                                <label></label>
                            </div>

                            <div className={classes.verticalItems}>
                                <label htmlFor="form--newPassword" style={{marginRight: '30px'}}>
                                    <span className={classes.formLabelText}>New password</span>
                                    <input
                                        id="form--newPassword"
                                        className={`${classes.formInput} ${invalidFields['newPassword']? '--invalid' : ''}`}
                                        type="password"
                                        name="newPassword"
                                        value={form.newPassword || ''}
                                        onChange={handleInputChange}
                                        required/>
                                </label>


                                <label htmlFor="form--confirmPassword">
                                    <span className={classes.formLabelText}>New password confirmation</span>
                                    <input
                                        id="form--confirmPassword"
                                        className={`${classes.formInput} ${invalidFields['confirmPassword']? '--invalid' : ''}`}
                                        type="password"
                                        name="confirmPassword"
                                        value={form.confirmPassword || ''}
                                        onChange={handleInputChange}
                                        required/>
                                </label>
                            </div>

                            {(passwordError) && <span className={classes.formInputError}>{passwordError}</span>}
                        </React.Fragment>}
                    </form>

                    {(props.mode !== 'view-public') && <Button
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    onClick={handleSubmit}>
                        {props.isLoading
                            ? 'Saving...'
                            : 'Save'}
                    </Button>}
                </Grid>

                <Grid item xs={12} md={4} className={classes.gridItem}>
                    <section className={classes.profilePicEditor}>
                        <Avatar
                            src={Util.getDisplayableAvatar(form.profileImg)}
                            name={props.form.firstName + ' ' + props.form.lastName }
                            round={true}
                            style={{
                                cursor: 'pointer'
                            }}                            
                            size="170"
                            onClick={handleShowProfileImgModal}/>
                            {(props.mode !== 'view-public') && <div style={{margin: '15px 0 0 0'}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.profileImgBtn}
                                    onClick={handleShowProfileImgModal}>
                                        Update profile picture
                                </Button>
                            </div>}
                    </section>
                </Grid>
            </Grid>

            <UploadProfileImgModal
                firstName={form.firstName}
                visible={isProfileImgModalVisible}
                onHide={handleHideProfileImgModal}
                onSuccess={handleProfileImgSuccess}/>
    </React.Fragment>
    );
}

const formInputStyle = {
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: '5px',
    marginBottom: '30px',
    width: '100%',
    fontSize: '16px',
    appearance: 'none',
    padding: '.5em 1em',
    color: '#353535',
    '&:focus': {
        outline: 'none',
        boxShadow: '0px 3px 30px 4px #e6e6e6'
    },
    '&.--invalid': {
        border: '1px solid #e63500',
    }
};

const useStyles = makeStyles((theme) => ({
    instructionText: {
        marginTop: '-10px',
        fontSize: '16px',
        marginBottom: '40px'
    },
    gridContainer: {
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse'
        }        
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column'
    },
    sectionTitle: {
        fontSize: '19px',
        paddingBottom: '5px',
        marginBottom: '25px',
        borderBottom: '1px solid #f7f7f7'
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        padding: '30px',
        borderRadius: '5px'
    },
    formLabelText: {
        display: 'block',
        fontSize: '16px',
        marginBottom: '20px',
        color: '#353d57'
    },
    formInput: {
        ...formInputStyle,
        marginRight: '5px'
    },
    formInputError: {
        marginTop: '-25px',
        display: 'block',
        fontSize: '15px',
        color: '#e63500',
        marginBottom: '3px'
    },
    actionBtn: {
        color: '#fff',
        textTransform: 'none',
        padding: '15px 80px',
        width: '200px',
        marginTop: '30px',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    verticalItems: {
        display: 'flex',
        justifyContent: 'space-between',
        '&>label': {
            flex: 1
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            '&>label': {
                width: '100%'
            },            
        }        
    },
    durationContainer: {
        display: 'flex',
        marginBottom: '30px'
    },
    durationContainerItem: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '20px'
    },
    durationContainerItemSelect: {
        ...formInputStyle,
        margin: '0 !important',
        width: '70%'
    },
    profilePicEditor: {
        backgroundColor: '#fff',
        padding: '40px 20px',
        borderRadius: '5px',
        textAlign: 'center'
    },
    profileImgBtn: {
        color: '#fff',
        textTransform: 'none',
        fontSize: '12px'
    }
}));