import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, Paper, ButtonGroup, Button} from '@material-ui/core';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons'

import SubscribersTable from 'components/dashboard/SubscribersTable';
import RecentViewersTable from 'components/dashboard/RecentViewersTable';
import GroupsTable from 'components/dashboard/GroupsTable';
import SectionTitle from 'components/dashboard/SectionTitle';
import SurgeriesAPI from 'api/surgeries';
import {validateEmail, getFullNameWithTitle} from 'service/util';
import TimezoneSvc from 'service/timezones';

export default(props) => {
    const classes = useStyles();

    const [mode,
        setMode] = useState('subscribers');

    const handleChangeMode = (mode) => {
        setMode(mode);
    }

    const [subscribers,
        setSubscribers] = useState([]);

    const handleUpdateSubscribers = (selected) => {
        setSubscribers(selected);
    }

    const [recentViewers,
        setRecentViewers] = useState([]);

    const handleUpdateRecentViewers = (selected) => {
        setRecentViewers(selected);
    }

    const [groups,
        setGroups] = useState([]);

    const handleUpdateGroups = (selected) => {
        setGroups(selected);
    }

    const [recipients,
        setRecipients] = useState([]);
    const [newInviteeEmail,
        setNewInviteeEmail] = useState('');

    const handleChangeInviteeEmail = (e) => {
        setNewInviteeEmail(e.target.value);
    }

    const handleInviteeEmailKeyPress = (e) => {
        if (['Enter', 'Tab'].includes(e.key)) {
            e.preventDefault();
            handleAddInviteeEmail();
        }
    }

    const handleAddInviteeEmail = () => {
        let inviteeEmails = newInviteeEmail.split(/,| /);

        if (inviteeEmails.length > 1) {
            handleAddMultipleInviteeEmails(inviteeEmails);
            return;
        }

        if (!validateEmail(newInviteeEmail)) {
            cogoToast.warn('Not a valid email address');
            return;
        }

        if (recipients.indexOf(newInviteeEmail) !== -1) {
            cogoToast.warn('Email already in the list');
            return;
        }

        setRecipients([
            ...recipients,
            newInviteeEmail
        ]);
        setNewInviteeEmail('');
    }

    const handleAddMultipleInviteeEmails = (emails) => {
        let validEmails = [];

        emails.forEach(e => {
            const el = e
                .trim()
                .length; // to check if its an empty space

            if (!validateEmail(e)) {
                if (el) 
                    cogoToast.warn(`${e} is not a valid email address`);
                return;
            }

            if (recipients.indexOf(e) !== -1) {
                if (el) 
                    cogoToast.warn(`${e} is already in the list`);
                return;
            }

            if (validEmails.indexOf(e) !== -1) {
                // ignore the duplicate without warning
                return;
            }

            validEmails.push(e);
        });

        let r = recipients.concat(validEmails);
        setRecipients(r);

        setNewInviteeEmail('');
    }

    const handleRemoveInviteeEmail = (i) => {
        let r = recipients;
        r.splice(i, 1);

        setRecipients([...r]);
    }

    const combineAllModes = () => {
        let individual = (recipients && recipients.length > 0)
            ? recipients
            : [];
        let subs = (subscribers && subscribers.length > 0)
            ? subscribers.map(s => s.email)
            : [];
        let recViewers = (recentViewers && recentViewers.length > 0)
            ? recentViewers.map(s => s.email)
            : [];
        let gs = (groups && groups.length > 0)
            ? getRecipientsFromGroups(groups)
            : [];
        let merged = individual
            .concat(subs)
            .concat(recViewers)
            .concat(gs);

        return keepUnique(merged);
    }

    const handleSend = () => {
        let r = combineAllModes();

        if (!r || r.length === 0) {
            cogoToast.warn('No users selected');
            return;
        }

        const startTime = moment.tz(props.surgery.start_time, props.surgery.timezone);
        let surgeryTime = startTime.format('h:mm A');

        if (props.surgery.timezone) {
            surgeryTime += ` (${TimezoneSvc.getNameByID(props.surgery.timezone)})`
        }

        const invitation = {
            invitationList: r,
            surgeryInfo: 'https://www.surgtime.com/app/surgery/' + props.surgery._id,
            broadcaster: getFullNameWithTitle(props.surgery.broadcaster, props.isFullName),
            date: startTime.format('MMMM D YYYY'),
            time: surgeryTime
        };

        SurgeriesAPI
            .sendInvitations(invitation)
            .then(response => {
                const {err} = response;

                if (err) {
                    cogoToast.error('Problem sending invitations');
                    console.error('Error sending invitations: ', err);
                    return;
                }

                const type = (mode === 'groups')
                    ? 'groups'
                    : 'subscribers';
                cogoToast.success(`Invitations sent to selected ${type}`, {hideAfter: 4});
                props.onHide();
            })
    }

    const recCount = combineAllModes().length;

    return (
        <Modal
            open={props.visible}
            onClose={props.onHide}
            disableBackdropClick
            disableEscapeKeyDown
            className={classes.invitesModal}>
            <Paper elevation={0} className={classes.invitesModalPaper}>
                <section className={classes.content}>
                    <ButtonGroup
                        className={classes.invitesModalModes}
                        size="small"
                        aria-label="small outlined button group">
                        <Button
                            className={classes.invitesModalMode}
                            color={mode === 'subscribers'
                            ? 'primary'
                            : ''}
                            onClick={() => handleChangeMode('subscribers')}>Subscribers</Button>
                        <Button
                            className={classes.invitesModalMode}
                            color={mode === 'recentViewers'
                            ? 'primary'
                            : ''}
                            onClick={() => handleChangeMode('recentViewers')}>Recent Viewers</Button>
                        <Button
                            className={classes.invitesModalMode}
                            color={mode === 'groups'
                            ? 'primary'
                            : ''}
                            onClick={() => handleChangeMode('groups')}>Groups</Button>
                        <Button
                            className={classes.invitesModalMode}
                            color={mode === 'individual'
                            ? 'primary'
                            : ''}
                            onClick={() => handleChangeMode('individual')}>Individual</Button>
                    </ButtonGroup>

                    {(mode === 'subscribers') && (<SubscribersTable
                        selected={subscribers}
                        title="Select subscribers to invite"
                        selection
                        email
                        onUpdateSelection={handleUpdateSubscribers}
                        tableStyle={{
                        border: '1px solid #eee'
                    }}/>)}

                    {(mode === 'recentViewers') && (<RecentViewersTable
                        selected={recentViewers}
                        showRecentViewers={true}
                        title="Select recent viewers to invite"
                        selection
                        email
                        onUpdateSelection={handleUpdateRecentViewers}
                        tableStyle={{
                        border: '1px solid #eee'
                    }}/>)}

                    {(mode === 'groups') && (<GroupsTable
                        selected={groups}
                        title="Select groups to invite"
                        selection
                        onUpdateSelection={handleUpdateGroups}
                        tableStyle={{
                        border: '1px solid #eee'
                    }}/>)}

                    {(mode === 'individual') && (
                        <div>
                            <SectionTitle title='Enter invitation emails'/>

                            <p className={classes.inviteeEmailsInstructions}>You can add up to 100 emails.
                                They can be added one by one or as a list separated by commas or spaces.</p>

                            <section
                                style={{
                                marginTop: '10px'
                            }}>
                                <input
                                    className={classes.inviteeEmailInput}
                                    type="email"
                                    placeholder="Email address"
                                    value={newInviteeEmail}
                                    onChange={handleChangeInviteeEmail}
                                    onKeyPress={handleInviteeEmailKeyPress}/>
                                <Button
                                    className={classes.btnAdder}
                                    disabled={!newInviteeEmail}
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={handleAddInviteeEmail}>Add</Button>

                                <Button
                                    className={classes.btnListClearer}
                                    disabled={!recipients || recipients.length === 0}
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={() => setRecipients([])}>Clear emails</Button>
                            </section>

                            {(recipients && recipients.length > 0) && <ul className={classes.inviteeEmailList}>
                                {recipients.map((re, k) => (
                                    <li className={classes.inviteeEmailListItem} key={k}>
                                        {re}

                                        <Button
                                            className={classes.inviteeEmailListItemRemover}
                                            elevation={0}
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            onClick={() => handleRemoveInviteeEmail(k)}>
                                            <FontAwesomeIcon icon={faTimes}/>
                                        </Button>
                                    </li>
                                ))}</ul>}
                        </div>
                    )}

                </section>

                <section className={classes.btns}>
                    <Button
                        className={classes.btnClose}
                        variant='contained'
                        color='default'
                        size='small'
                        onClick={() => props.onHide()}>
                        Close
                    </Button>
                    <Button
                        disabled={!recCount}
                        className={classes.btn}
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={handleSend}>
                        Send Invitations {recCount
                            ? `(${recCount})`
                            : ''}
                    </Button>
                </section>
            </Paper>
        </Modal>
    );
}

const useStyles = makeStyles({
    invitesModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    invitesModalPaper: {
        width: '1000px',
        maxWidth: '95%',
        padding: '30px 30px'
    },
    invitesModalModes: {
        float: 'right'
    },
    invitesModalMode: {
        fontSize: '13px',
        '&:focus': {
            outline: 'none'
        }
    },
    invitesModalTitle: {
        fontSize: '30px'
    },
    content: {
        minHeight: '400px',
        maxHeight: '600px',
        width: '100%',
        overflowY: 'auto'
    },
    inviteeEmailInput: {
        fontSize: '16px',
        borderRadius: '2px',
        marginRight: '10px',
        width: '360px',
        '&:focus': {
            outline: 'none',
            borderColor: '#66b1cc'
        }
    },
    inviteeEmailListEmpty: {
        marginTop: '20px',
        color: '#565656',
        fontWeight: '200',
        fontSize: '18px'
    },
    inviteeEmailsInstructions: {
        fontSize: '14px'
    },
    inviteeEmailList: {
        marginTop: '20px',
        paddingLeft: '0'
    },
    inviteeEmailListItem: {
        display: 'inline-flex',
        alignItems: 'center',
        background: '#f7f7f7',
        borderRadius: '50px',
        padding: '7px 60px 7px 15px',
        marginRight: '15px',
        marginBottom: '15px',
        position: 'relative',
        fontSize: '15px'
    },
    inviteeEmailListItemRemover: {
        float: 'right',
        color: '#FFF',
        background: '#f35627',
        height: '20px',
        width: '20px',
        position: 'absolute',
        right: '10px',
        minWidth: '0',
        padding: '0',
        fontSize: '14px',
        borderRadius: '50%'
    },
    btnAdder: {
        color: '#fff',
        padding: '7px 30px',
        marginTop: '-4px'
    },
    btnListClearer: {
        backgroundColor: '#f19435',
        color: '#fff',
        padding: '7px 30px',
        marginTop: '-4px',
        float: 'right'
    },
    btns: {
        paddingTop: '10px'
    },
    btnClose: {
        background: 'transparent',
        color: '#464646',
        border: '1px solid #8e8e8e',
        textTransform: 'none',
        padding: '5px 30px'
    },
    btn: {
        color: '#fff',
        textTransform: 'none',
        padding: '5px 30px',
        float: 'right'
    }
});

const getRecipientsFromGroups = (groups) => {
    if (!groups || groups.length === 0) {
        return [];
    }

    let members = groups.map(g => g.members);
    let merged = []
        .concat
        .apply([], members);
    let emails = merged.map(m => m.email);

    return emails;

}

const keepUnique = (arr) => {
    if (!arr || arr.length === 0) {
        return [];
    }

    return arr.filter((e, pos, arr) => arr.indexOf(e) === pos);
}