import React from 'react';
import {withRouter} from 'react-router-dom';

import GroupsTable from 'components/dashboard/GroupsTable';

const Groups = (props) => {
    const handleView = (group) => {
        props
            .history
            .push('/app/g/' + group._id);
    }

    return (
        <div>
            <GroupsTable
                title="Groups"
                viewable
                onView={handleView}
                deletable
                createVisible/>
        </div>
    )
}

export default withRouter(Groups);