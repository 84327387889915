import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import axios from 'axios';
import moment from 'moment';

import SurgeriesSvc from './../service/surgeries';
import Card from '../components/SurgeryCard';
import rotatorcuff from '../images/cover-photos/surgery-cover-01.png';
import labrum from '../images/cover-photos/surgery-cover-02.png';
import acl from '../images/cover-photos/surgery-cover-03.png';
import meniscus from '../images/cover-photos/surgery-cover-04.png';

class Surgeries extends Component {
  state = {
    surgeries: [],
    images: {
      labrum,
      acl,
      meniscus,
      rotatorcuff
    }
  };

  componentDidMount() {
    this.getSurgeries();
  }

  getSurgeries = () => {
    axios.get("/api/user/surgeries").then(res => {
      this.setState({
        surgeries: res.data
      });
    });
  };

  render() {
    if (!this.props.state.loggedIn) {
      return (
        <div>
          <p>Surgeries Page</p>
          <p>Please Log In</p>
        </div>
      );
    }

    const liveSurgeries = SurgeriesSvc.getLiveSurgeries(this.state.surgeries);
    const upcomingSurgeries = SurgeriesSvc.getUpcomingSurgeries(this.state.surgeries);
    const previousSurgeries = SurgeriesSvc.getPreviousSurgeries(this.state.surgeries);

    return (
      <>
        <main className="surgeries">
          {liveSurgeries.length ? (
            <section className="live-surgeries surgeries__section">
              <header className="surgeries__header space-between">
                <h1 className="h3">Live Surgeries</h1>
              </header>

              <ul className="upcoming-surgeries__list surgeries__grid__list">
                {liveSurgeries.map(surgery => {
                  const date = moment(surgery.start_time);
                  const dateComponent = date.utc().format("l");
                  const timeComponent = moment
                    .utc(date)
                    .local()
                    .format("hh:mma");
                  return (
                    <li
                      className="upcoming-surgeries__item surgeries__grid__item"
                      key={surgery._id}
                    >
                      <Link to={"/surgeries/" + surgery._id}>
                        <Card>
                          <span className="card__label">LIVE</span>
                          <img
                            src={this.state.images[surgery.image]}
                            alt={surgery.name}
                            className="surgery__card__img"
                          />
                          <div className="surgery__card__body">
                            <h2 className="surgery__card__title h4">
                              {surgery.topic}
                            </h2>
                            <div className="surgeon__card__name">
                              {surgery.doctor}
                            </div>
                            <div className="surgery__card__meta">
                              <time>
                                {dateComponent} - {timeComponent}
                              </time>
                              <div className="registered-users">
                                {surgery.registered_users.map((user, index) => {
                                  let image;
                                  if (user.profileImg && user.profileImg.data) {
                                    const base64 = btoa(String.fromCharCode.apply(null, user.profileImg.data.data));
                                    image = `data:${user.profileImg.contentType};base64, ${base64}`;
                                  }
                                  return (
                                    index < 3 ? (
                                      <Avatar
                                        src={image}
                                        key={index}
                                        name={`${user.firstName} ${user.lastName}`}
                                        size="30"
                                        className="registered-users__img"
                                        alt=""
                                      />
                                  ) : null
                                  )
                                })}
                                <span className="registered-users__registered">
                                  {surgery.registered_users_count} Registered
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </section>
          ) : null}

          {upcomingSurgeries.length ? (
            <section className="upcoming-surgeries surgeries__section">
              <header className="surgeries__header space-between">
                <h1 className="h3">Upcoming Surgeries</h1>
              </header>

              <ul className="upcoming-surgeries__list surgeries__grid__list">
                {upcomingSurgeries.map(surgery => {
                  const date = moment(surgery.start_time);
                  const dateComponent = date.utc().format("l");
                  const timeComponent = moment
                    .utc(date)
                    .local()
                    .format("hh:mma");
                  return (
                    <li
                      className="upcoming-surgeries__item surgeries__grid__item"
                      key={surgery._id}
                    >
                      <Link to={"/surgeries/" + surgery._id}>
                        <Card>
                          <img
                            src={this.state.images[surgery.image]}
                            alt={surgery.name}
                            className="surgery__card__img"
                          />
                          <div className="surgery__card__body">
                            <h2 className="surgery__card__title h4">
                              {surgery.topic}
                            </h2>
                            <div className="surgeon__card__name">
                              {surgery.doctor}
                            </div>
                            <div className="surgery__card__meta">
                              <time>
                                {dateComponent} - {timeComponent}
                              </time>
                              <div className="registered-users">
                                {surgery.registered_users.map((user, index) => {
                                  let image;
                                  if (user.profileImg && user.profileImg.data) {
                                    const base64 = btoa(String.fromCharCode.apply(null, user.profileImg.data.data));
                                    image = `data:${user.profileImg.contentType};base64, ${base64}`;
                                  }
                                  return (
                                    index < 3 ? (
                                      <Avatar
                                        src={image}
                                        name={`${user.firstName} ${user.lastName}`}
                                        size="30"
                                        className="registered-users__img"
                                        alt=""
                                        key={user._id}
                                      />
                                   ) : null
                                  )
                                })}
                                <span className="registered-users__registered">
                                  {surgery.registered_users_count} Registered
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </section>
          ) : null}

          <section className="previous-surgeries surgeries__section">
            <header className="surgeries__header space-between">
              <h1 className="h3">Past Surgeries</h1>
            </header>
            <ul className="previous-surgeries__list surgeries__grid__list">
              {previousSurgeries.map(surgery => {
                const date = moment(surgery.start_time);
                const dateComponent = date.utc().format("l");
                const timeComponent = moment
                  .utc(date)
                  .local()
                  .format("hh:mma");
                return (
                  <li
                    className="previous-surgeries__item surgeries__grid__item"
                    key={surgery._id}
                  >
                    <Link to={"/surgeries/" + surgery._id + "/previous"}>
                      <Card>
                        <img
                          src={this.state.images[surgery.image]}
                          alt={surgery.name}
                          className="surgery__card__img"
                        />
                        <div className="surgery__card__body">
                          <h2 className="surgery__card__title h4">
                            {surgery.topic}
                          </h2>
                          <div className="surgeon__card__name">
                              {surgery.doctor}
                          </div>
                          <div className="surgery__card__meta">
                            <time>
                              {dateComponent} - {timeComponent}
                            </time>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </section>
        </main>
      </>
    );
  }
}

export default Surgeries;