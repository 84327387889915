import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from 'App';
import 'css/styles.css';

Sentry.init({
    dsn: "https://04281583f3f946b796356ff7b5741f43@o528563.ingest.sentry.io/5645987",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

ReactDOM.render(
    <BrowserRouter><App/></BrowserRouter>, document.getElementById('root'));
