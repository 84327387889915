import React, { Component } from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import axios from 'axios';
import { FaUpload } from 'react-icons/fa';
import styled from 'styled-components';

import UsersAPI from './../api/users';
import { Button } from '../components/button/button';
import Modal from "./Modal";

const Icon = styled(FaUpload)`
  position: absolute;
  right: -.625rem;
  fill: white;
  bottom: 0;
  pointer-events: none;
  background-color: #4670e8;
  width: 2.5rem;
  height: 2.5rem;
  padding: .6rem;
  border-radius: 50%;
`;

const AbsPos = styled.div`
  display: inline-block;
  position: relative;
`;

class UserDashboardSidebar extends Component {
  state = {
    firstName: '',
    lastName: '',
    profileImg: null,
    uploadImg: "",
    modalToggle: false,
    modalContent: ""
  };

  componentDidMount() {
    this.getUserProfile();
  };

  getUserProfile = () => {
    axios.get('/api/user/profile/')
      .then((userProfile) => {
        const { firstName, lastName, profileImg } = userProfile.data;
        if (profileImg.data) {
          const base64 = btoa(String.fromCharCode.apply(null, profileImg.data.data));
          const image = `data:${profileImg.contentType};base64, ${base64}`;
          this.setState({
            profileImg: image
          })
        }
        this.setState({
          firstName,
          lastName
        })
      })
  };

  showModal = (event) => {
    this.setState({
      modalToggle: true
    });
  };

  hideModal = event => {
    this.setState({
      modalToggle: false,
      modalContent: ""
    });
  };

  handleInputChange = event => {
    if (event.target.name === 'uploadImg') {
      this.setState({
        uploadImg: event.target.files[0]
      })
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    UsersAPI.uploadImg(this.state.uploadImg).then(response => {
      const {data, err} = response;

      if(err) {
        console.error('Error uploading user image: ', err);
        //TODO: show an alert
      } else {
        this.setState({
          profileImg: data,
          modalToggle: false,
          modalContent: ''
        });
        //TODO: show success message
      }
    });
  }

  render() {
    const { firstName, lastName } = this.state;
    const fullName = `${firstName} ${lastName}`;
    let path = this.props.match.path.split("/");
    path = path[path.length - 1];
    return (
      <div className="user-dashboard__sidebar">
        <div className="sidebar__profile">
          <div className="profile__avatar text-center">
            <AbsPos className="mb-4">
              <Avatar src={this.state.profileImg} name={fullName} round={true} onClick={path === "profile" ? this.showModal : null}/>
              {path === "profile" ? <Icon/> : null}
            </AbsPos>

            <h2 className="h6">{fullName}</h2>
          </div>
        </div>
        <ul className="dashboard__sidebar__nav">
          <li>
            <Link to="/dashboard/">Dashboard</Link>
          </li>
          <li>
            <Link to="/dashboard/registered">Registered Surgeries</Link>
          </li>
          <li>
            <Link to="/dashboard/favorites">Favorites</Link>
          </li>
          <li>
            <Link to="/dashboard/profile">User Profile</Link>
          </li>
        </ul>
        <Modal show={this.state.modalToggle} handleClose={this.hideModal}>
          <h4 className="mb-3">Upload Profile Image</h4>
          <form className="display:flex">
            <input className="flex-grow-1" type="file" name="uploadImg" onChange={this.handleInputChange}/>
            <Button rounded buttonColor="#4670e8"  className="ml-3" typ="submit" onClick={this.handleSubmit}>Save</Button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default UserDashboardSidebar;
