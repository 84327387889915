// Control whether or not a request for user profile should be made on next
// refresh. If user's session has expired, this helps prevent more requests to
// get user's profile which would result in response status 500.
const setShouldGet = (should) => {
    localStorage.setItem('_st-should-get', should);
}

const removeShouldGet = () => {
    localStorage.removeItem('_st-should-get');
}

const getShouldGet = () => {
    return localStorage.getItem('_st-should-get');
}

const setCurrent = (user) => {
    localStorage.setItem('_st-user', JSON.stringify(user));
}

const removeCurrent = () => {
    localStorage.removeItem('_st-user');
}

const getCurrent = () => {
    return JSON.parse(localStorage.getItem('_st-user'));
}

export default {
    setCurrent,
    removeCurrent,
    getCurrent,
    setShouldGet,
    removeShouldGet,
    getShouldGet
}