import React, { Component } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";

import axios from "axios";
import moment from "moment";

import Modal from "../../components/Modal";
import { DashboardSidebar } from './components/DashboardSidebar';
import AdminUserInvitation from "../AdminUserInvitation";
import AdminIndividualInvitation from "../AdminIndividualInvitation";

class BroadcasterEditSurgery extends Component {
  state = {
    description: "",
    durationHr: 0,
    durationMin: 0,
    emailInvitation: "",
    id: "",
    image: "",
    imageList: [],
    join_url: "",
    modalContent: "",
    modalToggle: false,
    startDate: "",
    startTime: "12:00",
    startUrl: "#",
    topic: "",
    userInvitationList: [],
    userList: [],
    youtubeLink: "",
    zoomNum: "",
  };

  componentDidMount = () => {
    this.getSurgeryInfo();
    this.getFollowers();
  };

  getSurgeryInfo = () => {
    axios
      .get(`/api/broadcaster/surgery/${this.props.match.params.id}`)
      .then(async res => {
        const hour = Math.floor(res.data.duration / 60);
        const minute = res.data.duration % 60;
        const imageList = await axios.get("/api/admin/allimages");
        this.setState({
          description: res.data.description,
          durationHr: hour,
          durationMin: minute,
          id: res.data._id,
          image: res.data.image,
          imageList: imageList.data,
          join_url: res.data.join_url,
          startDate: moment(res.data.start_time).format("YYYY-MM-DD"),
          startTime: moment(res.data.start_time).format("HH:mm"),
          startUrl: res.data.start_url,
          topic: res.data.topic,
          youtubeLink: res.data.youtubeLink,
          zoomNum: res.data.id,
          isPublic: res.data.is_public,
        });
      });
  };

  getFollowers = () => {
    axios.get("/api/broadcaster/followers").then(res => {
      this.setState({
        userList: res.data
      });
    });
  };

  showModal = event => {
    switch (event.target.value) {
      case "userInvitationModal":
        this.setState({
          modalContent: event.target.value,
          modalToggle: true
        });
        break;
      case "individualInvitationModal":
        this.setState({
          modalContent: event.target.value,
          modalToggle: true
        });
        break;
      case "update":
        this.updateSurgeryInfo();
        break;
      default:
        break;
    }
  };

  hideModal = () => {
    this.getFollowers();
    this.setState({
      modalContent: "",
      modalToggle: false
    });
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCheckbox = event => {
    let userList = this.state.userList;
    userList.forEach(user => {
      if (user.email === event.target.value) {
        user.checked = event.target.checked;
      }
    });
    this.setState({ userList });
  };

  handleUserSubmit = event => {
    event.preventDefault();

    let selectUsers = [];
    this.state.userList.forEach(item => {
      if (item.checked === true) {
        selectUsers.push(item.email);
      }
    });

    if (selectUsers.length === 0) {
      return;
    }

    const invitationInfo = {
      invitationList: selectUsers,
      surgeryInfo: `https://surgtime.com/surgeries/${this.state.id}`
    };

    axios
      .post("/api/admin/surgery/send-single-invitations", invitationInfo)
      .then(result => {
        if (result.status === 200) {
          this.hideModal();
        }
      });
  };

  handleIndividualSubmit = event => {
    event.preventDefault();
    const invitation = {
      email: this.state.emailInvitation,
      surgeryInfo: `https://surgtime.com/surgeries/${this.state.id}`
    };
    axios
      .post("/api/admin/surgery/send-individual-invitation", invitation)
      .then(result => {
        if (result.status === 200) {
          this.hideModal();
        }
      });
  };

  updateSurgeryInfo = () => {
    let payload = {
      description: this.state.description,
      duration: this.state.durationHr * 60 + parseInt(this.state.durationMin),
      id: this.state.id,
      image: this.state.image,
      startTime: moment(`${this.state.startDate}T${this.state.startTime}:00`).toISOString(),
      topic: this.state.topic,
      youtubeLink: this.state.youtubeLink,
      zoomNum: this.state.zoomNum,
    };
    axios.put(`/api/broadcaster/surgery/${this.state.id}`, payload).then(res => {
      this.setState({
        modalContent: "update",
        modalToggle: true
      });
    });
  };

  selectImage = image => {
    this.setState({ image });
  };

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== "broadcaster") {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      );
    } else {
      let modalContent;
      switch (this.state.modalContent) {
        case "userInvitationModal":
          modalContent = (
            <AdminUserInvitation
              userList={this.state.userList}
              handleCheckbox={this.handleCheckbox}
              handleUserSubmit={this.handleUserSubmit}
            />
          );
          break;
        case "individualInvitationModal":
          modalContent = (
            <AdminIndividualInvitation
              emailInvitation={this.state.emailInvitation}
              handleInputChange={this.handleInputChange}
              handleIndividualSubmit={this.handleIndividualSubmit}
            />
          );
          break;
        case "update":
          modalContent = <div>Surgery details have been updated.</div>;
          break;
        default:
          break;
      }
      return (
        <>
          <div className="dashboard--has-sidebar">
            <DashboardSidebar
              {...this.props}
            />
            <div className="dashboard">
              <main className="dashboard wrapper">
                <section className="section">
                  <header className="header">
                    <h1 className="title h2">Surgery Details</h1>
                  </header>
                  <div className="content">
                    <div className="edit__surgery">
                      <div className="edit__alpha">
                        <div className="edit__title">
                          <h3 className="h5">Title</h3>
                          <input
                            type="text"
                            name="topic"
                            value={this.state.topic}
                            onChange={this.handleInputChange}
                            maxLength="30"
                            placeholder="Max: 30 Characters"
                          />
                        </div>
                        <div className="edit__url">
                          <div className="edit__surgery-url mb-4">
                            <h3 className="h5">SurgTime Url:</h3>
                            <input
                              type="text"
                              value={`https://surgtime.com/surgeries/${
                                this.state.id
                              }`}
                              readOnly
                            />
                          </div>
                          <div className="edit__surgery-url">
                            <h3 className="h5">
                              YouTube Url:{" "}
                              <FaRegQuestionCircle data-tip="Please use Embed url for YouTube Links" />
                            </h3>
                            <ReactTooltip />
                            <input
                              type="text"
                              name="youtubeLink"
                              value={
                                this.state.youtubeLink
                                  ? this.state.youtubeLink
                                  : "Not Currently Available"
                              }
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="edit__title">
                          <h3 className="h5">Current Image:</h3>
                          <select
                            name="image"
                            value={this.state.image}
                            onChange={this.handleInputChange}
                          >
                            <option value="" />
                            {this.state.imageList.map(image => (
                              <option key={image._id} value={image.name}>
                                {image.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="edit-surgery__form">
                          <label htmlFor="form--date">
                            <span>Select Date:</span>
                            <input
                              id="form--date"
                              type="date"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleInputChange}
                            />
                          </label>
                          <label htmlFor="form--time">
                            <span>Select Time:</span>
                            <input
                              id="form--time"
                              type="time"
                              name="startTime"
                              value={this.state.startTime}
                              onChange={this.handleInputChange}
                            />
                          </label>
                          <label htmlFor="form--duration">
                            <span>Duration:</span>
                            <select
                              name="durationHr"
                              value={this.state.durationHr}
                              onChange={this.handleInputChange}
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                            </select>
                            Hr
                            <select
                              name="durationMin"
                              value={this.state.durationMin}
                              onChange={this.handleInputChange}
                            >
                              <option value="0">0</option>
                              <option value="15">15</option>
                              <option value="30">30</option>
                              <option value="45">45</option>
                            </select>
                            Min
                          </label>
                        </div>
                        <div className="edit__description">
                          <h3 className="h5">Description</h3>
                          <textarea
                            className="edit__description-textarea"
                            type="text"
                            placeholder="Description"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <button
                          className="button button--rounded mt-4"
                          onClick={this.showModal}
                          value="update"
                        >
                          Update Changes
                        </button>
                      </div>

                      <div className="edit__beta">
                        <div className="pane">
                          <a
                            href={this.state.startUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button button--rounded text-center py-2 button--block"
                          >
                            Start Surgery
                          </a>
                        </div>

                        <div className="edit__invitations pane">
                          <h3>Send Invitations</h3>
                          <div className="d-flex w-100 mt-4">
                            <button
                              className="button button--rounded"
                              value="userInvitationModal"
                              onClick={this.showModal}
                            >
                              Followers
                            </button>
                          </div>
                          <div className="d-flex w-100 mt-4">
                            <button
                              className="button button--rounded"
                              value="individualInvitationModal"
                              onClick={this.showModal}
                            >
                              Individuals
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
          <Modal show={this.state.modalToggle} handleClose={this.hideModal}>
            {modalContent}
          </Modal>
        </>
      );
    }
  }
}

export default BroadcasterEditSurgery;
