import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from 'react-avatar';

import Util from 'service/util';

export default({user, onViewProfile}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} onClick={() => onViewProfile(user)}>
      <CardContent className={classes.content}>
        <Avatar
          src={Util.getDisplayableAvatar(user.profileImg)}
          name={`${user.firstName} ${user.lastName}`}
          round={true}
          size='72'/>
        <div
          className={classes.info}
          style={{
          margin: '0 0 0 20px'
        }}>
          <Typography
            gutterBottom
            variant="h6"
            component="h6"
            className={classes.infoName}>
            {Util.getFullNameWithTitle(user)}
          </Typography>
          <Typography
            gutterBottom
            variant="p"
            component="p"
            className={classes.infoPosition}>
            {user.position}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    height: '100px',
    marginBottom: '20px',
    textAlign: 'center',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '15px 0 0 15px'
  },
  info: {
    textAlign: 'left'
  },
  infoName: {
    margin: '0',
    lineHeight: '26px'
  },
  infoPosition: {
    color: '#A4A4A4',
    fontSize: '15px'
  }
});