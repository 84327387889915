import React, {useState} from 'react';
import cogoToast from 'cogo-toast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUsers} from '@fortawesome/free-solid-svg-icons';
import {Modal, makeStyles, Paper} from '@material-ui/core';

import SurgeriesAPI from '../../api/surgeries';
import UsersTable from './UsersTable';

export default(props) => {
    const [isModalVisible,
        setIsModalVisible] = useState(false);
    const classes = useStyles();

    const [viewers,
        setViewers] = useState([]);

    const handleShowModal = () => {
        setIsModalVisible(true);

        if (viewers && viewers.length > 0) {
            return;
        } else {
            SurgeriesAPI
                .getRegisteredViewers(props.id)
                .then(res => {
                    const {data, err} = res;

                    if (err) {
                        console.error('Error getting registered viewers: ', err);
                        cogoToast.err('Problem getting registered viewers');
                        return;
                    }
                    setViewers(data.users);
                });
        }
    }

    const handleHideModal = () => {
        setIsModalVisible(false);
    }

    return (
        <React.Fragment>
            <section
                onClick={handleShowModal}
                className={classes.broadcastInfoDetailViewers}>
                <FontAwesomeIcon
                    icon={faUsers}
                    style={{
                    marginRight: '10px'
                }}/>{` ${props.count} viewers registered`}
            </section>

            {(isModalVisible) && <Modal className={classes.modal} open={true} onClose={handleHideModal}>
                <Paper elevation={0}>
                    <UsersTable
                        title="Registered viewers"
                        titleStyle={{
                        padding: '20px 0px 20px 20px',
                        marginBottom: '0',
                        borderBottom: '1px solid #eee',
                        fontWeight: '600',
                        fontSize: '22px'
                    }}
                        type='registered-viewers'
                        data={viewers}
                        email
                        position
                        deletable={false}
                        pageSize={10}/>
                </Paper>
            </Modal>}
        </React.Fragment>
    )

}

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '800px',
        maxWidth: '95%',
        margin: '50px auto 0'
    },
    broadcastInfoDetailViewers: {
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            flex: '1'
        },
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0',
        fontSize: '16px',        
        '&:hover': {
            cursor: 'pointer',
            color: '#17a2b8'
        }
    }
}));