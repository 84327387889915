import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Avatar from "react-avatar";
import UserDashboardSidebar from "../components/UserDashboardSidebar";

export const Favorites = props => {
  const [favorites, setFavorites] = useState([]);
  const getFavorites = () => {
    axios
      .get("/api/user/favorites")
      .then(res => {
        setFavorites(res.data.following);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFavorites();
  }, []);

  return (
    <div className="dashboard--has-sidebar">
      <UserDashboardSidebar {...props} />
      <div className="dashboard">
        <main className="dashboard wrapper">
          <section className="section">
            <header className="header">
              <h1 className="title">Favorites</h1>
            </header>
            <div className="content pane">
              <ul className="users">
                <li className="user users__table-header">
                  <div className="user__img" />
                  <div className="user__name bold">Name</div>
                  <div className="user__groups">
                    Favorites: {favorites.length}
                  </div>
                </li>
                {favorites.map(function(user, i) {
                  return (
                    <li className="user" key={user._id}>
                      <Avatar
                        name={user.firstName + user.lastName}
                        size="40"
                        className="user__img"
                        round={true}
                      />
                      <div className="user__name bold">
                        {user.firstName} {user.lastName}
                      </div>
                      <div className="user__groups">
                        <Link to={`/broadcaster/profile/${user._id}`}>View</Link>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Favorites;
