export const theme = {
	colors: {
		primary: "#0ca3d9",
		dark: "#0B1F34",
		lightDark: "#314053",
		red: "#FF1143"
	}
}
		
// theme.js
export const breakpoints = ['320px', '480px', '640px', '720px', '960px', '1200px', '1600px']

// aliases
breakpoints.sm = breakpoints[0]
breakpoints.smPlus = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.mdPlus = breakpoints[3]
breakpoints.lg = breakpoints[4]
breakpoints.lgPlus = breakpoints[5]
breakpoints.xl = breakpoints[6]

export default {breakpoints,
	 theme}