import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DashboardSidebar } from "./components/DashboardSidebar";
import Avatar from "react-avatar";

class BroadcasterFollowers extends Component {
  state = {
    followersList: []
  };

  componentDidMount = () => {
    this.getFollowers();
  };

  getFollowers = () => {
    axios
      .get("/api/broadcaster/followers")
      .then(res => {
        this.setState({
          followersList: res.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== "broadcaster") {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      );
    } else {
      return (
        <div className="dashboard--has-sidebar">
          <DashboardSidebar {...this.props} />
          <div className="dashboard">
            <main className="dashboard wrapper">
              <section className="section">
                <header className="header">
                  <h1 className="title">Followers</h1>
                </header>
                <div className="content pane">
                  <ul className="users">
                    <li className="user users__table-header">
                      <div className="user__img" />
                      <div className="user__name bold">Name</div>
                      <div className="user__email">Email</div>
                      <div className="user__groups"># of Followers: {this.state.followersList.length}</div>
                    </li>
                    {this.state.followersList.map(function(user, i) {
                      return (
                        <li className="user" key={user._id}>
                          <Avatar
                            name={user.firstName + user.lastName}
                            size="40"
                            className="user__img"
                            round={true}
                          />
                          <div className="user__name bold">
                            {user.firstName} {user.lastName}
                          </div>
                          <div className="user__email">{user.email}</div>
                          <div className="user__groups">
                            <Link to={`/user/profile/${user._id}`}>
                              View
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            </main>
          </div>
        </div>
      );
    }
  }
}

export default BroadcasterFollowers;
