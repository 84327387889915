import axios from 'axios';

/*
    Get all procedure images
*/
const getAll = async(type) => {
    try {
        const response = await axios.get('/api/admin/allimages');

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

export default {
    getAll
}