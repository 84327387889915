import * as React from 'react';
import {Link} from 'react-router-dom';
import {Button, makeStyles} from '@material-ui/core';
import Avatar from 'react-avatar';
import cogoToast from 'cogo-toast';

import RequestPromotionDialog from 'components/dashboard/RequestPromotionDialog';
import Util from 'service/util';
import UsersAPI from 'api/users';

export default({user}) => {
    const [isRequestPromotionVisible,
        setIsRequestPromotionVisible] = React.useState(false);
    const classes = useStyles();

    const handleNew = (e) => {
        if (user.roleStatus === 'inactive') {
            e.preventDefault();

            const {hide} = cogoToast.warn('Your broadcaster account has not been activated yet. You will receive an email w' +
                    'hen it becomes active and you will be able to schedule surgeries.', {
                hideAfter: 0,
                heading: 'Account not active',
                onClick: () => {
                    hide();
                }
            });
        }
    }

    const handleShowPromote = () => {
        setIsRequestPromotionVisible(true);
    }

    const handleHidePromote = () => {
        setIsRequestPromotionVisible(false);
    }

    const handleConfirmPromotion = async () => {
        // TODO: call the API - update role_status to broadcaster_requested and send an
        // email to Admin Admin gets and email which opens the viewer profile page, and
        // on that page he will see a button saying "promote to broadcaster" (look at
        // the role_status and role fields)

        try {
            const res = await UsersAPI.requestPromotion();
            const {data, err} = res;

            if (err || data !== 'ok') {
                throw new Error('Error sending promotion request: ', err);
            }

            handleHidePromote();
            cogoToast.success('Your request has been sent');
        } catch (err) {
            console.error('Error requesting promotion: ', err);
            cogoToast.error('There was a problem with your request');
        }
    }

    return (
        <section className={classes.root}>
            <Link to="/app" className={classes.profile}>
                <Avatar
                    src={Util.getDisplayableAvatar(user.profileImg)}
                    className={classes.profileImg}
                    name={user.firstName + ' ' + user.lastName}
                    round={true}/>
                <h3 className={classes.profileName}>{Util.getFullNameWithTitle(user)}</h3>
            </Link>

            {(user.role === 'broadcaster') && <div className={classes.actionBtnContainer}>
                <Link
                    id="myDashboardScheduleNewButton"
                    to="/app/new-surgery"
                    onClick={handleNew}
                    style={{
                    opacity: user.roleStatus === 'inactive'
                        ? '0.4'
                        : '1'
                }}>
                    <Button variant="contained" color="primary" className={classes.actionBtn}>
                        Schedule a New Surgery
                    </Button>
                </Link>
            </div>}

            {(user.role === 'user') && <div className={classes.actionBtnContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    onClick={handleShowPromote}>
                    I want to broadcast
                </Button>
            </div>}

            <RequestPromotionDialog
                visible={isRequestPromotionVisible}
                onClose={handleHidePromote}
                onConfirm={handleConfirmPromotion}/>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '22px',
        textAlign: 'center',
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            padding: '22px 10px'
        },
        [
            theme
                .breakpoints
                .down('sm')
        ]: {
            display: 'none'
        }
    },
    profile: {
        display: 'block',
        "&:hover": {
            textDecoration: 'none',
            opacity: '0.8'
        },
        "&:focus": {
            outline: 'none'
        }
    },
    profileImg: {
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            height: '40px !important',
            maxWidth: '40px !important'
        }
    },
    profileName: {
        color: '#353d57',
        fontSize: '16px',
        margin: '20px 0 25px 0',
        textAlign: 'center',
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            fontSize: '13px',
            textAlign: 'center',
            wordBreak: 'break-all'
        }
    },
    actionBtnContainer: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    actionBtn: {
        fontSize: '13px',
        textTransform: 'capitalize',
        color: '#fff',
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            fontSize: '10px'
        }
    }
}));