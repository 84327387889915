import React, {useState, useEffect} from 'react';
import {makeStyles, Button, Modal, Paper} from '@material-ui/core';
import cogoToast from 'cogo-toast';

import {validateEmail} from 'service/util';

export default(props) => {
    const emptyForm = {
        firstName: '',
        lastName: '',
        email: ''
    };
    const classes = useStyles();
    const [form,
        setForm] = useState(emptyForm);

    useEffect(() => {
        setForm(emptyForm);
    }, [props.visible]);

    const handleInputChange = (e) => {
        setForm({
            ...form,
            ...{
                [e.target.name]: e.target.value
            }
        });
    }

    const handleSubmit = () => {
        if (anyFieldEmpty(form)) {
            cogoToast.warn('Please enter all information');
            return;
        }

        if (!validateEmail(form.email)) {
            cogoToast.warn('Please enter a valid email');
            return;
        }

        props.onSubmit({
            firstName: form
                .firstName
                .trim(),
            lastName: form
                .lastName
                .trim(),
            email: form
                .email
                .trim()
        });
    }

    return (
        <Modal open={props.visible} onClose={props.onClose} className={classes.modal}>
            <Paper elevation={0} className={classes.modalPaper}>
                <section className={classes.content}>
                    <form className={classes.formContainer}>
                        <h3 className={classes.formTitle}>Add a Broadcaster</h3>
                        <label htmlFor="form--firstName" className={classes.label}>
                            <span className={classes.formLabelText}>First name</span>
                            <input
                                id="form--firstName"
                                className={classes.formInput}
                                type="text"
                                name="firstName"
                                value={form.firstName}
                                onChange={handleInputChange}
                                required/>
                        </label>

                        <label htmlFor="form--lastName" className={classes.label}>
                            <span className={classes.formLabelText}>Last name</span>
                            <input
                                id="form--lastName"
                                className={classes.formInput}
                                type="text"
                                name="lastName"
                                value={form.lastName}
                                onChange={handleInputChange}
                                required/>
                        </label>

                        <label htmlFor="form--email" className={classes.label}>
                            <span className={classes.formLabelText}>Email</span>
                            <input
                                id="form--email"
                                className={classes.formInput}
                                type="text"
                                name="email"
                                value={form.email}
                                onChange={handleInputChange}
                                required/>
                        </label>
                    </form>

                    <section
                        style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.actionBtn}>Add</Button>

                        <span className={classes.dismisser} onClick={props.onClose}>Close</span>
                    </section>
                </section>
            </Paper>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '500px',
        maxWidth: '95%',
        margin: '50px auto 0'
    },
    modalPaper: {
        padding: '20px'
    },
    actionBtn: {
        color: '#fff',
        textTransform: 'none',
        padding: '10px 50px',
        display: 'inline-block',
        margin: '10px auto 0 auto'
    },
    dismisser: {
        display: 'inline-block',
        margin: '10px 0',
        cursor: 'pointer',
        fontSize: '14px',
        color: '#333'
    },
    formTitle: {
        textAlign: 'center',
        fontSize: '22px',
        margin: '10px 0 30px 0'
    },
    label: {
        display: 'flex'
    },
    formLabelText: {
        flex: '1'
    },
    formInput: {
        flex: '2'
    }
}));

const anyFieldEmpty = (fields) => {
    if (!fields.firstName || !fields.firstName.trim() === '' || !fields.lastName || !fields.lastName.trim() === '' || !fields.email || !fields.email.trim() === '') {
        return true;
    }

    return false;
}