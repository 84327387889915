import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from '@material-ui/core';

const PaperComponent = (props) => <Paper {...props}/>

export default(props) => {
    return (
        <Dialog
            open={props.visible}
            onClose={props.onClose}
            PaperComponent={PaperComponent}>
            <DialogTitle>
                Request Permission to Broadcast
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please confirm your request to obtain the permission to broadcast surgeries on
                    SurgTime.
                    <br></br>
                    <br></br>
                    When you confirm, your request will be sent to SurgTime for review.
                    <br></br>
                    <br></br>
                    Once your request is approved you will receive an email.
                </DialogContentText>
            </DialogContent>
            <DialogActions
                style={{
                justifyContent: 'space-between',
                padding: '10px 20px'
            }}>
                <Button onClick={props.onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={props.onConfirm}
                    color="primary"
                    style={{
                    background: '#0ca3d9',
                    color: '#fff',
                    padding: '8px 25px'
                }}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}