import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, Paper, Button, Slider} from '@material-ui/core';
import cogoToast from 'cogo-toast';
import Cropper from 'react-easy-crop';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons'

import UsersAPI from 'api/users';
import getCroppedImg from './../../service/cropImg';

export default(props) => {
    const classes = useStyles();
    const [isProcessing,
        setIsProcessing] = React.useState(false);
    const [isRemoving,
        setIsRemoving] = React.useState(false);
    const [profileImg,
        setProfileImg] = React.useState();
    const [originalImg,
        setOriginalImg] = React.useState();
    const [crop,
        setCrop] = React.useState({x: 0, y: 0})
    const [zoom,
        setZoom] = React.useState(1);
    const [croppedAreaPixels,
        setCroppedAreaPixels] = React.useState(null);

    React.useEffect(() => {
        resetImage();
    }, [props.visible]);

    const resetImage = () => {
        setProfileImg(null);
        setOriginalImg(null);
    }

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleChange = async(e) => {
        if (!e.target.files || e.target.files.length === 0) {
            cogoToast.error('Problem uploading the picture. Please try again.');
            return;
        }

        const file = e.target.files[0];
        const fileSize = Number((file.size / (1024 * 1024)).toFixed(2));

        if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
            cogoToast.error('The file must be either a JPG, JPEG or PNG', {hideAfter: 6});
            return;
        }

        if (fileSize > 2) {
            cogoToast.error(`The size of the file can't be greater than 2 megabytes`, {hideAfter: 6});
            return;
        }

        const imageDataUrl = await readFile(file); // Needs to be converted so cropping can be done
        setOriginalImg(imageDataUrl);
        setProfileImg(imageDataUrl);
        setZoom(1);
        setCrop({x: 0, y: 0});
    }

    const onRemove = async() => {
        setIsRemoving(true);

        try {
            UsersAPI
                .removeImg()
                .then(({data, err}) => {
                    if (err) {
                        cogoToast.error('Problem removing the picture');
                        console.error('Error removing profile picture: ', err);
                        return;
                    }

                    props.onSuccess(data);
                });
        } catch (err) {
            cogoToast.error('Problem removing the picture');
            console.error('Error removing the profile picture: ', err);
        } finally {
            setIsRemoving(false);
        }
    }

    const onSubmit = async() => {
        setIsProcessing(true);

        try {
            const croppedImage = await getCroppedImg(originalImg, croppedAreaPixels);
            const fileImage = new File([croppedImage], `${props.firstName}_${Math.random().toString(36).substring(4)}.jpg`, {type: 'image/jpg'});

            UsersAPI
                .uploadImg(fileImage)
                .then(({data, err}) => {
                    if (err) {
                        cogoToast.error('Problem uploading the picture');
                        console.error('Error uploading profile picture: ', err);
                        return;
                    }

                    props.onSuccess(data);
                });
        } catch (err) {
            cogoToast.error('Problem cropping the picture');
            console.error('Error cropping the profile picture: ', err);
        } finally {
            setIsProcessing(false);
        }
    }

    return (
        <Modal open={props.visible} onClose={props.onHide} className={classes.modal}>
            <Paper elevation={0} className={classes.modalPaper}>
                <FontAwesomeIcon icon={faTimes} onClick={props.onHide} className={classes.modalCloser}/>

                <section className={classes.content}>
                    <h4 className={classes.title}>Choose your profile picture</h4>

                    <input type="file" name="uploadImg" onChange={handleChange}/>
                    <p
                        className={classes.fileSizeNote}
                        style={{
                        marginBottom: '-5px'
                    }}>The picture must be either a JPG, JPEG or PNG.</p>
                    <p className={classes.fileSizeNote}>The maximum allowed size of the picture is 2 megabytes.</p>
                    {/*  */}

                    {profileImg && <React.Fragment>
                        <div className={classes.cropContainer}>
                            <Cropper
                                cropShape="round"
                                image={profileImg}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}/>
                        </div>

                        <p className={classes.controlsInstruction}>You can zoom and pan the picture around to fit the area inside the circle</p>

                        <div
                            className="controls"
                            style={{
                            marginTop: '10px'
                        }}>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}/>
                        </div>
                    </React.Fragment>}

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!profileImg || isProcessing}
                        onClick={onSubmit}
                        className={classes.actionBtn}
                        style={{
                        margin: '20px auto 0',
                        display: 'block'
                    }}>
                        {isProcessing
                            ? 'Saving...'
                            : 'Save'}
                    </Button>

                    <hr></hr>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isRemoving}
                        onClick={onRemove}
                        className={classes.actionBtn}
                        style={{
                        margin: '20px auto 0',
                        display: 'block'
                    }}>
                        {isRemoving
                            ? 'Removing...'
                            : 'Remove my profile picture'}
                    </Button>
                </section>
            </Paper>
        </Modal>
    );
}

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalPaper: {
        width: '460px',
        maxWidth: '95%',
        padding: '30px 30px',
        position: 'relative'
    },
    modalCloser: {
        position: 'absolute',
        top: '9px',
        right: '14px',
        fontSize: '23px',
        color: '#4e4e4e',
        cursor: 'pointer'
    },
    modalTitle: {
        fontSize: '30px'
    },
    content: {
        width: '100%'
    },
    actionBtn: {
        color: '#fff',
        textTransform: 'none',
        padding: '10px 0',
        width: '260px',
        marginTop: '30px',
        fontSize: '13px'
    },
    cropContainer: {
        position: 'relative',
        height: '300px'
    },
    title: {
        marginBottom: '20px',
        fontSize: '22px',
        textAlign: 'center',
        fontWeight: '600'
    },
    fileSizeNote: {
        color: '#404040',
        fontSize: '13px',
        marginTop: '8px',
        textAlign: 'center'
    },
    controlsInstruction: {
        color: '#676767',
        fontSize: '16px',
        margin: '10px 0 -10px 0',
        textAlign: 'center'
    }
});

const readFile = (file) => {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}
