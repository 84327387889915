import React, {useState} from 'react';
import {Grid, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import {faCalendarCheck} from '@fortawesome/free-regular-svg-icons';
import {Link} from 'react-router-dom';
import cogoToast from 'cogo-toast';

import {SurgeriesGrid} from 'pages/app/Surgeries';

const activeBtnStyle = {
  color: '#039EE0',
  background: '#D3E3E9',
  borderRadius: '100px',
  border: 'none',
  outline: 'none'
};

export default(props) => {
  const [selectedButton,
    setSelectedButton] = useState('upcoming')

  const classes = useStyles();

  const handleSwitch = (type) => {
    setSelectedButton(type)
  }

  const handleNewSurgery = (e) => {
    if (props.current_user.roleStatus === 'inactive') {
        e.preventDefault();

        const {hide} = cogoToast.warn('Your broadcaster account has not been activated yet. You will receive an email w' +
                'hen it becomes active and you will be able to schedule surgeries.', {
            hideAfter: 0,
            heading: 'Account not active',
            onClick: () => {
                hide();
            }
        });
    }
}

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} style={{
          paddingTop: '30px'
        }}>
          <Button
            onClick={() => handleSwitch('upcoming')}
            className={classes.menuItemBtn}
            classes={{label: 'tabSidebarButtonLabel'}}
            style={selectedButton === 'upcoming'
            ? activeBtnStyle
            : null}>
            <FontAwesomeIcon icon={faCalendar} className={classes.menuItemIcon}/>
            Upcoming
          </Button>
          <Button
            onClick={() => handleSwitch('previous')}
            className={classes.menuItemBtn}
            classes={{label: 'tabSidebarButtonLabel'}}
            style={selectedButton === 'previous'
            ? activeBtnStyle
            : null}>
            <FontAwesomeIcon icon={faCalendarCheck} className={classes.menuItemIcon}/>
            Previously Aired
          </Button>

          {(props.current_user && props.current_user.role === 'broadcaster' && props.current_user.id === props.user_id)
          ?
            <Link
                to="/app/new-surgery"
                onClick={handleNewSurgery}
                style={{
                opacity: props.current_user.roleStatus === 'inactive'
                    ? '0.4'
                    : '1'
            }}>
              <Button
                onClick={() => handleSwitch('previous')}
                className={classes.menuItemBtnNewSurgery}
                classes={{label: 'tabSidebarButtonLabel'}}>
                Schedule Surgery
              </Button>
            </Link>
            : null
          }
        </Grid>
        <Grid item xs={12} md={10}>
          {selectedButton === "upcoming"
            ? <SurgeriesGrid data={props.surgeries.upcoming} type="upcoming"/>
            : <SurgeriesGrid data={props.surgeries.recent} type="previous"/>}
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  menuItemBtn: {
    color: '#43545B',
    width: '187px',
    height: '48px',
    marginBottom: '5px',
    fontSize: '14px',
    font: 'Roboto',
    borderRadius: '100px',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:focus': {
      color: '#039EE0',
      background: "#D3E3E9",
      borderRadius: '100px',
      border: 'none',
      outline: 'none'
    },
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '14px',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  menuItemBtnNewSurgery: {
    color: '#FFF',
    backgroundColor: '#039DDF',
    width: '187px',
    height: '48px',
    marginTop: '10px',
    marginBottom: '5px',
    fontSize: '14px',
    font: 'Roboto',
    borderRadius: '100px',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#039DDA',
      color: '#FFF'
    },
  },
  menuItemIcon: {
    color: '#43545B',
    marginRight: '7px',
    [
      theme
        .breakpoints
        .down('lg')
    ]: {
      marginRight: '10px'
    },
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      marginRight: '5px'
    }
  }
}))