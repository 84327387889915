import React, {Component} from 'react';
import axios from 'axios';

class PasswordReset extends Component {
  state = {
    password: '',
    passwordConfirm: '',
    message: null,
    meetsRequirements: true
  }

  componentDidMount = () => {
    //console.log(this.props.match.params.token)
    this.getUser();
  }

  getUser = () => {
    axios
      .get(`/api/user/reset_password/${this.props.match.params.token}`)
      .then((res) => {
        if (res.data.error) {
          this.setState({message: res.data.error})
        }
      })
  }

  handleChange = event => {
    const {name, value} = event.target;

    this.setState({[name]: value})
  }

  handleSubmit = event => {
    event.preventDefault();

    const {password, passwordConfirm} = this.state;
    const {token} = this.props.match.params;

    if (password === passwordConfirm && password !== "" && password.length >= 6) {
      axios
        .post('/api/user/reset_password', {token, password})
        .then(res => {
          this.setState({
            meetsRequirements: true,
            message: 'Success! Your password has been changed.'
          }, () => {
            setTimeout(() => {
              this
                .props
                .history
                .push('/login')
            }, 5000)
          })
        })
    } else {
      this.setState({password: '', passwordConfirm: '', meetsRequirements: false})
    }
  }

  render() {
    if (this.state.message) {
      return (
        <div style={{padding: '80px 0', textAlign: 'center', fontSize: '28px'}}>
          <p>{this.state.message}</p>
        </div>
      )
    } else {
      return (
        <div
          style={{
          display: 'flex',
          background: '#fbfbfb'
        }}>
          <section
            style={{
            background: '#fff',
            border: '1px solid #f5f5f5',
            borderRadius: '5px',
            margin: '150px auto',
            padding: '30px',
            textAlign: 'center',
            width: '420px',
            maxWidth: '95%'
          }}>
            <p>Enter New Password</p>
            <form>
              <input
                type='password'
                placeholder='New Password'
                name="password"
                value={this.state.password}
                onChange={this.handleChange}/>
              <br></br>
              <input
                type='password'
                placeholder='Confirm Password'
                name="passwordConfirm"
                value={this.state.passwordConfirm}
                onChange={this.handleChange}
                style={{
                margin: '20px 0'
              }}/>
              <br></br>
              {!this.state.meetsRequirements
                ? <div style={{
                    color: "red",
                    marginBottom: '20px',
                    fontSize: '15px'
                  }}>Passwords must match and be at least 6 characters long</div>
                : null}
              <button type="submit" onClick={this.handleSubmit} style={{
                padding: '8px 40px',
                background: '#0da3d9',
                color: '#fff',
                fontSize: '18px',
                borderRadius: '20px',
              }}>Save New Password</button>
            </form>
          </section>
        </div>
      )
    }
  }
}

export default PasswordReset;