import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, Paper, Button} from '@material-ui/core';
import cogoToast from 'cogo-toast';

import SubscribersTable from 'components/dashboard/SubscribersTable';
import GroupsAPI from 'api/groups';

export default(props) => {
    const classes = useStyles();

    const [members,
        setMembers] = useState([]);

    const handleUpdateMembers = (selected) => {
        setMembers(selected);
    }

    const handleSubmit = () => {
        if (!members || members.length === 0) {
            cogoToast.warn('No subscribers selected');
            return;
        }

        let memberIDs = [];
        members.forEach(m => {
            memberIDs.push(m._id);
        });

        GroupsAPI
            .addMembersToGroup(props.group._id, memberIDs)
            .then(response => {
                const {err} = response;

                if (err) {
                    cogoToast.error('Problem adding members');
                    console.error('Error adding members: ', err);
                    return;
                }

                cogoToast.success('Members added to group', {hideAfter: 4});
                props.onSubmit();
            })
    }

    const excludedMemberIDs = (props.group.members && props.group.members.length > 0)
        ? props
            .group
            .members
            .map(m => m._id)
        : [];

    return (
        <Modal
            open={props.visible}
            onClose={props.onHide}
            className={classes.addMembersModal}>
            <Paper elevation={0} className={classes.addMembersModalPaper}>
                <section className={classes.content}>
                    <SubscribersTable
                        title="Select subscribers to add as members of the group"
                        noSubsMessage="No subscribers available"
                        exclude={excludedMemberIDs} // to hide existing members
                        selection
                        email                        
                        onUpdateSelection={handleUpdateMembers}
                        tableStyle={{
                        border: '1px solid #eee'
                    }}/>

                </section>

                <section className={classes.btns}>
                    <Button
                        className={classes.btnClose}
                        variant='contained'
                        color='default'
                        size='small'
                        onClick={() => props.onHide()}>
                        Close
                    </Button>
                    <Button
                        disabled={!members || members.length === 0}
                        className={classes.btn}
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={handleSubmit}>
                        Add Members
                    </Button>
                </section>
            </Paper>
        </Modal>
    );
}

const useStyles = makeStyles({
    addMembersModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    addMembersModalPaper: {
        width: '860px',
        maxWidth: '95%',
        padding: '30px 30px'
    },
    addMembersModalModes: {
        float: 'right'
    },
    addMembersModalTitle: {
        fontSize: '30px'
    },
    content: {
        height: '400px',
        width: '100%',
        overflowY: 'auto'
    },
    inviteeEmailInput: {
        fontSize: '16px',
        borderRadius: '2px',
        marginRight: '10px',
        width: '360px',
        '&:focus': {
            outline: 'none',
            borderColor: '#66b1cc'
        }
    },
    inviteeEmailListEmpty: {
        marginTop: '20px',
        color: '#565656',
        fontWeight: '200',
        fontSize: '18px'
    },
    inviteeEmailList: {
        marginTop: '20px',
        paddingLeft: '0'
    },
    inviteeEmailListItem: {
        display: 'inline-flex',
        alignItems: 'center',
        background: '#f7f7f7',
        borderRadius: '50px',
        padding: '10px 70px 10px 30px',
        marginRight: '15px',
        marginBottom: '15px',
        position: 'relative'
    },
    inviteeEmailListItemRemover: {
        float: 'right',
        color: '#FFF',
        background: '#f35627',
        height: '30px',
        width: '30px',
        position: 'absolute',
        right: '25px',
        minWidth: '0',
        padding: '0',
        fontSize: '17px',
        borderRadius: '50%'
    },
    btnAdder: {
        color: '#fff',
        padding: '7px 30px',
        marginTop: '-4px'
    },
    btns: {
        paddingTop: '10px'
    },
    btnClose: {
        background: 'transparent',
        color: '#464646',
        border: '1px solid #8e8e8e',
        textTransform: 'none',
        padding: '5px 30px'
    },
    btn: {
        color: '#fff',
        textTransform: 'none',
        padding: '5px 30px',
        float: 'right'
    }
});