import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {Button} from '@material-ui/core';
import Avatar from 'react-avatar';
import moment from 'moment-timezone';

import Util from 'service/util';
import TimezoneSvc from 'service/timezones';
import SectionTitle from 'components/dashboard/SectionTitle';
import Card from './../SurgeryCard';

import defImg from 'images/cover-photos/default.png';
import rotatorcuff from 'images/cover-photos/surgery-cover-01.png';
import labrum from 'images/cover-photos/surgery-cover-02.png';
import acl from 'images/cover-photos/surgery-cover-03.png';
import meniscus from 'images/cover-photos/surgery-cover-04.png';

const images = {
    defImg,
    labrum,
    acl,
    meniscus,
    rotatorcuff
};

const SurgeriesGrid = ({title, data, type, page}) => {
    const pageSize = page === "user-profile" ? 3 : 6;
    const [index, setIndex] = useState(0);
    const [visibleData, setVisibleData] = useState(null);

    useEffect(() => {
        if(data && Array.isArray(data) && data.length > 0) {
            const d = [...data];
            setVisibleData(d.slice(index, index+pageSize));
        } else {
            setVisibleData([]);
        }
    }, [data]);

    const handleLoadMore = () => {
        let vd = visibleData;
        let i = index+pageSize;
        let novo = data.slice(i, i+pageSize);
        vd = vd.concat(novo);
        setIndex(i);
        setVisibleData(JSON.parse(JSON.stringify(vd)));
    }

    return (
        <div style={{
            marginBottom: '60px',
            paddingBottom: '20px',
        }}>
            <SectionTitle title={title} style={{
                fontSize: '33px',
                marginBottom: '33px'
            }}/>

            {(!visibleData) && <div style={{marginLeft: '10px'}}>Loading surgeries from this user...</div>}

            {(Array.isArray(visibleData) && visibleData.length === 0) && <div style={{marginLeft: '10px'}}>
              This user did not publish any surgeries yet.
            </div>}

            {(Array.isArray(visibleData) && visibleData.length > 0) && <section className="live-surgeries surgeries__section">
                <ul className="upcoming-surgeries__list surgeries__grid__list">
                    {visibleData.map(surgery => {
                        const date = moment.tz(surgery.start_time, surgery.timezone);
                        const dateComponent = date.format("l");
                        const timeComponent = date.format("h:mm A");
                        const tzID = surgery.timezone || 'America/Los_Angeles';
                        return (
                            <li
                                className="upcoming-surgeries__item surgeries__grid__item"
                                key={surgery._id}>
                                <Link to={"/app/surgery/" + surgery._id}>
                                    <Card>
                                        {(type === 'live') && <span className="card__label pulse">LIVE</span>}
                                        <img
                                            src={images[surgery.image] || defImg}
                                            alt={surgery.name}
                                            className="surgery__card__img"/>
                                        <div className="surgery__card__body">
                                            <h2 className="surgery__card__title h4">
                                                {surgery.topic}
                                            </h2>
                                            <div className="surgeon__card__name">
                                                <Avatar
                                                    src={Util.getDisplayableAvatar(surgery.broadcaster.profileImg)}
                                                    name={`${surgery.broadcaster.firstName} ${surgery.broadcaster.lastName}`}
                                                    round
                                                    size="40"
                                                    style={{
                                                    marginRight: '10px'
                                                }}/> {Util.getFullNameWithTitle(surgery.broadcaster)}
                                            </div>
                                            <div className="surgery__card__meta" style={{fontSize: '16px'}}>
                                                <time style={{fontSize: '0.75em'}}>
                                                    {`${dateComponent}, ${timeComponent}, ${TimezoneSvc.getNameByID(tzID)}`}
                                                </time>
                                                <div className="registered-users">
                                                    {/* {surgery
                                                        .registered_users
                                                        .map((user, index) => {
                                                            return (index < 3
                                                                ? (<Avatar
                                                                    key={index}
                                                                    src={Util.getDisplayableAvatar(user.profileImg)}
                                                                    name={`${user.firstName} ${user.lastName}`}
                                                                    size="30"
                                                                    className="registered-users__img"
                                                                    alt=""/>)
                                                                : null)
                                                        })} */}
                                                    <span className="registered-users__registered">
                                                        {`${surgery.registered_users_count} Registered`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Link>
                            </li>
                        );
                    })}
                </ul>

                {(data.length > visibleData.length) &&
                    <div style={{margin: '35px 0', textAlign: 'center'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                color: '#fff',
                                textTransform: 'none'
                            }}
                            onClick={handleLoadMore}>
                            Show More
                        </Button>
                    </div>
                }
            </section>}
        </div>
    );
}

export default withRouter(SurgeriesGrid);