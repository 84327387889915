import React from 'react';
import {withRouter} from 'react-router-dom';

import UsersTable from 'components/dashboard/UsersTable';

const Viewers = ({history}) => {
    const handleEdit = (u) => {
        history.push('/app/v/' + u._id, {user: u})
    }

    return (
        <div>
            <UsersTable
                type="viewers"
                title="Viewers"
                email
                deletable
                onViewClick={handleEdit}
                pageSize={10}/>
        </div>
    )
}

export default withRouter(Viewers);