import * as React from 'react';
import {withRouter} from 'react-router-dom';

export default withRouter((props) => {
    const query = new URLSearchParams('?t=bc');

    return (
        <main
            style={{
            backgroundColor: '#fafafa',
            textAlign: 'center',
            padding: '150px 0 150px 0'
        }}>
            <div
                style={{
                backgroundColor: '#fff',
                border: '1px solid #eee',
                width: '830px',
                margin: '0 auto',
                maxWidth: '95%',
                padding: '60px 60px'
            }}>
                <h1>You have successfully signed up</h1>

                <section style={{
                    marginTop: '30px'
                }}>
                    {query.get('t') === 'bc'
                        ? <p>You may now log in to your account. You will be able to start broadcasting
                                once SurgTime admins review and approve your account.</p>
                        : <p>You may now log in to your account</p>}
                </section>

                <a
                    href="/login"
                    style={{
                    background: '#2ea9e3',
                    borderRadius: '5px',
                    color: '#fff',
                    padding: '12px 50px',
                    marginTop: '10px',
                    display: 'inline-block',
                    fontSize: '22px',
                    textDecoration: 'none'
                }}>Login</a>
            </div>
        </main>
    )
});
