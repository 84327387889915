import * as React from 'react';
import {makeStyles, Button, FormControlLabel, Checkbox} from '@material-ui/core';
import cogoToast from 'cogo-toast';

import {questions} from 'service/feedback';
import FeedbackAPI from 'api/feedback';
import Util from 'service/util';

const answerOptionsList = ['Strongly agree', 'Agree', 'Neither agree nor disagree', 'Disagree', 'Strongly disagree'];

const getAnswerOptions = (selectedOption, mode, onChange) => {
    return (
        <section>
            {answerOptionsList.map((o, k) => (
                <FormControlLabel
                    disabled={mode === 'view'}
                    control={< Checkbox checked = {
                    selectedOption === o
                }
                onChange = {
                    (e) => onChange(o, e.target.checked)
                }
                color = "primary" />}
                    key={k}
                    label={o}/>
            ))}
        </section>
    );
}

export default(props) => {
    const classes = useStyles();
    const ans = (props.mode === 'view')
        ? JSON.parse(props.feedback.answers)
        : [];
    const [answers,
        setAnswers] = React.useState(ans);
    const [isSubmitted,
        setIsSubmitted] = React.useState(false);

    const handleSubmit = async() => {
        try {
            let res = await FeedbackAPI.sendAnswers({
                id: props.surgery._id + '_' + props.user.id + '_' + Math.floor(Math.random() * 100),
                surgery: props.surgery._id,
                user: props.user.id,
                questions: JSON.stringify(questions),
                answers: JSON.stringify(answers)
            });

            if (!res || res.data !== 'ok') {
                throw new Error('Sending feedback failed');
            }

            setIsSubmitted(true);

            setTimeout(() => {
                props.onSubmit();
            }, 1000);
        } catch (err) {
            cogoToast.error('Problem sending feedback. Please try again');
        }
    }

    const renderedQuestions = (props.mode === 'view')
        ? JSON.parse(props.feedback.questions)
        : questions;

    return <div className={classes.root}>
        <h1 className={classes.feedbackModalTitle}>
            {(props.mode === 'view')
                ? `${Util.getFullNameWithTitle(props.feedback.user)}'s feedback for ${props.feedback.surgery.topic}`
                : 'Leave feedback'}
        </h1>

        {!isSubmitted && <div style={{
            textAlign: 'center'
        }}>
            {renderedQuestions.map((q, k) => (
                <div key={k} className={classes.questionWrapper}>
                    <p
                        style={{
                        margin: '0 0 5px 0',
                        fontWeight: '700'
                    }}>{q}</p>
                    <div>
                        {getAnswerOptions(answers[k], props.mode, (option) => {
                            const a = [...answers];
                            a[k] = option;
                            setAnswers(a);
                        })}
                    </div>
                </div>
            ))}
        </div>}

        {isSubmitted && <div className={classes.completeMsg}>
            <h4>Thank you!</h4>
        </div>}

        {(props.mode !== 'view') && <div className={classes.submitWrapper}>
            <Button
                variant="contained"
                color="primary"
                disabled={answers.length !== questions.length}
                className={classes.submitBtn}
                onClick={handleSubmit}>
                Submit
            </Button>
        </div>}
    </div>
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        height: '100%',
        overflow: 'auto'
    },
    feedbackModalTitle: {
        padding: '20px',
        fontSize: '26px',
        textAlign: 'center'
    },
    questionWrapper: {
        marginBottom: '30px',
        borderBottom: '1px solid #eee',
        paddingBottom: '0',
        '&:last-of-type': {
            borderBottom: 'none',
            marginBottom: 0
        }
    },
    submitWrapper: {
        textAlign: 'center',
        width: '100%',
        background: '#fff',
        bottom: '0',
        padding: '20px 0',
        borderTop: '1px solid #eee'
    },
    submitBtn: {
        color: '#fff'
    },
    completeMsg: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));