import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import axios from "axios";
import moment from "moment";


export const PreviousSingleSurgery = (props) => {
  const [surgeryInfo, setSurgeryInfo] = useState(
    {
      broadcaster: {},
      description: "",
      duration: "",
      start_time: Date(),
      topic: "",
      youtubeLink: ""
    }
  );
  const getSurgeryInfo = () => {
    axios
      .get(`/api/user/surgery/${props.match.params.id}`)
      .then(response => {
        setSurgeryInfo(response.data);
      });
  };

  useEffect(() => {
    getSurgeryInfo();
  }, []);

  let profileImage = null;
  if (surgeryInfo.broadcaster.profileImg && surgeryInfo.broadcaster.profileImg.data) {
    const base64 = btoa(String.fromCharCode.apply(null, surgeryInfo.broadcaster.profileImg.data.data));
    profileImage = `data:${surgeryInfo.broadcaster.profileImg.data.contentType};base64, ${base64}`;
  }

  return (
    <>
      <main className="single-surgery--previous">
        <div className="surgery__hero">
          <div className="surgery__hero-info">
            <h1 className="surgery__title">{surgeryInfo.topic}</h1>
            <div className="surgeon surgery__hero-surgeon">
              <div className="surgeon__item">
                <h3 className="suregon__name mb-3">{surgeryInfo.broadcaster.fullName}</h3>
              </div>
            </div>
          </div>
          <div className="surgery__image">
            <div className="videoWrapper">
              <iframe
                title={surgeryInfo.topic}
                src={surgeryInfo.youtubeLink}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <div className="surgery__content">
          <div className="surgery__description">
            {surgeryInfo.description}
          </div>
          <div className="surgery__sidebar">
            <h3 className="h5 mb-4">Aired On {moment(surgeryInfo.start_time).format('MM/DD/YYYY')}</h3>
            <div className="panel">
              <h3>About The Surgeon</h3>
              <div className="surgeon">
                <div className="surgeon__item">
                  <Link to={`/broadcaster/profile/${surgeryInfo.broadcaster.id}`}>
                    <Avatar
                      className="surgeon__image"
                      name={surgeryInfo.broadcaster.fullName}
                      src={profileImage}
                      size="70"
                      alt=""
                    />
                    <h3 className="surgeon__name">{surgeryInfo.broadcaster.fullName}</h3>
                  </Link>
                </div>
              </div>
              <p>{surgeryInfo.broadcaster.bio}</p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
