import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

export default({title, subTitle, style, subTitleStyle}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <h1 className={classes.title} style={{
                ...style
            }}>
                {title}
            </h1>

            {subTitle && <h4 className={classes.subTitle} style={{
                ...subTitleStyle
            }}>
                {subTitle}
            </h4>}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#353d57',
        fontSize: '20px',
        lineHeight: '29px',
        margin: '0 0 20px 0'
    },
    subTitle: {
        color: '#353d57',
        fontSize: '17px',
        lineHeight: '18px',
        margin: '0 0 20px 0'
    }
}));