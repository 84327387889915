import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardSidebar from '../components/DashboardSidebar';
import axios from 'axios';
import moment from 'moment';

class AdminDashboard extends Component {
  state = {
    latestUsers: [],
    latestSurgeries: []
  }

  componentDidMount() {
    this.recentUsers();
  }

  recentUsers = () => {
    axios.get('/api/admin/dashboard/recentusers')
      .then((res) => {
        this.setState({ latestUsers: res.data });
      })
    axios.get(`/api/admin/dashboard/recentsurgeries`)
      .then((res) => {
        this.setState({ latestSurgeries: res.data });
      })
  }

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== 'admin') {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      )
    }
    return (
      <div className='dashboard--has-sidebar'>
        <DashboardSidebar />
        <div className='dashboard'>
          <main className='wrapper'>
            <section className='section'>
              <div className='content'>
                <div className="admin-dashboard__grid">
                  <div className="latest-surgeries registered-surgeries">
                    <h1 className='title mb-4 h3'>Upcoming Surgeries</h1>
                    <div className="list-group">
                      {this.state.latestSurgeries.length ?
                        this.state.latestSurgeries.map(surgery => (
                          <div className="list-group-item list-group-item-action" key={surgery._id}>
                            <Link to={`/surgeries/${surgery._id}`} href="#" >
                              <div className="d-flex w-100 justify-content-between">
                                <h6 className="mb-1">{surgery.topic}</h6>
                                <small>{moment(surgery.start_time).calendar()}</small>
                              </div>
                              <small>{surgery.doctor}</small>
                            </Link>
                            <a style={{ float: 'right'}} className='button button--rounded' href={surgery.start_url} target="_blank" rel="noopener noreferrer">Start Surgery</a>
                          </div>
                        ))
                        :
                        <div className="list-group-item list-group-item-action registered-surgeries--empty">
                          <div className="registered-surgeries--empty__container">
                            <h6 className="mb-1">Currently, There Are No Upcoming Surgeries</h6>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="latest-users">
                    <h1 className='title mb-4 h3'>Latest Users</h1>
                    <div className="list-group">
                      {this.state.latestUsers.map((item, index) => (
                        <Link key={index} to="/" href="#" className="list-group-item list-group-item-action">
                          <div className="d-flex w-100 justify-content-between">
                            <h6 className="mb-1">{item.firstName} {item.lastName}</h6>
                            <small>{moment(item.userCreated).calendar()}</small>
                          </div>
                          <small>{item.email}</small>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    )
  }
}

export default AdminDashboard;