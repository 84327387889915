import React, { Component } from 'react';
import DashboardSidebar from '../components/DashboardSidebar'
import Modal from '../components/Modal';
import { BroadcasterModalContent } from '../components/BroadcasterInfoModalContent';
import Axios from 'axios';

class AdminBroadcasters extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    bio: '',
    broadcasterList: [],
    modalToggle: false,
    modalType: '',
    selectedBroadcaster: {}
  }

  componentDidMount = () => {
    this.getBroadcasters();
  }

  getBroadcasters = () => {
    Axios.get('/api/admin/broadcaster')
      .then(response => {
        this.setState({
          broadcasterList: response.data,
          firstName: '',
          lastName: '',
          email: '',
          modalToggle: false,
          selectedBroadcaster: {}
        })
      })
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    })
  }

  addBroadcaster = () => {
    if (this.state.firstName.trim() !== "") {
      let payload = {
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        email: this.state.email.trim()
      }
      Axios.post('/api/admin/broadcaster', payload)
        .then(response => {
          this.getBroadcasters();
        })
    }
  }

  // Remove: Only Broadcaster themselves can edit.  Admin delete only.
  // editBroadcaster = () => {
  //   if (this.state.doctor.trim() !== "") {
  //     let info = {
  //       _id: this.state.selectedBroadcaster._id,
  //       name: this.state.doctor.trim(),
  //       bio: this.state.bio.trim(),
  //     }
  //     Axios.put('/api/admin/broadcaster', info)
  //       .then(response => {
  //         this.getBroadcasters();
  //       })
  //   }
  // }

  deleteBroadcaster = (id) => {
    Axios.delete(`/api/admin/broadcaster/${id}`)
      .then(response => {
        this.getBroadcasters();
      })
  }

  showModal = (event, broadcaster) => {
    switch (event.target.value) {
      case 'addBroadcaster':
        this.setState({
          modalToggle: true,
          modalType: event.target.value
        })
        break;
      case 'viewBroadcaster':
        this.setState({
          modalToggle: true,
          modalType: event.target.value,
          selectedBroadcaster: broadcaster,
          firstName: broadcaster.firstName,
          lastName: broadcaster.lastName,
          email: broadcaster.email,
          bio: broadcaster.bio,
          dob: broadcaster.dob
        })
        break;
      case 'deleteBroadcaster':
        this.setState({
          modalToggle: true,
          modalType: event.target.value,
          selectedBroadcaster: broadcaster
        })
        break;
      default:
        break;
    }
  }

  hideModal = (event) => {
    this.setState({
      modalToggle: false,
      firstName: '',
      lastName: '',
      email: '',
      selectedBroadcaster: {}
    })
  }

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== 'admin') {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      )
    }

    return (
      <div className='dashboard--has-sidebar'>
        <DashboardSidebar />
        <div className='dashboard'>
          <main className='dashboard wrapper'>
            <section className='section'>
              <header className='header'>
                <h1 className='title'>Broadcasters</h1>
                <button type='button' className="button button--rounded bold" value='addBroadcaster' onClick={this.showModal}>Add Broadcaster</button>
              </header>
              <div className='content pane'>
                <ul className='surgeries__posts'>
                  {this.state.broadcasterList.map(broadcaster => (
                    <li className="surgeries__post space-between" key={broadcaster._id}>
                      <h3 className="surgeries__post__title">{broadcaster.firstName} {broadcaster.lastName} {broadcaster.email}</h3>
                      <button style={{ color: "blue", border: "none", background: "none" }} value="viewBroadcaster" onClick={(event) => this.showModal(event, broadcaster)}>View</button>
                      <button style={{ color: "red", border: "none", background: "none" }} value="deleteBroadcaster" onClick={(event) => this.showModal(event, broadcaster)}>Delete</button>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </main>
        </div>
        <Modal show={this.state.modalToggle} handleClose={this.hideModal}>
          {this.state.modalType === 'deleteBroadcaster' ?
            <div>
              Are you sure you want to delete "{this.state.selectedBroadcaster.name}"?
              <button className='button button--square' onClick={() => this.deleteBroadcaster(this.state.selectedBroadcaster._id)}>Yes</button>
            </div>
            :
            this.state.modalType === 'viewBroadcaster' ?
              <BroadcasterModalContent
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                email={this.state.email}
              />
            :
            <>
              <div>First Name (required):</div>
              <input type='text' name='firstName' value={this.state.firstName} onChange={this.handleChange}></input>
              <div>Last Name:</div>
              <input type='text' name='lastName' value={this.state.lastName} onChange={this.handleChange}></input>
              <div>E-mail:</div>
              <input type='text' name='email' value={this.state.email} onChange={this.handleChange}></input>
              {this.state.selectedBroadcaster.name === undefined ?
                <button className='button button--square' onClick={this.addBroadcaster}>Add Broadcaster</button>
                :
                <button className='button button--square' onClick={this.editBroadcaster}>Edit Broadcaster</button>
              }
            </>
          }
        </Modal>
      </div>
    )
  }
}

export default AdminBroadcasters;