import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import moment from "moment";

import Modal from "../../components/Modal";
import { DashboardSidebar } from "./components/DashboardSidebar";

class BroadcasterPastSurgeries extends Component {
  state = {
    modalToggle: false,
    pastSurgeriesWithVid: [],
    pastSurgeriesNoVid: [],
    surgeryToDelete: ""
  };

  componentDidMount() {
    this.getPastSurgeries();
  }

  showModal = (event, surgery) => {
    switch (event.target.value) {
      case "deletesurgery":
        this.setState({
          modalToggle: true,
          surgeryToDelete: surgery
        });
        break;
      default:
        break;
    }
  };

  hideModal = event => {
    this.setState({
      modalToggle: false
    });
  };

  getPastSurgeries = () => {
    axios.get("/api/broadcaster/surgeries/past").then(res => {
      let noVideo = [];
      let withVideo = [];
      const regex = /^[https://www.youtube.com/embed/]/;
      res.data.forEach(item => {
        if (item.youtubeLink) {
          if (item.youtubeLink.match(regex)) {
            withVideo.unshift(item);
          } else {
            noVideo.unshift(item);
          }
        } else {
          noVideo.unshift(item);
        }
      });
      this.setState({
        pastSurgeriesWithVid: withVideo,
        pastSurgeriesNoVid: noVideo
      });
    });
  };

  deleteSurgery = () => {
    axios
      .delete(`/api/broadcaster/surgery/${this.state.surgeryToDelete._id}`)
      .then(res => {
        this.setState({
          modalToggle: false
        });
        this.getPastSurgeries();
      });
  };

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== "broadcaster") {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      );
    }
    return (
      <>
        <div className="dashboard--has-sidebar">
          <DashboardSidebar
            {...this.props}
          />
          <div className="dashboard">
            <main className="admin__surgeries wrapper">
              <section className="section">
                <header className="header">
                  <h1 className="title">Past Surgeries</h1>
                </header>
                <div className="content pane mb-5">
                  <span className="badge badge-warning mb-3 d-inline-block">
                    Needs Video Embed:
                  </span>
                  <ul className="surgeries__posts">
                    {this.state.pastSurgeriesNoVid.map((surgery, i) => {
                      const date = moment(surgery.start_time);
                      const dateComponent = date
                        .utc()
                        .local()
                        .format("MM-DD-YYYY");
                      const timeComponent = moment
                        .utc(date)
                        .local()
                        .format("hh:mm a");

                      return (
                        <li
                          className="surgeries__post space-between"
                          key={surgery.id}
                        >
                          <h3 className="surgeries__post__title">
                            <Link
                              to={`/broadcaster/surgery/edit/${surgery.id}`}
                            >
                              {surgery.topic}
                            </Link>
                          </h3>
                          <time>
                            {dateComponent} - {timeComponent}
                          </time>
                          <Link to={`/broadcaster/surgery/edit/${surgery.id}`}>
                            Edit
                          </Link>
                          <button
                            style={{
                              color: "red",
                              border: "none",
                              background: "none"
                            }}
                            value="deletesurgery"
                            onClick={event => this.showModal(event, surgery)}
                          >
                            Delete
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="content pane">
                  <span className="badge badge-success mb-3 d-inline-block">
                    Video Embeded:
                  </span>
                  <ul className="surgeries__posts">
                    {this.state.pastSurgeriesWithVid.map((surgery, i) => {
                      const date = moment(surgery.start_time);
                      const dateComponent = date
                        .utc()
                        .local()
                        .format("MM-DD-YYYY");
                      const timeComponent = moment
                        .utc(date)
                        .local()
                        .format("hh:mm a");

                      return (
                        <li
                          className="surgeries__post space-between"
                          key={surgery.id}
                        >
                          <h3 className="surgeries__post__title">
                            <Link to={`/broadcaster/surgery/edit/${surgery.id}`}>
                              {surgery.topic}
                            </Link>
                          </h3>
                          <time>
                            {dateComponent} - {timeComponent}
                          </time>
                          <Link to={`/broadcaster/surgery/edit/${surgery.id}`}>
                            Edit
                          </Link>
                          <button
                            style={{
                              color: "red",
                              border: "none",
                              background: "none"
                            }}
                            value="deletesurgery"
                            onClick={event => this.showModal(event, surgery)}
                          >
                            Delete
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            </main>
          </div>
        </div>
        <Modal show={this.state.modalToggle} handleClose={this.hideModal}>
          <div>
            Are you sure you want to delete "{this.state.surgeryToDelete.topic}
            "?
            <button
              className="button button--square"
              onClick={this.deleteSurgery}
            >
              Yes
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default BroadcasterPastSurgeries;
