import React from 'react';

const AdminAddGroupUser = (props) => {

  return (
    <section className='section'>
      <header className='header'>
        <h1 className='title'>Add User</h1>
      </header>
      <div className='content'>
        <form className="login__form">
          <table>
            <thead>
              <tr>
                <th><input hidden type="checkbox"/></th>
                <th>Name</th> 
                <th>E-mail</th>
              </tr>
            </thead>
            <tbody>
              {props.userList.map((user) => {
                return (
                  <tr key={user._id}>
                    <td><input type="checkbox" checked={props.checked} name={user.email} value={user.email} onChange={(event) => props.handleCheckbox(event, user.email)} /></td>
                    <td>{user.firstName} {user.lastName}</td> 
                    <td>{user.email}</td>
                  </tr>                
                  )
                })}
            </tbody>
          </table>          
          <div className='sign-up__meta'>
          <button 
            className="button button--square" 
            type="submit" 
            name="addUser"
            onClick={props.handleSubmit}
          >
            Add Users
          </button>
          </div>
        </form>
      </div>
    </section>
  )
};

export default AdminAddGroupUser;