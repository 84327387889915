import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import cogoToast from 'cogo-toast';

import SectionTitle from 'components/dashboard/SectionTitle';
import SurgeryForm from 'components/dashboard/SurgeryForm';
import SurgeriesAPI from 'api/surgeries';
import UsersAPI from 'api/users';

const NewSurgery = (props) => {
    const [isSubmitLoading,
        setIsSubmitLoading] = useState(false);
    const classes = useStyles();


    const handleSubmit = async(form) => {
        setIsSubmitLoading(true);
        const {data, err} = await UsersAPI.getZoomToken()

        if (!data.access_token || err) {
            setIsSubmitLoading(false);
            cogoToast.error('There was a problem scheduling the surgery (Zoom API)');
            console.error('Problem creating the surgery: ', err);
            return;
        }

        SurgeriesAPI
            .create({
              ...form,
              bearerToken: data.access_token
            })
            .then(response => {
                setIsSubmitLoading(false);

                if (!response) {
                    cogoToast.error('There was a problem scheduling the surgery');
                    console.error('Problem creating the surgery: no response');
                    return;
                }

                const {data, err} = response;

                if (err) {
                    cogoToast.error('There was a problem scheduling the surgery');
                    console.error('Problem creating the surgery: ', err);
                    return;
                }

                cogoToast.success('New surgery scheduled');

                setTimeout(() => {
                    props
                        .history
                        .push('/app/surgery/' + data.id + '?new=y');
                }, 500);
            }).catch(err => {
                setIsSubmitLoading(false);
                cogoToast.error('There was a problem scheduling the surgery');
                console.error('Problem creating the surgery (2): ', err);
            });
    }

    return (
        <div>
            <SectionTitle title='New surgery'/>
            <p className={classes.instructionText}>Please fill out all fields</p>

            <SurgeryForm mode='create' isLoading={isSubmitLoading} onSubmit={handleSubmit}/>
        </div>
    )
}

export default withRouter(NewSurgery);

const useStyles = makeStyles((theme) => ({
    instructionText: {
        marginTop: '-10px',
        fontSize: '16px',
        marginBottom: '40px'
    }
}));