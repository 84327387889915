import axios from 'axios';
import cogoToast from 'cogo-toast';

const isFieldEmpty = (value) => {
    if (!value || (value && typeof value === 'string' && !value.trim())) {
        return true;
    }

    return false;
}

const getDisplayableAvatar = (profileImg) => {
    let a = null;

    if (profileImg && profileImg.data) {
        const base64 = btoa(String.fromCharCode.apply(null, profileImg.data.data));
        a = `data:${profileImg.contentType};base64, ${base64}`;
    }

    return a;
}

export const getFullNameWithTitle = (user, isFull) => {
    if (!user) {
        return '';
    }

    let n = (!isFull)
        ? `${user.firstName} ${user.lastName}`
        : user.fullName;
    if (!user.title) {
        return n;
    }

    if (user.title === 'Dr.') {
        return user.title + ' ' + n;
    } else {
        return n + ' ' + user.title;
    }
}

const extractIDfromYTUrl = (ytURL) => {
    if (!ytURL) {
        return null;
    }

    // eslint-disable-next-line
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = ytURL.match(regExp);
    if (match && match[2].length === 11) {
        return match[2];
    }

    return null;
}

export default {
    isFieldEmpty,
    getDisplayableAvatar,
    getFullNameWithTitle,
    extractIDfromYTUrl
}

export const setupInterceptors = (onError) => {
    axios
        .interceptors
        .response
        .use(response => {
            return response;
        }, error => {
            if (error.response.status === 401 && window.location.pathname !== '/login' && window.location.pathname !== '/admin') {
                cogoToast.warn('Your session has expired. Pleas login again.', {hideAfter: 4})
                onError();
            }
            return error;
        });
}

export const validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}