import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import UsersAPI from 'api/users';

const Bg = styled.div `
  background-color: #4670e8;
`;

export default(props) => {
  if (props.state && props.state.loggedIn) {
    let urlParams = new URLSearchParams(props.location.search)
    const intention = urlParams.get('lp');

    if (intention) {
      window.location = intention;
      return null;
    }

    window.location = '/app';
    return null;
  }

  const [viewerLogin,
    setViewerLogin] = useState({email: "", password: ""});
  const [isError,
    setIsError] = useState({viewer: false, broadcaster: false});

  const onChangeViewerLogin = event => {
    setViewerLogin({
      ...viewerLogin,
      [event.target.name]: event.target.value
    });
  };

  const onViewerSubmit = event => {
    event.preventDefault();

    UsersAPI
      .login('viewer', viewerLogin)
      .then(response => {
        const {data, err} = response;

        if (err) {
          setIsError({
            ...isError,
            viewer: true
          });
        } else {
          props.onLogin(data);
        }
      });
  };

  return (
    <Bg>
      <div className="dashboard">
        <main className="login wrapper">
          <section className="section">
            <header className="header">
              <h1 className="title">Login</h1>
            </header>
            <div className="content">
              <form className="login__form">
                <input
                  className="sign-up__input"
                  type="text"
                  placeholder="E-mail"
                  name="email"
                  value={viewerLogin.email}
                  onChange={onChangeViewerLogin}/>
                <input
                  className="sign-up__input"
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={viewerLogin.password}
                  onChange={onChangeViewerLogin}/> {isError.viewer && (
                  <div
                    style={{
                    color: 'red',
                    marginTop: '14px',
                    fontSize: '16px',
                    textAlign: 'center'
                  }}>
                    Your email and password combination does not match
                    <br/>
                    a SurgTime account. Please try again.
                  </div>
                )}
                <div className="sign-up__meta">
                  <button
                    className="button button--square"
                    style={{
                    borderRadius: '5px'
                  }}
                    type="submit"
                    disabled={!viewerLogin.email || !viewerLogin.password}
                    onClick={onViewerSubmit}>
                    Sign In
                  </button>
                  <Link
                    to="forgot_password"
                    className="password-reset__link"
                    style={{
                    marginTop: '15px',
                    fontSize: '18px'
                  }}>
                    Forgot Password?
                  </Link>
                </div>
              </form>
            </div>
          </section>

          <div
            style={{
            width: '500px',
            margin: '20px auto 0 auto',
            textAlign: 'center'
          }}>
            <Link to={getSignupLink(props.location.search)} className="signup-link">
              Don't have an account?
            </Link>
          </div>
        </main>
      </div>
    </Bg>
  );
};


const getSignupLink = (search) => {
  let urlParams = new URLSearchParams(search)
  const intention = urlParams.get('lp');
  let r = 'signup'

  if(intention) {
    r += `?lp=${intention}`
  }

  return r;
}