import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {Modal} from '@material-ui/core';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlayCircle} from '@fortawesome/free-solid-svg-icons'

import operatingRoom from'../images/operating-room2.jpg';
import surgeryPreview from'../images/watch-surgeries-live.jpg';
import HeroArthroscopy from '../images/hero-surgeons_arthroscopy2.jpg';
import { Button } from '../components/button/button';
import SurgeriesAPI from 'api/surgeries';
import Util from 'service/util';

import defImg from 'images/cover-photos/default.png';
import rotatorcuff from 'images/cover-photos/surgery-cover-01.png';
import labrum from 'images/cover-photos/surgery-cover-02.png';
import acl from 'images/cover-photos/surgery-cover-03.png';
import meniscus from 'images/cover-photos/surgery-cover-04.png';

const images = {
  defImg,
  rotatorcuff,
  labrum,
  acl,
  meniscus
};

const Hero = styled.section`
  background-image:  linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,.8)), url(${HeroArthroscopy});
	background-size: cover;
	background-position: center center;
  min-height: min-content;
  height: calc(100vh - 4.6875rem);

  display: flex;
  align-items: center;
	justify-content: flex-end;
	color: #fff;
`

const HeroContent = styled.div`
		padding: 1rem;
    text-align: center;
    background-color: transparent;
		h1{
			font-weight: 500;
			margin-bottom: 2rem;
			@media (min-width: 800px){
				font-size: 3.5rem;
			}
		}
		@media (min-width: 800px){
			text-align: left;
      padding: 5rem;
      padding-top: 10rem;
      padding-bottom: 5rem;
			max-width: 50rem;
		}
		p{
			@media (min-width: 800px){
        margin-bottom: 2rem;
        max-width: 35rem;
			}
		}
`

const WatchSurgeriesLive = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
  padding: 6rem 3rem;

  @media (max-width: 30em) {
    padding: 2rem 2rem;
  }

	@media (min-width: 720px) {
		flex-direction: row;
		> *{
			width: 42rem;
		}
	}

	.content{
		padding: 2rem 2rem 2rem 0;
		h2{
			margin-bottom: 2rem;
		}
	}
`


const IntoTheOR = styled.div`
	background-image: url(${operatingRoom});
	background-size: cover;
	display: flex;
	padding: 3rem;
	min-height: 100vh;
	align-items: center;
	justify-content: flex-start;
	div{
		border-radius: .5rem;
		background-color: #fff;
    padding: 4rem;
    @media (max-width: 30em) {
      padding: 2rem;
    }
		@media (min-width: 800px){
			width: 36rem;
			padding: 2rem;
		}
		@media (min-width: 1200px){
			padding: 4rem;
		}

		* + * {
			margin-top: 2rem;
		}
  }
  @media (max-width: 30em) {
    text-align: center;
    padding: 0;
    background-image: none;
    border-bottom: 50px solid #f5f5f5;
  }
`

class Homepage extends Component {
  state = {
    promoSurgeries: null,
    isVideoModalVisible: false
  }

  componentDidMount() {
    this.getPromoSurgeries(3);
  }

  getPromoSurgeries = async (limit) => {
    try {
      let r = await SurgeriesAPI.listPromo(limit);

      if(r && r.data && r.data.length === 3) {
        this.setState({promoSurgeries: r.data});
      }
    } catch (err) {
      console.log("Error getting promo surgeries: ", err);
    }
  }

  handleSelectSurgery = (id) => {
    this
    .props
    .history
    .push(`/app/surgery/${id}`);
  }

  render() {
    const promoSurg = this.state.promoSurgeries;

    return (
      <>
        <Hero>
          <HeroContent>
            <h1>Teaching Surgery to a Global Audience</h1>
            <p>
              A real-time, intra-operative, live video platform focused on expanding access to medical education world-wide
              <br></br>
              <div
                className="d-flex justify-content-center justify-content-md-start"
                style={{
                  marginTop: '10px'
                }}
              >
                <Button onClick={() => {
                  this.setState({
                    isVideoModalVisible : true
                  })
                }}
                  style={{
                    marginRight: 10
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    style={{
                    marginRight: 5
                  }}/>
                  Learn More
                </Button>
                {!this.props.state.loggedIn && <Link to="/signup"><Button>Register</Button></Link>}
              </div>
            </p>
          </HeroContent>

          <Modal
            open={this.state.isVideoModalVisible}
            onClose={() => {
              this.setState({isVideoModalVisible : false});
          }}
            style={{
              width: '840px',
              maxWidth: '95%',
              margin: '100px auto 0'
            }}
            >
              <div>
              <span onClick={() => {
                this.setState({isVideoModalVisible : false});
              }}
              style={{
                position: 'absolute',
                top: '-25px',
                bottom: '0',
                right: '0',
                color: '#fff',
                cursor: 'pointer'
              }}>CLOSE VIDEO</span>
              <iframe width="100%" height="450" src="https://www.youtube.com/embed/LI0cWE3j17U?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Modal>
        </Hero>

        <div className="content-container">
          <WatchSurgeriesLive>
              <div className="content">
                <h2>Watch Surgeries Live</h2>
                <p>Whether you’re in medicine, education, or industry YOU will have unprecedented access to operating room procedures through our portal.</p>
              </div>
              <img src={surgeryPreview} alt="Surgery preview" />
          </WatchSurgeriesLive>
        </div>

        <div className="audience-container">
          <div className="div-block-6">
            <div className="border-card">
              <img src="https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddc992a4b38b7115e656e68_Doctors%402x.jpg" srcSet="https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddc992a4b38b7115e656e68_Doctors%402x-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddc992a4b38b7115e656e68_Doctors%402x.jpg 1200w" sizes="(max-width: 479px) 256.484375px, (max-width: 767px) 11vw, (max-width: 991px) 10vw, 16vw" alt="Surgeon smiling" className="image-2" />
              <h5 className="heading">Surgeons<br />&amp; Residents</h5>
              <div className="text-block">Improve techniques through virtual interactions during live broadcasts</div>
              <div className="div-block-5 divider-line"></div>
            </div>
            <div className="border-card"><img src="/images/educators-students.jpeg" sizes="(max-width: 479px) 256.484375px, (max-width: 767px) 11vw, (max-width: 991px) 10vw, 16vw" alt="" className="image-2" />
              <h5 className="heading">Educators &amp;<br />Students</h5>
              <div className="text-block">Witness curricular science and health applied in practice</div>
              <div className="div-block-5 divider-line purple-line"></div>
            </div>
            <div className="border-card">
              <img src="/images/medical-device-reps.jpeg" sizes="(max-width: 479px) 256.484375px, (max-width: 767px) 11vw, (max-width: 991px) 10vw, 16vw" alt="" className="image-2" />
              <h5 className="heading">Medical Device<br />Representatives</h5>
              <div className="text-block">Gain remote access to operating room instruction 24/7</div>
              <div className="div-block-5 divider-line turquoise-line"></div>
            </div>
          </div>
        </div>


				<IntoTheOR>
					<div>
            <h2>We offer a unique, dual opportunity to View or Broadcast</h2>
						<h3>Viewers</h3>
            <p>Observe operating room protocols and interact with telesurgeons in real-time</p>
            <h3>Broadcasters</h3>
            <p>Surgeon broadcasters can host an audience of viewers for teaching and training purposes while they operate</p>

					</div>
				</IntoTheOR>

        {(promoSurg && promoSurg.length > 0) && <div className="promo-surgeries-container">
            <h1 className="title">Popular Broadcasts</h1>

            <div className="promo-surgeries-content">
              {promoSurg.map((s, k) => (
                <div key={k} className="promo-surgery-card" onClick={() => this.handleSelectSurgery(s._id)}>
                  <img
                      src={images[s.image] || defImg}
                      alt="Surgery graphic"/>
                  <section>
                    <h4 style={{marginBottom: '15px'}}>{s.topic}</h4>
                    <Avatar
                        src={Util.getDisplayableAvatar(s.broadcaster.profileImg)}
                        name={`${s.broadcaster.firstName} ${s.broadcaster.lastName}`}
                        round
                        size="40"
                        style={{
                        marginRight: '10px'
                    }}/> {Util.getFullNameWithTitle(s.broadcaster)}
                    {/* {s.description && <p>{s.description.substring(0, 90)}{s.description.length > 87 ? '...' : ''}</p>} */}
                    {/* <p>{(s.registered_users && s.registered_users.length > 0)? `${s.registered_users.length} registered` : ''}</p> */}
                  </section>
                </div>
              ))}
            </div>
            {(this.props.state && this.props.state.loggedIn)? <button onClick={() => {
                    this
                    .props
                    .history
                    .push('app/surgeries');
              }}>Explore more broadcasts</button> : <button onClick={() => {
                    this
                    .props
                    .history
                    .push('signup');
              }}>Register to watch</button>}
        </div>}

        <h2 className="global-welcome">
          We Welcome Global Connections and Contributors!
        </h2>
        <div className="section-4">
          <div className="div-block-10">
            <p className="paragraph">
              One guiding mission at SurgTime is to standardize surgical excellence
              across the globe by expanding and enhancing access to medical education.
            </p>
            <div className="div-block-12">
              <img
                src="/images/small-globe-2.png"
                width={500}
                sizes="(max-width: 479px) 100vw, 80px"
                alt="Planet Earth"
                className="image-3"
              />
              <p className="paragraph-2" style={{marginBottom: 0, fontSize: '1rem', color: '#1a1b1f'}}>
                We wish to improve surgical techniques practiced around the world by
                inviting skilled surgeons to stream their surgeries, developing a
                database of content to empower fellow surgeons with unprecedented
                resources.
              </p>
            </div>
            <p className="paragraph-3" style={{color: '#1a1b1f'}}>
              Interested in our mission to change the global surgical landscape?
              <br />
            </p>
            <a href="/about" className="button-4 w-button learn-more-btn-home" style={{color: '#fff'}}>
              Learn More
            </a>
          </div>

          <div className="div-block-11">
            <img
                src="https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddb653d56a2d68b835e3421_teaching-surgery-worldwide.jpg"
                srcSet="https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddb653d56a2d68b835e3421_teaching-surgery-worldwide-p-500.jpeg 500w, https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddb653d56a2d68b835e3421_teaching-surgery-worldwide-p-800.jpeg 800w, https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddb653d56a2d68b835e3421_teaching-surgery-worldwide-p-1600.jpeg 1600w, https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddb653d56a2d68b835e3421_teaching-surgery-worldwide-p-2000.jpeg 2000w, https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5ddb653d56a2d68b835e3421_teaching-surgery-worldwide.jpg 2209w"
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 83vw, (max-width: 991px) 59vw, 61vw"
                alt="Teaching surgery worldwide"
                className="image-4"
              />
          </div>
        </div>


        <div className="partner-section">
          <section className="partner-section-text">
            <h2>
              We Work With the Best Partners
            </h2>
          </section>
          <section>
            <img src="/images/Stetson_Lee_Final_Logo.png" alt="Stetson Lee" />
            <img src="/images/pristine.png" alt="Pristine Surgical Partner" />
            <img src="/images/Adi_Logo.png" alt="Adi" />
            <img src="/images/Razvan_Logo.png" alt="Razvan" />
          </section>
          <section>
            <img src="/images/SCOI.png" alt="SCOI" />
            <img src="/images/Snyder_Logo1.png" alt="Snyder" style={{
              width: '400px',
              maxWidth: '90%'
            }} />
          </section>
        </div>

        <div className="homepage__testimonials">
          <h2 className="testimonials__title">Testimonials</h2>
          <ul className="testimonials__container">
            <li className="testimonial">
            <p>You can look up a doctor on the app as opposed to navigating a ridiculous website and getting all these doctors not even near you. It’s intuitive, the way that we would order food.</p>

              <div className="media align-items-center">
                <img className="align-self-center mr-2 rounded-circle img-thumbnail" src="https://placekitten.com/50/50" alt="Generic placeholder" />
                <div className="media-body">
                  <h6 className="mt-0 mb-0">Ian Hernandez</h6>
                </div>
              </div>
            </li>
            <li className="testimonial">

              <p>You can look up a doctor on the app as opposed to navigating a ridiculous website and getting all these doctors not even near you. It’s intuitive, the way that we would order food.</p>
              <div className="media align-items-center">
                <img className="align-self-center mr-2 rounded-circle img-thumbnail" src="https://placekitten.com/50/50" alt="Generic placeholder" />
                <div className="media-body">
                  <h6 className="mt-0 mb-0">Ian Hernandez</h6>
                </div>
              </div>
            </li>
            <li className="testimonial">

              <p>You can look up a doctor on the app as opposed to navigating a ridiculous website and getting all these doctors not even near you. It’s intuitive, the way that we would order food.</p>
              <div className="media align-items-center">
                <img className="align-self-center mr-2 rounded-circle img-thumbnail" src="https://placekitten.com/50/50" alt="Generic placeholder" />
                <div className="media-body">
                  <h6 className="mt-0 mb-0">Ian Hernandez</h6>
                </div>
              </div>
            </li>
            <li className="testimonial">
              <p>You can look up a doctor on the app as opposed to navigating a ridiculous website and getting all these doctors not even near you. It’s intuitive, the way that we would order food.</p>
              <div className="media align-items-center">
                <img className="align-self-center mr-2 rounded-circle img-thumbnail" src="https://placekitten.com/50/50" alt="Generic placeholder" />
                <div className="media-body">
                  <h6 className="mt-0 mb-0">Ian Hernandez</h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </>
    )
  }
}

export default withRouter(Homepage);