import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import cogoToast from 'cogo-toast';

import SectionTitle from 'components/dashboard/SectionTitle';
import UserProfileForm from 'components/dashboard/UserProfileForm';
import UsersAPI from 'api/users';

const MyProfile = (props) => {
    const [form,
        setForm] = useState(null);
    const [isSubmitLoading,
        setIsSubmitLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        UsersAPI
            .getUserProfile()
            .then(({data, err}) => {
                if (err) {
                    setForm({});
                    cogoToast.error('Problem getting your profile information');
                    console.error('Error getting profile info: ', err);
                    return;
                }

                setForm(data);
            })
    }, []);

    const handleSubmit = (form) => {
        setIsSubmitLoading(true);

        UsersAPI
            .updateProfile(form)
            .then(response => {
                setIsSubmitLoading(false);

                if (!response) {
                    cogoToast.error('There was a problem updating your profile');
                    console.error('Problem updating profile: no response');
                    return;
                }

                const {err} = response;

                if (err) {
                    const errMsg = (err.data && err.data.Error)? err.data.Error : 'Problem updating your profile';
                    cogoToast.error(errMsg, {hideAfter: 4});
                    console.error('Problem updating profile: ', err);
                    return;
                }

                let f = form;
                f.oldPassword=''; f.newPassword=''; f.confirmPassword='';
                setForm({...f});
                cogoToast.success('Profile updated');
                props.onUpdate();
            });
    }

    const handleProfileImgUpdate = () => {
        props.onUpdate();
    }

    return (
        <div>
            <SectionTitle title='Account Settings'/>
            <p className={classes.instructionText}>You can edit your profile information and password here</p>

            {(!form) && <div>
                <p>Loading profile information...</p>
            </div>}

            {(form && !form.firstName) && <div>
                <p>There was a problem getting your profile information. Please try again.</p>
            </div>}

            {(form && form.firstName) && <UserProfileForm
                mode='edit'
                role={props.user.role}
                form={form}
                isLoading={isSubmitLoading}
                onSubmit={handleSubmit}
                onProfileImgUpdate={handleProfileImgUpdate}/>}
        </div>
    )
}

export default withRouter(MyProfile);

const useStyles = makeStyles((theme) => ({
    instructionText: {
        marginTop: '-10px',
        fontSize: '16px',
        marginBottom: '40px'
    }
}));