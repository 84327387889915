import React, { Component } from 'react';

class About extends Component {

  render () {
    return (
      <div className="product-page">

  <div className="section-6">
    <h1 className="heading-6">
      Ready to Broadcast Surgery?
      <br />
    </h1>
    <p className="paragraph-4">Take a peek at how it works…</p>
  </div>
  <div className="section-8" />
  <div className="section-7">
    <div className="div-block-15">
      <div id="w-node-b2e91b2356f0-670a93ea" className="div-block-20 center-vert">
        <h1 className="heading-8">Create A Surgery</h1>
        <p className="paragraph-6">
          What procedure are you performing and when does it take place? You can
          include case information that your viewers may want to know before
          they watch, such as patient age, mechanism of injury, and prior
          treatment. Set the date and time to make sure your audience is
          available!
        </p>
      </div>
      <div>
        <img
          src="https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddca36e4d7246aad6754a15_Schedule-Surgery.jpg"
          srcSet="https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddca36e4d7246aad6754a15_Schedule-Surgery-p-500.jpeg 500w, https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddca36e4d7246aad6754a15_Schedule-Surgery.jpg 760w"
          sizes="100vw"
          alt="Schedule surgery form"
        />
      </div>
    </div>
  </div>
  <div className="section-7 section-7-2">
    <div className="div-block-15 block-15-2">
      <div>
        <img
          src="https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9d524d72466668752fb4_Send-Invitation%402x.jpg"
          srcSet="https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9d524d72466668752fb4_Send-Invitation%402x-p-500.jpeg 500w, https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9d524d72466668752fb4_Send-Invitation%402x-p-800.jpeg 800w, https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9d524d72466668752fb4_Send-Invitation%402x-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9d524d72466668752fb4_Send-Invitation%402x.jpg 1520w"
          sizes="(max-width: 479px) 74vw, (max-width: 767px) 83vw, (max-width: 991px) 87vw, (max-width: 3454px) 44vw, 1520px"
          alt="Send invitation form"
        />
      </div>
      <div id="w-node-78a06a91d770-670a93ea" className="center-vert">
        <h1 className="heading-8">Invite Individuals Or Groups To View</h1>
        <p className="paragraph-6">
          You may send email invitations to groups, previous viewers, or new individuals who will benefit from watching your operation.
        </p>
      </div>
    </div>
  </div>
  <div className="section-7">
    <div className="div-block-15">
      <div id="w-node-6a435ea43d95-670a93ea" className="center-vert">
        <h1 className="heading-8">Host Your SurgTime Teaching Session</h1>
        <p className="paragraph-6">
          At the scheduled time of the procedure, open your operating room to 
          the invited participants and prepare to engage in a dynamic academic forum!
        </p>
      </div>
      <div>
        <img
          src="https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9a07ab1008b0810a9439_Start-Surgery%402x.jpg"
          srcSet="https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9a07ab1008b0810a9439_Start-Surgery%25402x-p-800.jpeg 800w, https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9a07ab1008b0810a9439_Start-Surgery%25402x-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9a07ab1008b0810a9439_Start-Surgery%402x.jpg 1520w"
          sizes="(max-width: 479px) 73vw, (max-width: 767px) 83vw, (max-width: 991px) 87vw, (max-width: 3454px) 44vw, 1520px"
          alt="Start surgery"
        />
      </div>
    </div>
  </div>
 
  <div className="product-join">
    <div className="div-block-21">
      <h1 className="heading-9">Are You Ready To Change The World?</h1>
      {!this.props.state.loggedIn && <a href="/signup" className="w-button" style={{color: '#fff'}}>
        Register Now
      </a>}
    </div>
  </div>
  
</div>

    )
  }
}

export default About;