import React from 'react';

import {zoneList} from 'service/timezones';

export default(props) => {
    return (
        <select
            name={props.name}
            disabled={props.disabled}
            value={props.selected}
            onChange={props.onChange}
            className={props.className}>
            <option disabled defaultChecked>Select a timezone</option>
            {zoneList.map(z => (
                <option key={z.id} value={z.id}>{z.name}</option>
            ))}
        </select>
    )
}
