import React from 'react';

export default(props) => {
    return (
        <div>
            <div
                style={{
                textAlign: 'center',
                padding: '3em 0'
            }}>
                <h1>Page not found</h1>
            </div>
        </div>
    )
}
