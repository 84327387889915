import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class DashboardSidebar extends Component {
    render() {
        return (
            <div className='dashboard__sidebar'>
                <ul className="dashboard__sidebar__nav">
                <li><NavLink activeClassName='is-active' to="/admin/surgeries">Add/Edit Surgeries</NavLink></li>
                <li><NavLink activeClassName='is-active' to="/admin/pastsurgeries">Past Surgeries</NavLink></li>
                <li><NavLink activeClassName='is-active' to="/admin/users">Users</NavLink></li>
                <li><NavLink activeClassName='is-active' to="/admin/groups">Groups</NavLink></li>
                <li><NavLink activeClassName='is-active' to="/admin/broadcasters">Broadcasters</NavLink></li>
                <li><NavLink activeClassName='is-active' to="/admin/settings">User Profile</NavLink></li>
                </ul>
            </div>
        );
    }
}

export default DashboardSidebar;