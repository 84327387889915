import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Modal from "../../components/Modal";
import BroadcasterCreateMeeting from "./BroadcasterCreateMeeting";
import { DashboardSidebar } from "./components/DashboardSidebar";
import { Button } from "../../components/button/button";

class BroadcasterSurgeries extends Component {
  state = {
    upcomingSurgeries: [],
    modalToggle: false,
    meeting: true,
    surgeryToDelete: ""
  };

  componentDidMount() {
    this.getSurgeries();
  }

  showModal = (event, surgery) => {
    switch (event.target.value) {
      case "addnew":
        this.setState({
          modalToggle: true,
          meeting: true
        });
        break;
      case "deletesurgery":
        this.setState({
          modalToggle: true,
          meeting: false,
          surgeryToDelete: surgery
        });
        break;
      default:
        break;
    }
  };

  hideModal = event => {
    this.getSurgeries();
    this.setState({
      modalToggle: false
    });
  };

  getSurgeries = () => {
    axios.get("/api/broadcaster/surgeries").then(res => {
      this.setState({
        upcomingSurgeries: res.data
      });
    });
  };

  deleteSurgery = () => {
    axios
      .delete(
        `/api/broadcaster/surgery/${this.state.surgeryToDelete._id}`
      )
      .then(res => {
        this.setState({
          modalToggle: false
        });
        this.getSurgeries();
      });
  };

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== "broadcaster") {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      );
    }
    return (
      <>
        <div className="dashboard--has-sidebar">
          <DashboardSidebar
            {...this.props}
          />
          <div className="dashboard">
            <main className="admin__surgeries wrapper">
              <section className="section">
                <header className="header">
                  <h1 className="title">Surgeries</h1>
                  <Button
                    rounded
                    buttonColor="#4670e8"
                    className="ml-3"
                    type="button"
                    value="addnew"
                    onClick={this.showModal}
                  >
                    Add New
                  </Button>
                </header>
                <div className="content pane">
                  <ul className="surgeries__posts">
                    {this.state.upcomingSurgeries.map((surgery, i) => {
                      const date = moment(surgery.start_time);
                      const dateComponent = date
                        .utc()
                        .local()
                        .format("MM-DD-YYYY");
                      const timeComponent = moment
                        .utc(date)
                        .local()
                        .format("hh:mm a");

                      return (
                        <li
                          className="surgeries__post space-between"
                          key={surgery.id}
                        >
                          <h3 className="surgeries__post__title">
                            <Link to={`/broadcaster/surgery/edit/${surgery.id}`}>
                              {surgery.topic}
                            </Link>
                          </h3>
                          <time>
                            {dateComponent} - {timeComponent}
                          </time>
                          <Link to={`/broadcaster/surgery/edit/${surgery.id}`}>
                            Edit
                          </Link>
                          <button
                            style={{
                              color: "red",
                              border: "none",
                              background: "none"
                            }}
                            value="deletesurgery"
                            onClick={event => this.showModal(event, surgery)}
                          >
                            Delete
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            </main>
          </div>
        </div>
        <Modal show={this.state.modalToggle} handleClose={this.hideModal}>
          {this.state.meeting ? (
            <BroadcasterCreateMeeting
              state={this.props.state}
              handleClose={this.hideModal}
              getSurgeries={this.getSurgeries}
            />
          ) : (
            <div>
              Are you sure you want to delete "
              {this.state.surgeryToDelete.topic}"?
              <button
                className="button button--square"
                onClick={this.deleteSurgery}
              >
                Yes
              </button>
            </div>
          )}
        </Modal>
        <Modal
          show={this.state.deleteModalToggle}
          deleteHandleClose={this.deleteHideModal}
        />
      </>
    );
  }
}

export default BroadcasterSurgeries;
