import React from 'react';

export default(props) => {
    return (
        <React.Fragment>
            <option value="Doctor">Doctor</option>
            <option value="Fellow">Fellow</option>
            <option value="Resident">Resident</option>
            <option value="Student">Student</option>
            <option value="Educator">Educator</option>
            <option value="Vendor">Vendor</option>
            <option value="Medical Staff">Medical Staff</option>
            <option value="Other Healthcare Provider">Other Healthcare Provider</option>
        </React.Fragment>
    )
}
