import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import cogoToast from 'cogo-toast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarPlus} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from "react-tooltip";

import renderNavBarItem from 'components/NavBarItems';
import Logo from 'images/surgtime-logo_1.png';
import UserMenuDropdown from 'components/UserMenuDropdown';
import MobileMenu from 'components/MobileMenu';
import UsersAPI from 'api/users';

const _IS_PRICING_PAGE_ENABLED = process.env.REACT_APP_IS_PRICING_PAGE_ENABLED === 'true'
  ? true
  : false;

const UserMenu = ({user, onLogout, onShowTutorial}) => {
  return (
    <React.Fragment>

      {
        (user.role === 'broadcaster' && user.roleStatus === 'active') &&
        <React.Fragment>
          <li className="nav-separator"></li>
          <li className="nav-item-user-icon">
            <ReactTooltip />
            <Link to='/app/new-surgery' style={{color: '#000'}}>
              <FontAwesomeIcon icon={faCalendarPlus} data-tip="Schedule A New Surgery" />
            </Link>
          </li>
        </React.Fragment>
      }
      {/* <li className="nav-item-user-icon">
        <Link to='/app/notifications' className='brand site-navigation__brand'>
          <FontAwesomeIcon icon={faBell} />
        </Link>
      </li> */}
      <li
        className="site-navigation__nav__item nav-item"
        style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '30px'
      }}>
        <UserMenuDropdown
          user={user}
          onShowTutorial={onShowTutorial}
          onLogout={onLogout}/>
      </li>
    </React.Fragment>
  )
};

class NavBar extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  handleShowProfile = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.props.history.push('/app/b/' + this.props.state.id);
  }

  handleLogout = (event) => {
    if (event) {
      event.preventDefault();
    }

    UsersAPI
      .logout()
      .then((response) => {
        if (!response || response.err) {
          cogoToast.error('Problem logging out');
          console.log('Logout error ', response);
          return;
        }

        this
          .props
          .updateUser({loggedIn: false, email: null});

        window.location = '/';
      });
  }

  renderNavList = ({
    role,
    loggedIn,
    firstName,
    lastName,
    profileImg,
    title
  }) => {
    if (!loggedIn) {
      return (
        <React.Fragment>
          {renderNavBarItem('home')}
          {renderNavBarItem('about')}
          {renderNavBarItem('product')}
          {_IS_PRICING_PAGE_ENABLED && renderNavBarItem('pricing')}
          {renderNavBarItem('signup')}
          {renderNavBarItem('login')}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {renderNavBarItem('explore')}
        {renderNavBarItem('profile', this.handleShowProfile)}
        <UserMenu
          user={{
          firstName,
          lastName,
          profileImg,
          title,
          role
        }}
          onShowTutorial={this.props.onShowTutorial}
          onLogout={this.handleLogout}/>
      </React.Fragment>
    );
  }

  render() {
    const {
      loggedIn,
      role,
      firstName,
      lastName,
      profileImg,
      title
    } = this.props.state;

    return (
      <header className='site-navigation'>
        <Link to='/' className='brand site-navigation__brand'>
          <img src={Logo} alt="SurgTime Logo"/>
        </Link>

        <nav className='site-navigation__nav nav'>
          <ul className="site-navigation__nav__list nav-list">
            {this.renderNavList({
              loggedIn,
              role,
              firstName,
              lastName,
              profileImg,
              title
            })}
          </ul>

          <div className="site-navigation__mobile-menu">
            <MobileMenu
              loggedIn={loggedIn}
              user={{
              firstName,
              lastName,
              profileImg,
              role,
              title
            }}
              onLogout={this.handleLogout}/>
          </div>
        </nav>
      </header>
    )
  }
}

export default withRouter(NavBar);