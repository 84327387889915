import styled from 'styled-components';

const Card = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    //border: 1px solid rgba(0,0,0,.125);
    border-radius: .5rem;
    color: inherit;
    transition: .3s transform ease-out;
    will-change: transform;
    .card__label{
        font-size: .6rem;
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: #fff;
        background-color: rgba(255,0,0,0.5);
        padding: .2rem .6rem;
        border-radius: 2rem;
        letter-spacing: 1.5px;
        
    }
    time{
        font-size: .8em;
        color: #666;
        @include mq($from:sm-plus){
            font-size: 1em;
        }
    }
    &:hover{
        transform: scale(1.01) translateY(-2px);
        text-decoration: none;
        box-shadow: 0 15px 10px -10px rgba(0,0,0,0.05), 0 0px 10px 0 rgba(0,0,0,0.03);
        color: #111;
    }

    .surgery__card__body{
        padding: 1rem;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
    }
    
    .surgery__card__img{
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }
    
    .surgery__card__title{
        margin: 0; padding: 0;
        flex-grow: 1;
    }

    .surgeon__card__name{
        margin-top: .5rem;
    }
    
    .surgery__card__meta{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .surgery__card__meta:last-child{
        margin-top: .5rem;
    }
`

export default Card;