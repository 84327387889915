import React, {useState} from 'react';
import {makeStyles, Modal, Button, Paper} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/free-solid-svg-icons'
import YouTube from '@u-wave/react-youtube';

export default(props) => {
    const [isModalVisible,
        setIsModalVisible] = useState(false);
    const classes = useStyles();

    const handleShowModal = () => {
        setIsModalVisible(true);
    }

    const handleHideModal = () => {
        setIsModalVisible(false);
    }

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="primary"
                style={{...props.btnStyle}}
                className={classes.playBtn}
                onClick={handleShowModal}>
                <FontAwesomeIcon
                    icon={faPlay}
                    style={{
                    marginRight: '5px'
                }}/>
                Play Video
            </Button>

            {(isModalVisible) && <Modal
                open={true}
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleHideModal}
                className={classes.videoModal}>
                <Paper elevation={0} className={classes.videoModalPaper}>
                    <YouTube video={props.videoID} autoplay width="100%" height="100%"/>
                    <div
                        style={{
                        textAlign: 'center'
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.videoModalActionBtn}
                            onClick={handleHideModal}>
                            Close
                        </Button>
                    </div>
                </Paper>
            </Modal>}
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    playBtn: {
        color: '#fff',
        textTransform: 'none',
        padding: '5px 25px',
        fontSize: '15px',
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
            padding: '10px 50px',
            marginBottom: '10px',
            width: '100%'
        },
        '&:focus': {
            outline: 'none'
        }  
    },
    videoModal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    videoModalPaper: {
        height: '580px',
        width: '1030px',
        maxHeight: '75%',
        maxWidth: '95%'
    },
    videoModalActionBtn: {
        color: '#fff',
        background: 'transparent',
        boxShadow: 'none',
        border: '1px solid #eee',
        textTransform: 'none',
        padding: '2px 20px',
        '&:hover': {
            background: 'rgba(250, 250, 250, 0.4)'
        }
    }
}));