import React from 'react';
import {withRouter} from 'react-router-dom';

import SurgeriesTable from 'components/dashboard/SurgeriesTable';

const ArchivedSurgeries = ({history}) => {
    const handleEdit = (s) => {
        history.push('/app/surgery/' + s._id, {surgery: s})
    }

    return (
        <div>
            <SurgeriesTable
                userRole="user"
                type="past"
                title="Archived Surgeries"
                playback
                pageSize={10}
                deletable={false}
                onEditClick={handleEdit}/>
        </div>
    )
}

export default withRouter(ArchivedSurgeries);