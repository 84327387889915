import React, { Component } from 'react';
import axios from 'axios';
import Avatar from 'react-avatar';

import Modal from '../components/Modal';
import AdminAddGroup from './AdminAddGroup';
import AdminAddGroupUser from './AdminAddGroupUser';
import DashboardSidebar from '../components/DashboardSidebar';

import {Button, ButtonGhost} from '../components/button/button';

class AdminGroups extends Component {
  state = {
    groups: [],
    newTitle: '',
    modalToggle: false,
    modalContent: '',
    userList: [],
    addUserList: {},
    groupId: '',
    member: '',
    groupDelete: '',
    checked: false
  }

  componentDidMount = () => {
    this.getGroups();
  }

  showModal = (event) => {
    switch (event.target.value) {
      case "addUserModal":
        this.setState({
          groupId: event.target.name,
          modalContent: event.target.value,
          modalToggle: true
        })
        this.getUserList();
        break;
      case "addGroupModal":
        this.setState({
          modalContent: event.target.value,
          modalToggle: true
        });
        break;
      case "deleteMemberModal":
        this.setState({
          modalContent: event.target.value,
          groupId: event.target.id,
          member: event.target.name,
          modalToggle: true
        })
        break;
      case "deleteGroupModal":
        this.setState({
          groupDelete: event.target.name,
          modalToggle: true,
          modalContent: event.target.value
        })
        break;
      default:
        break;
    }
  }

  hideModal = (event) => {
    this.setState({
      modalContent: '',
      modalToggle: false
    });
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCheckbox = (event, name) => {
    let checkedUsers = this.state.addUserList;
    checkedUsers[name] = event.target.checked;
    this.setState({
      addUserList: checkedUsers
    })
  };

  handleSubmit = (event) => {
    event.preventDefault();

    switch (event.target.name) {
      case "addGroup":
        const newTitle = {
          title: this.state.newTitle
        };
        this.createGroup(newTitle);
        break;
      case "addUser":
        let users = this.state.addUserList;
        let addUsers = []
        for (const key in users) {
          if (users[key] === true) {
            addUsers.push(key);
            users[key] = false;
          }
        }
        const groupUser = {
          _id: this.state.groupId,
          members: addUsers,
          reset: users
        }
        this.addUserToGroup(groupUser);
        break;
      case "deleteMember":
        const deleteMemberInfo = {
          _id: this.state.groupId,
          member: this.state.member
        }
        this.deleteMember(deleteMemberInfo)
        break;
      case "deleteGroup":
        const deleteGroupInfo = {
          title: this.state.groupDelete
        };
        this.deleteGroup(deleteGroupInfo)
        break;
      default:
        break;

    }
  }

  getGroups = () => {
    axios.get('/api/admin/groups')
      .then((res) => {
        this.setState({
          groups: res.data
        })
      })
  }

  createGroup = (title) => {
    axios.post('/api/admin/groups/new', title)
      .then((res) => {
        if (res.status === 200) {
          this.getGroups();
          this.setState({
            modalToggle: false,
            newTitle: ''
          })
        } else {
          console.log("error creating group")
        }
      })
  }

  getUserList = () => {
    axios.get('/api/admin/users')
      .then((res) => {
        let addUserList = {}
        res.data.forEach((user) => {
          addUserList[user.email] = false;
        })
        this.setState({
          userList: res.data,
          addUserList
        })
      })
  }

  addUserToGroup = (addUser) => {
    axios.put('/api/admin/groups/user', addUser)
      .then((res) => {
        if (res.status === 200) {
          this.getGroups();
          this.setState({
            modalToggle: false,
            modalContent: ''
          })
        } else {
          console.log("error adding user")
        }
      })
  }

  deleteMember = (deleteMemberInfo) => {

    axios.delete('/api/admin/groups/delete/user', { data: deleteMemberInfo })
      .then((res) => {
        if (res.status === 200) {
          this.getGroups();
          this.setState({
            modalToggle: false,
            modalContent: '',
            groupId: '',
            member: ''
          })
        }
      })
  }

  deleteGroup = (deleteGroupInfo) => {
    axios.delete('/api/admin/groups/delete/group', { data: deleteGroupInfo })
      .then((res) => {
        if (res.status === 200) {
          this.getGroups();
          this.setState({
            groupDelete: '',
            modalToggle: false
          })
        }
      })
  }

  render() {

    if (!this.props.state.loggedIn || this.props.state.role !== 'admin') {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      )
    } else {
      let modalContent;
      switch (this.state.modalContent) {
        case "addGroupModal":
          modalContent = <AdminAddGroup
            handleInputChange={this.handleInputChange}
            handleSubmit={this.handleSubmit}
            state={this.state}
            checked={this.state.checked}
          />
          break;
        case "addUserModal":
          modalContent = <AdminAddGroupUser
            userList={this.state.userList}
            handleCheckbox={this.handleCheckbox}
            handleSubmit={this.handleSubmit}
          />
          break;
        case "deleteMemberModal":
          modalContent = <div>
            Are you sure you want to remove <strong>"{this.state.member}"</strong> from this group?
                            <button className='button button--square' type='button' name="deleteMember" onClick={this.handleSubmit}>Yes</button>
          </div>
          break;
        case "deleteGroupModal":
          modalContent = <div>
            Are you sure you want to remove the <strong>"{this.state.groupDelete}"</strong> group?
                            <button className='button button--square' type='button' name="deleteGroup" onClick={this.handleSubmit}>Yes</button>
          </div>
          break;
        default:
          break;
      }
      return (
        <>
          <div className='dashboard--has-sidebar'>
            <DashboardSidebar />
            <div className='dashboard'>
              <main className='dashboard wrapper'>
                <section className='section'>
                  <header className='header space-between'>
                    <h1 className='title'>Groups</h1>
                    <Button rounded buttonColor="#4670e8" className="ml-3" type='button' value="addGroupModal" onClick={this.showModal}>Create Group</Button>
                  </header>
                  <div className='content pane'>
                    <ul className="groups">
                      {this.state.groups.map((group) => {
                        return (

                          <li className="group" key={group._id}>
                            <header className="header group__header">
                              <h2 className="title">{group.title}</h2>
                              <Button rounded buttonColor="#4670e8" className="ml-3" type='button' name={group._id} value="addUserModal" onClick={this.showModal}>Add User</Button>
                              <ButtonGhost rounded buttonColor="#4670e8" className="ml-3" type='button' value="deleteGroupModal" name={group.title} onClick={this.showModal}>Delete Group</ButtonGhost>
                            </header>
                            <ul className="group__users">
                              {group.members.map((user) => {

                                return (
                                  <li className="group__user" key={user}>
                                    <Avatar email={user} size="30" round={true} />
                                    <div className="user__name bold">{user}</div>
                                    <button className="delete close" name={user} id={group._id} value="deleteMemberModal" onClick={this.showModal}>&times;</button>
                                  </li>
                                )
                              })}
                            </ul>
                          </li>

                        )
                      })}
                    </ul>
                  </div>
                </section>
              </main>
            </div>
          </div>
          <Modal show={this.state.modalToggle} handleClose={this.hideModal} >
            {modalContent}
            {/* <AdminAddGroup
            handleInputChange={this.handleInputChange}
            handleSubmit={this.handleSubmit}
            state={this.state}
          /> */}
          </Modal>
        </>
      )
    }
  }
}

export default AdminGroups;