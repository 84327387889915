import styled from 'styled-components';
import { lighten, darken, desaturate, readableColor } from 'polished';
import {theme} from '../../theme/';


// The Button from the last section without the interpolations
export const ButtonUnstyled = styled.button`
  color: inherit;
  display: inline-block;
  font-size: 1em;
  background-color: transparent;
  border: none;
  padding: 1em 2em;
  line-height: 1;
  transition: all 0.5s ease;
  cursor: pointer;

  &:focus{
    transition: all 0s ease;
  }
    
  ${props => props.buttonSize && `
    font-size: ${props.buttonSize}em;
  `}

  ${props => props.buttonColor && `
    color: ${props.buttonColor};
  `}

  ${props => props.block && `
    width: 100%;
  `}

`;

export const Button = styled(ButtonUnstyled)`
  color: #fff;
  background-color: ${props => props.buttonColor || theme.colors.primary};
/* ${props => props.buttonColor && `color: ${readableColor(props.buttonColor, 'rgba(0,0,0,0.8)', 'rgba(255,255,255,0.9)')}`};  */
  padding: 1em 2em;
  border-radius: 6px;


  ${props => props.rounded ? `border-radius: 999px;` : null }
  
  &:hover{
    background-color: ${lighten(0.2, theme.colors.primary)};
    background-color: ${props => props.buttonColor && lighten(.1, props.buttonColor)};
    /* color: ${props => props.buttonColor && `${readableColor(props.buttonColor, 'rgba(0,0,0,0.5)', '#fff')}`}; */
  }

  &:active{
      background-color: ${props => props.buttonColor && desaturate(0.05, darken(0.06, props.buttonColor))};
  }

  ${props => props.tomato ? `
    color: #fff; background-color: tomato;
    &:hover{
      background-color: ${lighten(0.05, 'tomato')};
    }
    &:active{
      background-color: ${desaturate(0.2, darken(0.1, 'tomato'))};
    }
  ` : null }

    &:disabled{
        background-color: #c0c0c0;
        color: #999;
        cursor: not-allowed;
    }
`

export const ButtonGhost = styled(Button)`
  background-color: transparent;
  color: ${theme.colors.primary};
  box-shadow: inset 0 0 0 3px;
  
  ${props => props.buttonColor ? `color: ${props.buttonColor};` : null }
  ${props => props.tomato ? "color: tomato;" : null }
  
  &:hover{
    background-color: transparent;
    color: ${lighten(.05, theme.colors.primary)};
    ${props => props.buttonColor && `color: ${props.buttonColor};` };
  }
  &:active{
    transition: all 0s ease;
    background-color: transparent;
    ${props => props.buttonColor && `background-color: ${darken(.1, props.buttonColor)};`};
    color: ${lighten(.1, theme.colors.primary)};
    ${props => props.buttonColor && `box-shadow: inset 0 0 0 0; color: ${readableColor(props.buttonColor, 'rgba(0,0,0,0.5)', 'rgba(255,255,255,0.7)')}`};
  }
`;

