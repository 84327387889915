import React, { Component } from 'react';
import styled from 'styled-components';

import UsersAPI from './../api/users';
import Modal from '../components/Modal';
import {Button} from '../components/button/button';

const Section = styled.div`
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  form{
    max-width: 50rem;
    input{
      width: 100%;
      margin-bottom: 2rem;
    }
    label{
      font-weight: bold;
    }
    textarea{
      height: 10rem;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ContactHero = styled.div`
  background-color: #4670e8;
  padding: 2rem;
  padding-top: 10vh;
  color: #fff;
  h1{
    margin-bottom: 0;
  }
`

const FormContainer = styled.div`
  flex-grow: 1;
`

// const ContactSidebar = styled.div`
//   display: none;
//   background-color: #efefef;
//   padding: 2rem;
//   align-self: flex-start;
//   margin-left: 2rem;
//   @media (min-width: 960px){
//     display: block;
//     width: 20rem;
//   }
// `

class ContactUs extends Component {
  state = {
    email: "",
    message: "",
    topic: "",
    modalToggle: false,
    modalContent: ""
  }

  hideModal = () => {
    this.setState({
      modalToggle: false,
      modalContent: ""
    });
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const isFormValid = (!this.state.email.match(/.+@.+\..+/) || this.state.topic === "" || this.state.message === "")? false : true;

    if (!isFormValid) {
      this.setState({
        modalContent: "Please enter a valid email (e.g. name@email.com), topic and message",
        modalToggle: true
      });
      return;
    }

    UsersAPI.contact({
      email: this.state.email,
      message: this.state.message,
      topic: this.state.topic
    }).then(({err}) => {
      if(err) {
        console.log('Contact us error: ', err);
        // TODO: show an alert
        return;
      }

      this.setState({
        email: '',
        message: '',
        topic: '',
        modalContent: 'Email Sent!',
        modalToggle: true
      });
    });
  }

  render() {
    const isFormValid = (!this.state.email.match(/.+@.+\..+/) || this.state.topic === "" || this.state.message === "")? false : true;

    return (
      <Wrapper>
        <ContactHero><h1>Contact Us</h1></ContactHero>
        <Section>
          <FormContainer>
            <p>Interested in dropping us a line? Please use the form below to send us an email and we will respond back promptly.</p>
            <form>
              <label htmlFor="email">
                Your email:
              </label>
              <br />
              <input
                type='text'
                placeholder='name@example.com'
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
              <br />
              <label htmlFor="topic">
                Topic:
              </label>
              <br />
              <input
                type='text'
                placeholder='topic'
                name="topic"
                value={this.state.topic}
                onChange={this.handleChange}
              />
              <br />
              <label htmlFor="message">
                Message:
              </label>
              <textarea
                placeholder='message'
                name="message"
                value={this.state.message}
                onChange={this.handleChange}
                className="mb-4"
              />

              <Button type="submit" disabled={!isFormValid} buttonColor="#4670e8" onClick={this.handleSubmit}>Submit</Button>
            </form>
          </FormContainer>

          {/* <ContactSidebar>
            <h3>This Is A Sidebar</h3>
            <p className="mb-3">You could include seperate contact info or messaging in this space</p>
            <h3>Corporate Office</h3>

            <p>1234 Sesame St.<br />Los Angeles, Ca 90017</p>
          </ContactSidebar> */}
        </Section>

        <Modal show={this.state.modalToggle} handleClose={this.hideModal}>
          <div>{this.state.modalContent}</div>
        </Modal>
      </Wrapper>
    )
  }
}

export default ContactUs;