import moment from "moment";

const getLiveSurgeries = (surgeries) => {
    return surgeries.filter(surgery => {
        const originalDate = new Date();
        const time = new Date(originalDate.getTime());
        const currentTime = new Date(time).toISOString();
        let getOffsetTime = new Date(originalDate.getTime() - surgery.duration * 60 * 1000);
        const offsetTime = new Date(getOffsetTime).toISOString();
        const isLive = (surgery.start_time < currentTime && surgery.start_time >= offsetTime) || surgery.broadcast_status === 200;

        // If surgery ended more than 24 hours ago then stop treating it as live even if it's status is 200
        const nowMoment = moment(new Date());
        const endMoment = moment(surgery.start_time);
        const hoursPassed = nowMoment.diff(endMoment, 'hours');
        return isLive && hoursPassed < 24;
    });
}

const getUpcomingSurgeries = (surgeries) => {
    return surgeries.filter(surgery => {
        const originalDate = new Date();
        const time = new Date(originalDate.getTime());
        const currentTime = new Date(time).toISOString();
        return (!surgery.broadcast_status || surgery.broadcast_status < 300) && surgery.start_time >= currentTime;
    });
}

const getPreviousSurgeries = (surgeries) => {
    let ytRegex = /^[https://www.youtube.com/]/;

    return surgeries.filter(surgery => {
        const originalDate = new Date();
        const getOffsetTime = new Date(originalDate.getTime() - surgery.duration * 60 * 1000);
        const offsetTime = new Date(getOffsetTime).toISOString();

        let hasYoutube = false;

        if (surgery.youtubeLink && surgery.youtubeLink.match(ytRegex)) {
            hasYoutube = true;
        }

        return  hasYoutube && (surgery.broadcast_status >= 300 || surgery.start_time < offsetTime);
    });
}

export default {
    getLiveSurgeries,
    getUpcomingSurgeries,
    getPreviousSurgeries
}