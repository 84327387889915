import React, { Component } from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import axios from "axios";
import moment from "moment";
// components
import Modal from "../components/Modal";
import { Button } from '../components/button/button';
import Message from '../components/Message';

// images
import rotatorcuff from "../images/cover-photos/surgery-cover-01.png";
import labrum from "../images/cover-photos/surgery-cover-02.png";
import acl from "../images/cover-photos/surgery-cover-03.png";
import meniscus from "../images/cover-photos/surgery-cover-04.png";



class SingleSurgery extends Component {
  state = {
    surgeryInfo: "",
    broadcasterInfo: {
      profileImg: {
        data: {}
      }
    },
    regStatus: "",
    joinStatus: false,
    images: {
      labrum,
      acl,
      meniscus,
      rotatorcuff
    },
    isFollowing: false,
    modalToggle: false,
    modalContent: ""
  };

  componentDidMount = () => {
    this.getSurgeryInfo();
    let surgeryTimer = setInterval(() => {
      if (this.state.joinStatus) {
        clearInterval(surgeryTimer);
      }
      if (
        moment(this.state.surgeryInfo.start_time).diff(moment()) < 600000 &&
        !this.state.joinStatus
      ) {
        this.setState({ joinStatus: true });
      }
    }, 1000);
  };

  getSurgeryInfo = () => {
    axios
    .get("/api/user/surgery/" + this.props.match.params.id)
    .then(surgeryInfo => {
      if (
        surgeryInfo.data.registered_users.includes(surgeryInfo.data.user_id)
      ) {
        this.setState({
          surgeryInfo: { ...surgeryInfo.data },
          broadcasterInfo: surgeryInfo.data.broadcaster,
          regStatus: "Registered"
        });
      } else {
        this.setState({
          surgeryInfo: { ...surgeryInfo.data },
          broadcasterInfo: surgeryInfo.data.broadcaster,
          regStatus: "Register"
        });
      }
      if (surgeryInfo.data.broadcaster.followers.includes(surgeryInfo.data.user_id)) {
        this.setState({
          isFollowing: true
        })
      }
    });
  };

  hideModal = event => {
    this.setState({
      modalToggle: false,
      modalContent: ""
    });
  };

  handleSubmit = () => {
    if (this.state.regStatus === "Register") {
      const registerInfo = {
        surgeryInfo: this.state.surgeryInfo,
        userEmail: this.props.state.email
      };
      axios.put("/api/user/surgery/register", registerInfo).then(response => {
        this.setState({
          regStatus: "Registered",
          modalToggle: true
        });
      });
    } else {
      const registerInfo = {
        surgeryInfo: this.state.surgeryInfo,
        userEmail: this.props.state.email
      };
      axios.put("/api/user/surgery/unregister", registerInfo).then(response => {
        this.setState({
          regStatus: "Register",
          modalToggle: true,
          modalContent: "unregister"
        });
      });
    }
  };

  handleFollow = () => {
    axios.post(`/api/broadcaster/follow/${this.state.broadcasterInfo.id}`)
      .then(response => {
        this.setState({
          isFollowing: true
        });
      });
  };

  render() {
    const date = moment(this.state.surgeryInfo.start_time)
    const dateComponent = date.utc().format('MM-DD-YYYY');
    const timeComponent = moment.utc(date).local().format('hh:mm a');
    let profileImage = null;
    if (this.state.broadcasterInfo.profileImg && this.state.broadcasterInfo.profileImg.data) {
      const base64 = btoa(String.fromCharCode.apply(null, this.state.broadcasterInfo.profileImg.data.data));
      profileImage = `data:${this.state.broadcasterInfo.profileImg.data.contentType};base64, ${base64}`;
    }
    return (
      <>
        <main className="single-surgery">
        {!this.state.joinStatus &&
                this.state.regStatus === "Registered" ? (
                  <Message>
                    You're registered! The join button will become activated 10 minutes before the
                    start time
                  </Message>
                ) : null}
          <div className="surgery__hero">
            <div className="surgery__hero-info">
              <h1 className="surgery__title">{this.state.surgeryInfo.topic}</h1>
              <div className="surgeon surgery__hero-surgeon">
                <div className="surgeon__item">
                  <h3 className="suregon__name mb-3">
                    {this.state.surgeryInfo.doctor}
                  </h3>
                </div>
              </div>

              <div className="surgery__register">
                {this.props.state.role === "admin" ?
                  <Button as="a"
                          buttonColor="green"
                          href={this.state.surgeryInfo.start_url}
                          target="_blank"
                          rel="noopener noreferrer"
                  >
                    Start Surgery
                  </Button>
                  :
                  (<>
                    <Button rounded
                            buttonColor="#4670e8"
                            onClick={this.handleSubmit}
                    >
                      {this.state.regStatus}
                    </Button>

                    {this.state.joinStatus && this.state.regStatus === "Registered" ? (
                      <Button as="a"
                              buttonColor="green"
                              href={this.state.surgeryInfo.join_url}
                              target="_blank"
                              rel="noopener noreferrer"
                      >
                        Join Surgery
                      </Button>
                    ) : (
                      <Button rounded
                              disabled
                              buttonColor="green"
                              className="button button--ghost"
                              rel="noopener noreferrer"
                      >
                        Join Surgery
                      </Button>
                    )}
                  </>)
                }
              </div>
            </div>

            <div className="surgery__image">
              <div className="surgery__time">
                {this.state.surgeryInfo.start_time}
              </div>
              <img
                src={this.state.images[this.state.surgeryInfo.image]}
                className="surgery__img"
                alt=""
              />
            </div>
          </div>
          <div className="surgery__content">

            <div className="surgery__description">
              <div className="time__description mb-3"><time>Approximate duration:{" "}
                  {Math.floor(this.state.surgeryInfo.duration / 60)} hr{" "}
                  {this.state.surgeryInfo.duration % 60} min</time></div>
              {this.state.surgeryInfo.description}
            </div>
            <div className="surgery__sidebar">
              <div className="panel">
                <Button
                  rounded
                  buttonColor="#4670e8"
                  disabled={this.state.isFollowing ? true : false}
                  onClick={this.handleFollow}
                >
                  {this.state.isFollowing ? 'Subscribed' : 'Subscribe'}
                </Button>
                <h3>About The Surgeon</h3>
                <div className="surgeon">
                  <div className="surgeon__item">
                    <Link to={`/broadcaster/profile/${this.state.broadcasterInfo.id}`}>
                      <Avatar
                        className="surgeon__image"
                        name={this.state.broadcasterInfo.fullName}
                        src={profileImage}
                        size="70"
                        alt=""
                      />
                      <h3 className="surgeon__name">
                        {this.state.broadcasterInfo.fullName}
                      </h3>
                    </Link>
                  </div>
                </div>
                <p>{this.state.broadcasterInfo.bio}</p>
              </div>
            </div>
          </div>
        </main>
        <Modal show={this.state.modalToggle} handleClose={this.hideModal}>
          {this.state.modalContent === "" ? (
            <div>Thank you for registering.  An e-mail confirmation will be sent.  Please return to this page on {dateComponent} - {timeComponent} to view the surgery.</div>
          ) : (
            <div>You have unregistered.</div>
          )}
        </Modal>
      </>
    );
  }
}

export default SingleSurgery;
