import * as React from 'react';
import {makeStyles, Modal, Button, Paper} from '@material-ui/core';

import SurgeryFeedbackForm from 'components/dashboard/SurgeryFeedbackForm';

export default(props) => {
    const classes = useStyles();

    const handleSubmit = () => {
        props.onHide();
    }

    return (
        <Modal
            open={props.visible}
            disableBackdropClick
            disableEscapeKeyDown
            onClose={props.onHide}
            className={classes.feedbackModal}>
            <Paper elevation={0} className={classes.feedbackModalPaper}>
                <SurgeryFeedbackForm
                    mode={props.mode}
                    feedback={props.feedback}
                    user={props.user}
                    surgery={props.surgery}
                    onSubmit={handleSubmit}/>
                <div className={classes.closeWrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.closeBtn}
                        onClick={props.onHide}>
                        Close
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
}

const useStyles = makeStyles((theme) => ({
    feedbackModal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    feedbackModalPaper: {
        height: '800px',
        width: '90%',
        maxHeight: '80%',
        maxWidth: '95%',
        position: 'relative',
        top: '-50px'
    },
    closeWrapper: {
        width: '100%',
        position: 'absolute',
        bottom: '-60px',
        textAlign: 'center'
    },
    closeBtn: {
        color: '#fff',
        background: 'transparent',
        boxShadow: 'none',
        border: '1px solid #fff',
        textTransform: 'none',
        padding: '2px 20px',
        '&:hover': {
            background: 'rgba(250, 250, 250, 0.4)'
        }
    }
}));