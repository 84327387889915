import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import cogoToast from 'cogo-toast';
import {Grid} from '@material-ui/core';

import UserProfileCard from 'components/dashboard/UserProfileCard';
import SectionTitle from 'components/dashboard/SectionTitle';
import EmptyArea from 'components/dashboard/EmptyArea';
import UsersAPI from 'api/users';

const FollowedBroadcasters = ({history}) => {
    const [users,
        setUsers] = useState(null);

    useEffect(() => {
        UsersAPI
            .getDiscoverableBroadcasters()
            .then(response => {
                const {data, err} = response;

                if (err || !data) {
                    console.error('Error getting broadcasters: ', err);
                    setUsers([]);
                    cogoToast.error('Problem getting broadcasters');
                    return;
                }

                setUsers([...data]);
            })
    }, []);

    const handleView = (u) => {
        history.push('/app/b/' + u.id, {user: u})
    }

    return (
        <div>
            <SectionTitle title="Discover Broadcasters"/> {/*  */}

            {(!users) && <EmptyArea title="Loading broadcasters"/>}

            {(Array.isArray(users) && users.length === 0) && <EmptyArea title="No broadcasters"/>}

            {(Array.isArray(users) && users.length > 0) && <Grid container spacing={3}>
                {users.map((u, k) => (
                    <Grid item xs={12} md={3} key={k}>
                        <UserProfileCard user={u} onViewProfile={handleView}/>
                    </Grid>
                ))}
            </Grid>}
        </div>
    )
}

export default withRouter(FollowedBroadcasters);