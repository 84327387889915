import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';
import DashboardSidebar from '../components/DashboardSidebar';
import Modal from '../components/Modal';
import AdminGroupInvitation from './AdminGroupInvitation';
import AdminUserInvitation from './AdminUserInvitation';
import AdminIndividualInvitation from './AdminIndividualInvitation';
import { FaRegQuestionCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

class AdminEditSurgery extends Component {
  state = {
    join_url: '',
    id: '',
    start_url: '#',
    description: '',
    topic: '',
    zoomNum: '',
    startTime: '12:00',
    startDate: '',
    durationHr: 0,
    durationMin: 0,
    modalToggle: false,
    modalContent: '',
    userList: [],
    userInvitationList: [],
    groups: [],
    youtubeLink: '',
    broadcaster: '',
    image: '',
    broadcasterList: [],
    imageList: [],
    emailInvitation: ''
  };

  componentDidMount = () => {
    this.getInfo();
    this.getUserList();
    this.getGroups();
  };

  getInfo = () => {
    axios.get(`/api/admin/surgeries/${this.props.match.params.id}`).then( async (res) => {
      let hour = Math.floor(res.data.duration / 60);
      let minute = res.data.duration % 60;
      const imageList = await axios.get("/api/admin/allimages");
      this.setState({
        join_url: res.data.join_url,
        start_url: res.data.start_url,
        description: res.data.description,
        topic: res.data.topic,
        id: res.data._id,
        zoomNum: res.data.id,
        startDate: moment(res.data.start_time).format("YYYY-MM-DD"),
        startTime: moment(res.data.start_time).format("HH:mm"),
        durationHr: hour,
        durationMin: minute,
        youtubeLink: res.data.youtubeLink,
        broadcaster: `${res.data.broadcaster.firstName} ${res.data.broadcaster.lastName}`,
        image: res.data.image,
        imageList: imageList.data
      })
    })
  }

  showModal = (event) => {
    switch (event.target.value) {
      case "userInvitationModal":
        this.setState({
          modalContent: event.target.value,
          modalToggle: true
        });
        break;
      case "groupInvitationModal":
        this.setState({
          modalContent: event.target.value,
          modalToggle: true
        });
        break;
      case "individualInvitationModal":
        this.setState({
          modalContent: event.target.value,
          modalToggle: true
        });
        break;
      case "update":
        this.updateDescription();
        break;
      default:
        break;
    }
  };

  hideModal = () => {
    this.getGroups();
    this.getUserList();
    this.setState({
      modalContent: '',
      modalToggle: false
    })
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };

  handleCheckbox = (event) => {
    if (event.target.name === "group") {
      let groups = this.state.groups;
      groups.forEach(item => {
        if (item._id === event.target.value) {
          item.checked = event.target.checked;
        }
      })
      this.setState({ groups });
    }
    else {
      let userList = this.state.userList;
      userList.forEach(user => {
        if (user.email === event.target.value) {
          user.checked = event.target.checked;
        }
      })
      this.setState({ userList });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let selectGroups = [];
    this.state.groups.forEach(item => {
      if (item.checked === true) {
        selectGroups.push(item._id);
      }
    });

    if (selectGroups.length === 0) {
      return
    }

    const invitationInfo = {
      invitationList: selectGroups,
      surgeryInfo: `https://surgtime.com/surgeries/${this.state.id}`
    }

    axios.post('/api/admin/surgery/send-invitation', invitationInfo)
      .then((result) => {
        if (result.status === 200) {
          this.hideModal();
        }
      })
  };

  handleUserSubmit = (event) => {
    event.preventDefault();

    let selectUsers = [];
    this.state.userList.forEach(item => {
      if (item.checked === true) {
        selectUsers.push(item.email);
      }
    });

    if (selectUsers.length === 0) {
      return
    }

    const invitationInfo = {
      invitationList: selectUsers,
      surgeryInfo: `https://surgtime.com/surgeries/${this.state.id}`
    }

    axios.post('/api/admin/surgery/send-single-invitations', invitationInfo)
      .then((result) => {
        if (result.status === 200) {
          this.hideModal();
        }
      })
  }

  handleIndividualSubmit = (event) => {
    event.preventDefault();
    const invitation = {
      email: this.state.emailInvitation,
      surgeryInfo: `https://surgtime.com/surgeries/${this.state.id}`
    };
    axios.post('/api/admin/surgery/send-individual-invitation', invitation)
      .then((result) => {
        if (result.status === 200) {
          this.hideModal();
        }
      })
  }

  getUserList = () => {
    axios.get('/api/admin/users')
    .then((res) => {
        this.setState({
          userList: res.data
        })
      })
  };

  getGroups = () => {
    axios.get('/api/admin/groupsInfo')
      .then((res) => {
        this.setState({
          groups: res.data
        })
      })
  };

  updateDescription = () => {
    let info = {
      id: this.state.id,
      description: this.state.description,
      topic: this.state.topic,
      zoomNum: this.state.zoomNum,
      startTime: moment(`${this.state.startDate}T${this.state.startTime}:00`).toISOString(),
      duration: this.state.durationHr * 60 + parseInt(this.state.durationMin),
      broadcaster: this.state.broadcaster,
      image: this.state.image,
      youtubeLink: this.state.youtubeLink
    }
    axios.put('/api/admin/surgery/update', info)
      .then((res) => {
        this.setState({
          modalContent: "update",
          modalToggle: true
        });
      })
  }

  selectImage = (image) => {
    this.setState({ image });
  }

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== 'admin') {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      );
    } else {
      let modalContent;
      switch (this.state.modalContent) {
        case "groupInvitationModal":
          modalContent = <AdminGroupInvitation
            groups={this.state.groups}
            handleCheckbox={this.handleCheckbox}
            handleSubmit={this.handleSubmit}
          />
          break;
        case "userInvitationModal":
          modalContent = <AdminUserInvitation
            userList={this.state.userList}
            handleCheckbox={this.handleCheckbox}
            handleUserSubmit={this.handleUserSubmit}
          />
          break;
        case "individualInvitationModal":
          modalContent = <AdminIndividualInvitation
            emailInvitation={this.state.emailInvitation}
            handleInputChange={this.handleInputChange}
            handleIndividualSubmit={this.handleIndividualSubmit}
          />
          break;
        case "update":
          modalContent = <div>Surgery details have been updated.</div>
          break;
        default:
          break;
      }
      return (
        <>
          <div className="dashboard--has-sidebar">
            <DashboardSidebar />
            <div className="dashboard">
              <main className="dashboard wrapper">
                <section className="section">
                  <header className="header">
                    <h1 className="title h2">Surgery Details</h1>
                  </header>
                  <div className="content">
                    <div className="edit__surgery">
                      <div className="edit__alpha">
                        <div className="edit__title">
                          <h3 className="h5">Broadcaster:</h3>
                          {this.state.broadcaster}
                        </div>
                          <div className="edit__title">
                            <h3 className="h5">Title</h3>
                            <input
                              type="text"
                              name="topic"
                              value={this.state.topic}
                              onChange={this.handleInputChange}
                              maxLength="30"
                              placeholder="Max: 30 Characters"
                            />
                          </div>
                        <div className="edit__url">
                          <div className="edit__surgery-url mb-4">
                            <h3 className="h5">SurgTime Url:</h3>
                            <input type="text" value={`https://surgtime.com/surgeries/${this.state.id}`} readOnly />
                          </div>
                          <div className="edit__surgery-url">
                            <h3 className="h5">YouTube Url: <FaRegQuestionCircle data-tip="Please use Embed url for YouTube Links" /></h3>
                            <ReactTooltip />
                            <input
                              type="text"
                              name="youtubeLink"
                              value={this.state.youtubeLink ? this.state.youtubeLink : "Not Currently Available"}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="edit__title">
                          <h3 className="h5">Current Image:</h3>
                          <select
                            name="image"
                            value={this.state.image}
                            onChange={this.handleInputChange}
                          >
                          <option value=""></option>
                          {this.state.imageList.map(image => (
                            <option key={image._id} value={image.name}>{image.name}</option>
                          ))}
                          </select>
                        </div>
                        <div className="edit-surgery__form">
                          <label htmlFor="form--date">
                            <span>Select Date:</span>
                            <input id="form--date" type='date' name="startDate" value={this.state.startDate} onChange={this.handleInputChange} />
                          </label>
                          <label htmlFor="form--time">
                            <span>Select Time:</span>
                            <input id="form--time" type='time' name="startTime" value={this.state.startTime} onChange={this.handleInputChange} />
                          </label>
                          <label htmlFor="form--duration">
                            <span>Duration:</span>
                            <select name="durationHr" value={this.state.durationHr} onChange={this.handleInputChange}>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                            </select>
                            Hr
                            <select name="durationMin" value={this.state.durationMin} onChange={this.handleInputChange}>
                              <option value="0">0</option>
                              <option value="15">15</option>
                              <option value="30">30</option>
                              <option value="45">45</option>
                            </select>
                            Min
                          </label>
                        </div>
                        <div className="edit__description">
                          <h3 className="h5">Description</h3>
                          <textarea
                            className="edit__description-textarea"
                            type="text"
                            placeholder="Description"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <button className="button button--rounded mt-4" onClick={this.showModal} value="update" >Update Changes</button>
                      </div>

                      <div className="edit__beta">

                        <div className="pane">
                          <a href={this.state.start_url} target="_blank" rel="noopener noreferrer" className="button button--rounded text-center py-2 button--block">Start Surgery</a>
                        </div>

                        <div className="edit__invitations pane">
                          <h3>Send Invitations</h3>
                          <div className="d-flex w-100 mt-4">
                            <button className="button button--rounded" value="userInvitationModal" onClick={this.showModal}>Users</button>
                          </div>
                          <div className="d-flex w-100 mt-4">
                            <button className="button button--rounded" value="groupInvitationModal" onClick={this.showModal}>Groups</button>
                          </div>
                          <div className="d-flex w-100 mt-4">
                            <button className="button button--rounded" value="individualInvitationModal" onClick={this.showModal}>Individuals</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
          <Modal show={this.state.modalToggle} handleClose={this.hideModal} >
            {modalContent}
          </Modal>
        </>
      );
    }
  }
}

export default AdminEditSurgery;
