import React, {useEffect, useState} from "react";
import {Grid, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import cogoToast from "cogo-toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserFriends, faUsers, faUser} from "@fortawesome/free-solid-svg-icons";

import UsersAPI from "api/users";
import SubscriptionsContent from "./SubscriptionsContent";
import GroupsTable from "components/dashboard/GroupsTable";

const activeBtnStyle = {
  color: '#039EE0',
  background: '#D3E3E9',
  borderRadius: '100px',
  border: 'none',
  outline: 'none'
};

export default(props) => {
  const [selectedButton,
    setSelectedButton] = useState('subscribers');
  const [subscribers,
    setSubscribers] = useState([]);
  const [subscribedTo,
    setSubscribedTo] = useState([]);
  const [contentData,
    setContentData] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const getSubscribers = async() => {
      try {
        const res = await UsersAPI.getUserFollowers(props.user_id);
        const {data, err} = res;
        if (err) {
          throw new Error('Error getting subscribers: ', err);
        }
        setSubscribers(data);
        setContentData(data);
      } catch (err) {
        console.error('Error getting subscribers: ', err);
        cogoToast.error('There was a problem with your request');
        setSubscribers([]);
      }
    }

    const getFollowing = async() => {
      try {
        const res = await UsersAPI.getUserSubscribedTo(props.user_id);
        const {data, err} = res;
        if (err) {
          throw new Error('Error profiles user is subscribed to: ', err);
        }
        setSubscribedTo(data);
      } catch (err) {
        console.error('Error profiles user is subscribed to: ', err);
        cogoToast.error('There was a problem with your request');
        setSubscribedTo([]);
      }
    }

    getSubscribers();
    getFollowing();
  }, []);

  const handleSwitch = (type) => {
    setSelectedButton(type);

    if (type === 'subscribers') {
      setContentData(subscribers);
    } else if (type === 'subscribed_to') {
      setContentData(subscribedTo);
    } else {
      setContentData([]);
    }
  };

  return (
    <div style={{height: '100%'}}>
      <Grid container spacing={2}>
        <Grid item xs={2} style={{
          paddingTop: '30px'
        }}>
          <Button
            onClick={() => handleSwitch('subscribers')}
            className={classes.menuItemBtn}
            classes={{label: 'tabSidebarButtonLabel'}}
            style={selectedButton === 'subscribers'
            ? activeBtnStyle
            : null}>
            <FontAwesomeIcon icon={faUserFriends} className={classes.menuItemIcon}/>
            Subscribers
          </Button>
          <Button
            onClick={() => handleSwitch('subscribed_to')}
            className={classes.menuItemBtn}
            classes={{label: 'tabSidebarButtonLabel'}}
            style={selectedButton === 'subscribed_to'
            ? activeBtnStyle
            : null}>
            <FontAwesomeIcon
              icon={faUser}
              style={{
              marginRight: "5px"
            }}
              className={classes.menuItemIcon}/>
            Subscribed To
          </Button>
          {(props.current_user.id === props.user_id && props.current_user.role === 'broadcaster') ?
          <Button
            onClick={() => handleSwitch('groups')}
            className={classes.menuItemBtn}
            classes={{label: 'tabSidebarButtonLabel'}}
            style={selectedButton === 'groups'
            ? activeBtnStyle
            : null}>
            <FontAwesomeIcon icon={faUsers} className={classes.menuItemIcon}/>
            Groups
          </Button>
          : null
          }
        </Grid>
        <Grid item xs={10}>
          {(selectedButton === 'subscribers' || selectedButton === 'subscribed_to')
            ? <SubscriptionsContent data={contentData}/>
            : <GroupsTable title="Groups" user={props.profile}/>
}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  menuItemBtn: {
    color: '#43545B',
    width: '187px',
    height: '48px',
    marginBottom: '5px',
    fontSize: '14px',
    font: 'Roboto',
    borderRadius: '100px',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:focus': {
      color: '#039EE0',
      background: "#D3E3E9",
      borderRadius: '100px',
      border: 'none',
      outline: 'none'

    },
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '14px',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  menuItemIcon: {
    color: '#43545B',
    marginRight: '7px',
    [
      theme
        .breakpoints
        .down('lg')
    ]: {
      marginRight: '10px'
    },
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      marginRight: '5px'
    }
  }
}))