import * as React from 'react';
import {withRouter} from 'react-router-dom';

import AdminFeedbackTable from 'components/dashboard/AdminFeedbackTable';
import SurgeryFeedbackModal from 'components/dashboard/SurgeryFeedbackModal';

const ArchivedSurgeries = ({history}) => {
    const [selectedFeedback,
        setSelectedFeedback] = React.useState(null);

    const handleViewFeedback = (fb) => {
        setSelectedFeedback(fb);
    }

    const handleHideFeedback = () => {
        setSelectedFeedback(null);
    }

    return (
        <div>
            <AdminFeedbackTable
                title="Broadcasts Feedback"
                pageSize={10}
                deletable={false}
                onSelect={handleViewFeedback}/>

            <SurgeryFeedbackModal
                mode="view"
                visible={selectedFeedback !== null}
                feedback={selectedFeedback}
                onHide={handleHideFeedback}/>
        </div>
    )
}

export default withRouter(ArchivedSurgeries);