import React from "react";
import { Grid } from "@material-ui/core";
import {withRouter} from 'react-router-dom';

import UserProfileCard from "components/dashboard/UserProfileCard";

function SubscriptionsContent({ data, history }) {
  const handleViewUser = (u) => {
    const p = u.role === 'user' ? 'v' : 'b';
    history.push(`/app/${p}/` + u._id, {user: u});
  }

  return (
    <div>
      {data !== [] ? (
        <Grid container spacing={3}>
          {data.map((u, k) => (
            <Grid item xs={12} md={3} key={k}>
              <UserProfileCard user={u} onViewProfile={handleViewUser} />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </div>
  );
}

export default withRouter(SubscriptionsContent);
