import React from 'react';

const AdminGroupInvitation = props => {
  
  return (
    <section className='section'>
      <header className='header'>
        <h1 className='title'>Select group to invite:</h1>
      </header>
      <div className='content'>
        <form className="login__form">
          <table>
            <thead>
              <tr>
                <th><input hidden type="checkbox"/></th>
                <th>Groups</th> 
              </tr>
            </thead>
            <tbody>
              {props.groups.map((group) => {
                return (
                  <tr key={group._id}>
                    <td><input type="checkbox" name="group" value={group._id} onChange={(e) => props.handleCheckbox(e)} /></td>
                    <td>{group.title}</td> 
                  </tr>                
                  )
                })}
            </tbody>
          </table>          
          <div className='sign-up__meta'>
          <button 
            className="button button--square" 
            type="submit" 
            name="addUser"
            onClick={props.handleSubmit}
          >
            Send Invitations
          </button>
          </div>
        </form>
      </div>
    </section>
  )
};

export default AdminGroupInvitation;