import React from 'react';

const AdminAddGroup = (props) => {

  return (
    <section className='section'>
      <header className='header'>
        <h1 className='title'>Create New Group</h1>
      </header>
      <div className='content'>
        <form className="login__form">
          <input
            className="sign-up__input"
            type='text'
            placeholder='Group Title'
            name="newTitle"
            value={props.state.newTitle}
            onChange={props.handleInputChange}
          />
          <div className='sign-up__meta'>
          <button 
            className="button button--square" 
            type="submit"
            name="addGroup"
            onClick={props.handleSubmit}
          >
            Save
          </button>
          </div>
        </form>
      </div>
    </section>
  )
};

export default AdminAddGroup;