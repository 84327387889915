import React, {useState} from 'react';
import Avatar from 'react-avatar';
import {Button, Menu, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome, faUserCircle, faPowerOff} from '@fortawesome/free-solid-svg-icons';

import Util from 'service/util';

export default({user, onShowTutorial, onLogout}) => {
    const classes = useStyles();

    const [anchorEl,
        setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenuItem = (path) => {
        handleClose();

        if (path === 'logout') {
            onLogout();
            return;
        }

        window.location = path;
    }

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.button}
                classes={{
                label: classes.label
            }}>
                <Avatar
                    src={Util.getDisplayableAvatar(user.profileImg)}
                    name={`${user.firstName} ${user.lastName}`}
                    round={true}
                    size='40'
                    style={{
                    marginRight: 10
                }}/> {Util.getFullNameWithTitle(user)}
            </Button>
            <Menu
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleClickMenuItem('/app')}>
                    <FontAwesomeIcon icon={faHome} className={classes.menuItemIcon}/>
                    My Dashboard</MenuItem>
                <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleClickMenuItem('/app/my-profile')}>
                    <FontAwesomeIcon icon={faUserCircle} className={classes.menuItemIcon}/>
                    Account Settings</MenuItem>
                {/* <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                    handleClose();
                    onShowTutorial();
                }}>
                    <FontAwesomeIcon icon={faInfoCircle} className={classes.menuItemIcon}/>
                    Show Tutorial</MenuItem> */}
                <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleClickMenuItem('logout')}>
                    <FontAwesomeIcon icon={faPowerOff} className={classes.menuItemIcon}/>
                    Logout</MenuItem>
            </Menu>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        '&:focus': {
            outline: 'none'
        }
    },
    label: {
        fontSize: '1rem',
        lineHeight: '1rem',
        fontWeight: 700,
        textTransform: 'none',
        color: '#0ca3d9',
        '&:focus,&:active': {
            outline: 'none'
        }
    },
    menuItem: {
        marginBottom: 5
    },
    menuItemIcon: {
        marginRight: 10
    }
}));