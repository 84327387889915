import axios from 'axios';

/*
    Get a group
*/
const get = async(ID) => {
    try {
        const response = await axios.get('/api/admin/group-detail/' + ID);

        if (!response) {
            throw new Error('Error getting a group, no response');
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
    Get groups for admin or broadcaster
*/
const list = async(user) => {
    try {
        const response = await axios.get('/api/admin/groups');

        if (!response) {
            throw new Error('Error getting groups, no response');
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
    Create a group
*/
const create = async(group) => {
    try {
        const response = await axios.post('/api/admin/groups/new', group);

        if (!response) {
            throw new Error('Error creating a group, no response');
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
    Delete a group
*/
const remove = async(ID) => {
    try {
        const response = await axios.delete('/api/admin/groups/delete/groupID/' + ID);

        if (!response) {
            throw new Error('Error deleting a group, no response');
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
    Add members to a group
*/
const addMembersToGroup = async(groupID, memberIDs) => {
    try {
        const response = await axios.post('/api/admin/groups/add-members', {groupID, memberIDs});

        if (!response) {
            throw new Error('Error adding members, no response');
        }

        if(response.status !== 200) {
            throw new Error(response.response.data);
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
    Remove member from a group
*/
const removeMemberFromGroup = async(groupID, memberID) => {
    try {
        const response = await axios.post('/api/admin/groups/remove-member', {groupID, memberID});

        if (!response) {
            throw new Error('Error removing member, no response');
        }

        if(response.status !== 200) {
            throw new Error(response.response.data);
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

export default {
    get,
    list,
    create,
    remove,
    addMembersToGroup,
    removeMemberFromGroup
}