import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from 'react-avatar';
import {Table, TableBody, TableRow, TableCell} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVideo, faUser} from '@fortawesome/free-solid-svg-icons';

import Util from 'service/util';

export default({user, onViewProfile}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Avatar
                    src={Util.getDisplayableAvatar(user.profileImg)}
                    name={`${user.firstName} ${user.lastName}`}
                    round={true}
                    size='130'/>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="h6"
                    style={{
                    marginTop: '10px'
                }}>
                    {Util.getFullNameWithTitle(user)}
                </Typography>

                <Table className={classes.stats}>
                    <TableBody>
                        <TableRow>
                            {(user.surgeries) &&<TableCell align="center">
                                <Typography
                                    variant="body1"
                                    className={classes.additional}
                                    color="primary"
                                    component="p"><FontAwesomeIcon
                                    icon={faVideo}
                                    className={classes.icon}
                                    title="Number of surgeries"/>{(user.surgeries.length)}</Typography>
                            </TableCell>}
                           {(user.followers) &&<TableCell align="center">
                                <Typography
                                    variant="body1"
                                    className={classes.additional}
                                    color="primary"
                                    component="p"><FontAwesomeIcon
                                    icon={faUser}
                                    className={classes.icon}
                                    title="Number of followers"/>{(user.followers.length)}</Typography>
                            </TableCell>}
                        </TableRow>
                    </TableBody>
                </Table>

                <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                    className={classes.bio}>
                    {(user.bio) && (
                        <span>
                            {user
                                .bio
                                .substring(0, 80)}
                            {(user.bio.length > 79) && '...'}
                        </span>
                    )}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    className={classes.actonBtn}
                    color="primary"
                    onClick={() => onViewProfile(user)}>
                    View Profile
                </Button>
            </CardActions>
        </Card>
    );
}

const useStyles = makeStyles({
    root: {
        maxWidth: '100%',
        height: '100%',
        textAlign: 'center',
        position: 'relative',
        paddingBottom: '40px'
    },
    media: {
        height: 140
    },
    bio: {
        fontSize: '16px',
        paddingTop: '10px'
    },
    actonBtn: {
        position: 'absolute',
        bottom: '15px',
        color: '#0da3d9',
        textTransform: 'none',
        border: '1px solid #0da3d9',
        width: '70%',
        left: '15%',
        fontSize: '14px'
    },
    additional: {
        marginTop: '-10px',
        color: '#545454',
        fontSize: '14px'
    },
    icon: {
        marginRight: 10
    },
    stats: {
        marginTop: '10px'
    }
});