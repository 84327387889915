import React, {useState, useEffect} from 'react';
import {makeStyles, Button} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faPlus} from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast';

import UsersTable from 'components/dashboard/UsersTable';
import EmptyArea from 'components/dashboard/EmptyArea';
import AddGroupMembersModal from 'components/dashboard/AddGroupMembersModal';
import GroupsAPI from 'api/groups';
import UsersAPI from 'api/users';

const Group = ({history, match}) => {
    const classes = useStyles();

    const [group,
        setGroup] = useState(null);

    useEffect(() => {
        if(match.params.id === 'subscribersGroup') {
            getSubscribers();
        } else if(match.params.id === 'recentViewersGroup') {
            getRecentViewers();
        }
         else {
            getGroup(match.params.id);
        }
    }, []);

    const getGroup = (ID) => {
        GroupsAPI
            .get(ID)
            .then(response => {
                const {data, err} = response;

                if (err) {
                    setGroup({members: []});
                    console.error('Error getting group: ', err);
                    cogoToast.error('There was a problem getting the group');
                    return;
                }

                setGroup(data);
            });
    }

    const getSubscribers = () => {
        UsersAPI
            .getBroadcasterSubscribers()
            .then(response => {
                const {data, err} = response;

                if (err) {
                    setGroup({members: []});
                    console.error('Error getting your subscribers: ', err);
                    cogoToast.error('Problem getting your subscribers');
                    return;
                }

                setGroup({
                    _id: 'subscribersGroup',
                    title: 'Subscribers',
                    members: data.reverse() //Reverse so newest are first
                });
            });        
    }

    const getRecentViewers = () => {
        UsersAPI
            .getBroadcasterRecentViewers()
            .then(response => {
                const {data, err} = response;

                if (err) {
                    setGroup({members: []});
                    console.error('Error getting recent viewers: ', err);
                    cogoToast.error('Problem getting recent viewers');
                    return;
                }

                setGroup({
                    _id: 'recentViewersGroup',
                    title: 'Recent Viewers',
                    members: data.viewers
                });
            });        
    }

    const handleView = (u) => {
        history.push('/app/v/' + u._id, {user: u});
    }

    const [isAddVisible,
        setIsAddVisible] = useState(false);

    const handleShowAdd = () => {
        setIsAddVisible(true);
    }

    const handleHideAdd = () => {
        setIsAddVisible(false);
    }

    const handleSubmit = () => {
        setIsAddVisible(false);
        getGroup(match.params.id);
    }

    const renderAddBtn = () => {
        return (
            <Button
                variant="contained"
                color="primary"
                className={classes.addBtn}
                onClick={handleShowAdd}>
                <FontAwesomeIcon
                    icon={faPlus}
                    style={{
                    marginRight: '10px'
                }}/>
                Add Members
            </Button>
        )
    }

    return (
        <div>
            {(group && group._id !== 'subscribersGroup' && group._id !== 'recentViewersGroup') && renderAddBtn()}

            <span
                onClick={() => history.go(-1)}
                className="hover_color-blue cursor-pointer"
                style={{
                display: 'inline-block',
                marginBottom: '30px',
                fontSize: '14px'
            }}>
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{
                    marginRight: '10px'
                }}/>
                Go Back</span>

            {(!group) && <EmptyArea title="Loading group details"/>}

            {(group && group.members.length === 0) && <EmptyArea
                title={`${group.title} has no members`}
                subTitle={getSubTitle(group)}/>}

            {(group && group.members.length > 0) && <UsersTable
                type="group_members"
                title={group.title}
                subTitle={group._id === 'recentViewersGroup'? 'Users that registered to your surgeries during the last month' : ''}
                data={group.members}
                email
                deletable={(group._id === 'recentViewersGroup' || group._id === 'subscribersGroup')? false : true}
                deleteText="Delete from Group"
                deleteType="from-group"
                groupID={group._id}
                onDeleteFromGroup={() => {getGroup(match.params.id)}}
                onViewClick={handleView}
                pageSize={10}/>}

            {(group && isAddVisible) && <AddGroupMembersModal
                group={group}
                visible={isAddVisible}
                onHide={handleHideAdd}
                onSubmit={handleSubmit}/>}
        </div>
    )
}

export default withRouter(Group);

const useStyles = makeStyles({
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    addBtn: {
        color: '#fff',
        float: 'right',
        fontSize: '14px',
        padding: '7px 30px',
        textTransform: 'none',
        '&:focus': {
            outline: 'none'
        }
    }
});

const getSubTitle = (group) => {
    if(group._id === 'subscribersGroup') return `You don't have any subscribers`;
    if(group._id === 'recentViewersGroup') return `You don't have any viewers in last month`;

    return 'Add members to this group using the button above'    
}