import React, {useState, useEffect} from 'react';
import {makeStyles, Button, Modal, Paper} from '@material-ui/core';
import cogoToast from 'cogo-toast';

export default(props) => {
    const emptyForm = {
        title: ''
    };
    const classes = useStyles();
    const [form,
        setForm] = useState(emptyForm);

    useEffect(() => {
        setForm(emptyForm);
    }, [props.visible]);

    const handleInputChange = (e) => {
        setForm({
            ...form,
            ...{
                [e.target.name]: e.target.value
            }
        });
    }

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    }

    const handleSubmit = () => {
        if (anyFieldEmpty(form)) {
            cogoToast.warn('Please enter all information');
            return;
        }

        props.onSubmit({
            title: form
                .title
                .trim()
        });
    }

    return (
        <Modal open={props.visible} onClose={props.onClose} className={classes.modal}>
            <Paper elevation={0} className={classes.modalPaper}>
                <section className={classes.content}>
                    <form className={classes.formContainer}>
                        <h3 className={classes.formTitle}>Create a Group</h3>
                        <input
                            id="form--title"
                            className={classes.formInput}
                            type="text"
                            name="title"
                            placeholder="Enter group name"
                            value={form.title}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            required/>
                    </form>

                    <section
                        style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.actionBtn}>Create</Button>

                        <span className={classes.dismisser} onClick={props.onClose}>Close</span>
                    </section>
                </section>
            </Paper>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '400px',
        maxWidth: '95%',
        margin: '50px auto 0'
    },
    modalPaper: {
        padding: '20px'
    },
    actionBtn: {
        color: '#fff',
        textTransform: 'none',
        padding: '10px 50px',
        display: 'inline-block',
        margin: '10px auto 0 auto'
    },
    dismisser: {
        display: 'inline-block',
        margin: '10px 0',
        cursor: 'pointer',
        fontSize: '14px',
        color: '#333'
    },
    formTitle: {
        textAlign: 'center',
        fontSize: '22px',
        margin: '10px 0 30px 0'
    },
    formInput: {
        width: '100%',
        textAlign: 'center'
    }
}));

const anyFieldEmpty = (fields) => {
    if (!fields.title || !fields.title.trim() === '') {
        return true;
    }

    return false;
}