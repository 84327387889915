export const questions = [
    '1. It was easy to use the SurgTime platform for the telemedicine surgery.',
    '2. The SurgTime platform was reliable for watching and participating in the telemedicine surgery.',
    '3. The SurgTime platform allowed for clear visualization of normal anatomy and variants vs surgical pathology.',
    '4. The audio during the SurgTime video was clear and sufficient.',
    '5. The image resolution of the video was clear and sufficient.',
    '6. There was no perceptible time lag between motions in the operating room.',
    '7. The SurgTime platform allowed for clear observation of surgical technique.',
    '8. The SurgTime platform did not delay the surgical procedure, i.e. time spent operating.',
    '9. The SurgTime platform did not compromise any safety concerns.',
    '10. Overall, SurgTime was effective as a teaching tool.'
]