import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

export default(props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h2 className={classes.title}>{props.title}</h2>
            {(props.subTitle) && <p className={classes.subTitle}>{props.subTitle}</p>}

            {(props.actionBtnLabel) && <Button
                variant="contained"
                color="primary"
                className={classes.actionBtn}
                onClick={props.onActionBtnClick}>
                {props.actionBtnLabel}
            </Button>}
        </div>
    );
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#fff',
        justifyContent: 'center',
        borderRadius: '5px',
        marginBottom: 25
    },
    title: {
        fontSize: '30px',
        fontWeight: 600,
        color: '#353d57',
        margin: 0,
        maxWidth: '90%',
        textAlign: 'center'
    },
    subTitle: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1.13,
        color: '#6e787c',
        margin: '30px 0 0 0'
    },
    actionBtn: {
        fontSize: '14px',
        lineHeight: '1.21',
        color: '#fff',
        margin: '30px 0 0 0',
        width: 250,
        height: 40,
        textTransform: 'capitalize'
    }
});