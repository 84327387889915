import React from 'react';

const Privacy = (props) => {
  return (
    <div>
      <h1>Privacy Notice</h1>
    </div>
  )
}

export default Privacy;