import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import Avatar from 'react-avatar';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons'

import renderNavBarItem from 'components/NavBarItems';
import Util from 'service/util';

const MobileMenu = ({loggedIn, user, setHideMethod, onLogout, history}) => {
    const classes = useStyles();
    const [isVisible,
        setIsVisible] = useState(false);

    const handleShowMobileMenu = () => {
        setIsVisible(true);
        //TODO: Also set overflow hidden to body
    }

    const handleHideMobileMenu = () => {
        setIsVisible(false);
    }

    const renderMenuItems = ({loggedIn, user}) => {
        if (!loggedIn) {
            return (
                <React.Fragment>
                    {renderNavBarItem('home')}
                    {renderNavBarItem('about')}
                    {renderNavBarItem('product')}
                    {renderNavBarItem('signup')}
                    {renderNavBarItem('login')}
                </React.Fragment>
            );
        }

        const fullName = `${user.firstName} ${user.lastName}`;

        return (
            <section onClick={() => handleHideMobileMenu()} style={{width: '100%'}}>
                <div className={classes.menuProfile} onClick={() => history.push('/app')}>
                    <Avatar
                        src={Util.getDisplayableAvatar(user.profileImg)}
                        name={fullName}
                        round={true}
                        size='130'/>
                    <div className={classes.menuProfileName}>{Util.getFullNameWithTitle(user)}</div>
                </div>

                {renderNavBarItem('home')}
                {renderNavBarItem('about')}
                {renderNavBarItem('dashboard')}
                {renderNavBarItem('surgeries')}

                {/* Viewer only */}
                {(user.role === 'user') && (
                    <React.Fragment>
                        {renderNavBarItem('pastSurgeries')}
                        {renderNavBarItem('surgeonsIFollow')}
                    </React.Fragment>
                )}

                {/* Broadcaster only */}
                {(user.role === 'broadcaster') && renderNavBarItem('newSurgery')}

                {/* Broadcaster and Admin */}
                {(user.role === 'broadcaster' || user.role === 'admin') && (
                    <React.Fragment>
                        {renderNavBarItem('archivedSurgeries')}
                        {renderNavBarItem('groups')}
                    </React.Fragment>
                )}

                {/* Admin only */}
                {(user.role === 'admin') && (
                    <React.Fragment>
                        {renderNavBarItem('adminBroadcasters')}
                        {renderNavBarItem('adminViewers')}
                    </React.Fragment>
                )}

                {renderNavBarItem('myProfile')}
                {renderNavBarItem('logout', onLogout)}
            </section>
        );
    }

    return (
        <div>
            <span className={classes.menuOpener} onClick={handleShowMobileMenu}>MENU</span>

            {isVisible && (
                <div className={classes.menuContainer}>
                    <section className={classes.menuItems} onClick={() => handleHideMobileMenu()} style={{width: '100%'}}>
                        {renderMenuItems({loggedIn, user})}
                    </section>

                    <div className={classes.menuCloser} onClick={handleHideMobileMenu}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default withRouter(MobileMenu);

const useStyles = makeStyles((theme) => ({
    menuOpener: {
        padding: '5px 20px',
        color: '#039de1',
        fontWeight: '500',
        fontSize: '22px',
        border: '2px solid #039de1',
        borderRadius: '5px'
    },
    menuCloser: {
        position: 'absolute',
        top: '20px',
        right: '40px',
        fontSize: '36px'
    },
    menuContainer: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        backgroundColor: '#fff'
    },
    menuProfile: {
        marginBottom: '30px'
    },
    menuProfileName: {
        fontSize: '22px',
        marginTop: '20px'
    },
    menuItems: {
        textAlign: 'center',
        overflowY: 'auto',
        height: '100%',
        padding: '100px 0 40px 0',
        width: '100%',
    }
}));