import React, { Component } from "react";
import moment from "moment";
import axios from "axios";

class AdminCreateMeeting extends Component {
  state = {
    topic: "",
    description: "",
    startDate: "",
    startTime: "12:00",
    durationHr: 0,
    durationMin: 0,
    hostVideo: "true",
    participantVideo: "false",
    record: false,
    buttonAbility: true,
    image: "",
    imageList: []
  };

  componentDidMount = () => {
    this.initForm();
  };

  async initForm() {
    let broadcasterList;
    let imageList;
    const date = moment().format("YYYY-MM-DD");

    try {
      broadcasterList = await axios.get("/api/admin/broadcaster");
      imageList = await axios.get("/api/admin/allimages");
    } catch (err) {
      console.log(err);
    }
    if (this.props.isOpen) {
      this.setState({
        startDate: date,
        broadcasterList: broadcasterList.data,
        imageList: imageList.data
      });
    }
  }

  handleInputChange = event => {
    let { type, value, name, checked } = event.target;
    value = type === "checkbox" ? checked : value;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ buttonAbility: false });
    const ISO_8601 = moment(
      `${this.state.startDate}T${this.state.startTime}:00`,
      moment.ISO_8601
    );
    const meetingInfo = {
      topic: this.state.topic,
      description: this.state.description,
      start_time: ISO_8601.utc().format(),
      broadcaster: this.state.broadcaster,
      image: this.state.image,
      duration: this.state.durationHr * 60 + parseInt(this.state.durationMin),
      settings: {
        host_video: this.state.hostVideo,
        participant_video: this.state.participantVideo,
        auto_recording: this.state.record === true ? "local" : "none"
      }
    };
    axios.post("/api/admin/surgeries/new", meetingInfo).then(res => {
      this.setState({
        topic: "",
        description: "",
        startDate: moment().format("YYYY-MM-DD"),
        startTime: "12:00",
        durationHr: 0,
        durationMin: 0,
        hostVideo: "true",
        participantVideo: "false",
        record: false,
        buttonAbility: true,
        broadcaster: "",
        image: ""
      });
      this.props.handleClose();
    });
  };

  render() {
    const { topic, description, image } = this.state;
    const isEnabled = topic.length > 2 && description.length > 2 && image.length > 2;
    return (
      <div>
        <h1 className="h3">Schedule Surgery</h1>
        <form className="schedule-surgery__form">
          <label htmlFor="form--topic">
            <span>Procedure:</span>
            <input
              id="form--topic"
              type="text"
              name="topic"
              value={this.state.topic}
              onChange={this.handleInputChange}
              maxLength="30"
              placeholder="Max: 30 characters"
            />
          </label>
          <label htmlFor="form--description">
            <span>Case Information:</span>
            <textarea
              id="form--description"
              name="description"
              value={this.state.description}
              onChange={this.handleInputChange}
            >
              Description
            </textarea>
          </label>
          <label htmlFor="form--date">
            <span>Select Date:</span>
            <input
              id="form--date"
              type="date"
              name="startDate"
              value={this.state.startDate}
              onChange={this.handleInputChange}
            />
          </label>
          <label htmlFor="form--time">
            <span>Select Time:</span>
            <input
              id="form--time"
              type="time"
              name="startTime"
              value={this.state.startTime}
              onChange={this.handleInputChange}
            />
          </label>
          <label htmlFor="form--duration">
            <span>Duration:</span>
            <select
              name="durationHr"
              value={this.state.durationHr}
              onChange={this.handleInputChange}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </select>
            Hr
            <select
              name="durationMin"
              value={this.state.durationMin}
              onChange={this.handleInputChange}
            >
              <option value="0">0</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
            </select>
            Min
          </label>

          <label
            htmlFor="hostVideo"
            name="hostVideo"
            onChange={this.handleInputChange}
          >
            <span>Host Video:</span>
            <input name="hostVideo" type="radio" value={true} defaultChecked />
            On
            <input name="hostVideo" type="radio" value={false} />
            Off
          </label>

          <label name="participantVideo" onChange={this.handleInputChange}>
            <span>Participant Video:</span>
            <input name="participantVideo" type="radio" value="true" />
            On
            <input
              name="participantVideo"
              type="radio"
              value="false"
              defaultChecked
            />
            Off
          </label>
          <label>
            Image:
            <select
              name="image"
              value={this.state.image}
              onChange={this.handleInputChange}
            >
            <option value=""></option>
            {this.state.imageList.map(image => (
              <option key={image._id} value={image.name}>{image.name}</option>
            ))}
            </select>
          </label>
          <label htmlFor="record">
            <span>Record & Save To Local Computer:</span>
            <input
              name="record"
              type="checkbox"
              checked={this.state.record}
              onChange={this.handleInputChange}
            />
          </label>
          {this.state.buttonAbility ? (
            <button
              className="schedule-surgery__form__button button button--rounded"
              type="submit"
              onClick={this.handleSubmit}
              disabled={!isEnabled}
            >
              Submit
            </button>
          ) : (
            <button type="button" className="button button--rounded bold">
              Updating...
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default AdminCreateMeeting;
