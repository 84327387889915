import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

class AdminLogin extends Component {
  state = {
    email: '',
    password: '',
    error: false,
    errMsg: ''
  }

  componentDidUpdate(prevProps) {

    if (this.props.state !== prevProps.state) {
      const { role, loggedIn } = this.props.state;

      if(loggedIn) {
        const p = (role === 'admin')? '/admin/dashboard' : '/app';
        this.props.history.push(p);
      }
    }
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    })
  }

  handleSubmit = event => {
    event.preventDefault();

    // TODO: call AuthAPI instead of below
    axios.post('/api/admin/login', {
      email: this.state.email,
      password: this.state.password
    }).then(response => {
      if (response.data.role === 'admin') {
        const data = {
          role: response.data.role,
          loggedIn: true,
          email: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName
        };

        this.props.onLogin(data);
      } else {
        console.log("User is not an administrator.")
        this.setState({
          error: true,
          errMsg: "User is not an administrator."
        })
      }
    }).catch(error => {
      console.log('login error: ', error)
      this.setState({
        error: true,
        errMsg: "No User Found"
      })
    })
  }

  render () {

    return (
        <div className='dashboard'>
          <main className='login wrapper'>
            <section className='section'>
              <header className='header'>
                <h1 className='title'>Admin Login</h1>
              </header>
              <div className='content'>

                <form className="login__form">
                  {this.state.error ? <p>{this.state.errMsg}</p> : null}
                  <input
                    className="sign-up__input"
                    type='text'
                    placeholder='E-mail'
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    autoFocus
                  />
                  <input
                    className="sign-up__input"
                    type='password'
                    placeholder='Password'
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                  <div className='sign-up__meta'>
                  <button className="button button--square" type="submit" onClick={this.handleSubmit}>Sign In</button>

                  <Link to='forgot_password' className="password-reset__link">Forgot Password?</Link>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
    )
  }
}

export default AdminLogin;