import React from 'react';

const AdminIndividualInvitation = props => {
  return (
    <section className='section'>
      <header className='header'>
        <h1 className='title'>Send Invitation To:</h1>
      </header>
      <div className='content'>
        <form className="login__form">
          <input
            type="email"
            name="emailInvitation"
            value={props.emailInvitation}
            onChange={props.handleInputChange}
            placeholder="E-mail"
          />
          <button 
            className="button button--square" 
            type="submit" 
            name="addUser"
            onClick={props.handleIndividualSubmit}
          >
            Send Invitation
          </button>
        </form>
      </div>
    </section>
  )
};

export default AdminIndividualInvitation;