import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button
} from '@material-ui/core';
import cogoToast from 'cogo-toast';
import Avatar from 'react-avatar';

import SectionTitle from 'components/dashboard/SectionTitle';
import EmptyArea from 'components/dashboard/EmptyArea';
import UsersAPI from 'api/users';
import GroupsAPI from 'api/groups';
import Util from 'service/util';

export default(props) => {
    const classes = useStyles();
    const [users,
        setUsers] = useState(null);
    const [page,
        setPage] = React.useState(0);
    const pageSize = props.pageSize || 10;
    const userType = (['broadcasters', 'broadcasters-following'].includes(props.type))
        ? 'broadcaster'
        : 'viewer'

    const getUsers = () => {
        let req = null;

        if (props.type === 'broadcasters') {
            req = UsersAPI.listBroadcasters();
        } else if (props.type === 'recent-broadcasters') {
            req = UsersAPI.listRecentBroadcasters(10);
        } else if (props.type === 'recent') {
            req = UsersAPI.listRecent(10);
        } else if (props.type === 'broadcasters-followed') {
            req = UsersAPI.getFavorites();
        } else {
            req = UsersAPI.list();
        }

        req.then(response => {
            const {data, err} = response;

            if (err || !data) {
                console.error('Error getting users: ', err);
                setUsers([]);
                cogoToast.error(`Problem getting ${userType}s`);
                return;
            }

            setUsers([...data]);
        });
    }

    useEffect(() => {
        if(!props.data) {
            getUsers();
        } else {
            setUsers(props.data);
        }
    }, []);

    useEffect(() => {
        setUsers(props.data);
    }, [props.data]);    

    const [userToDelete,
        setUserToDelete] = useState(null);

    const handleDelete = (user) => {
        setUserToDelete(user);
    }

    const handleCloseDeleteConfirmation = () => {
        setUserToDelete(null);
    }

    const handleConfirmDelete = () => {
        if(props.deleteType === 'from-group') {
            handleDeleteFromGroup();
            return;
        }

        UsersAPI
            .remove(userToDelete._id)
            .then(({err}) => {
                if (err) {
                    cogoToast.error(`Problem deleting this ${userType}`);
                    return;
                }

                setUserToDelete(null);
                cogoToast.success(`Deleted a ${userType}`);
                getUsers();
            });
    }

    const handleDeleteFromGroup = () => {
        GroupsAPI
            .removeMemberFromGroup(props.groupID, userToDelete._id)
            .then(({err}) => {
                if (err) {
                    cogoToast.error('Problem deleting member from group');
                    return;
                }

                setUserToDelete(null);
                cogoToast.success('Deleted a member from the group');
                props.onDeleteFromGroup();
            });
    }    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div>
            <SectionTitle title={props.title} subTitle={props.subTitle} style={props.titleStyle}/> {/*  */}

            {(!users) && <EmptyArea title={`Loading ${userType}s`}/>}

            {(Array.isArray(users) && users.length === 0) && <EmptyArea title={`No ${userType}s`}/>}

            {(Array.isArray(users) && users.length > 0) && <TableContainer className={classes.tableContainer} component={Paper}>
                <Table
                    className={classes.table}
                    style={{
                    ...props.tableStyle
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell size="small" padding="none"></TableCell>
                            <TableCell
                                style={{
                                paddingLeft: 0
                            }}>Name</TableCell>
                            {props.email && (
                                <TableCell>Email</TableCell>
                            )}
                            {(props.roleStatus) && <TableCell>Status</TableCell>}
                            {(props.position) && <TableCell>Position</TableCell>}
                            <TableCell align="right"></TableCell>
                            {(props.deletable) && <TableCell align="right"></TableCell>}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {users
                            .slice(page * pageSize, page * pageSize + pageSize)
                            .map((u) => {
                            return (
                                <TableRow key={u._id} hover>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        size="small"
                                        padding="none"
                                        style={{
                                        textAlign: 'center'
                                    }}>
                                        <Avatar
                                            src={Util.getDisplayableAvatar(u.profileImg)}
                                            name={`${u.firstName} ${u.lastName}`}
                                            round={true}
                                            size='35'/>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                        paddingLeft: 0
                                    }}>
                                        {`${u.firstName} ${u.lastName}`}
                                    </TableCell>
                                    {props.email && (
                                        <TableCell>{u.email}</TableCell>
                                    )}
                                    {(props.roleStatus) && <TableCell>{u.roleStatus === 'inactive'
                                            ? <span className={classes.statusInactive}>Inactive</span>
                                            : <span className={classes.statusActive}>Active</span>}</TableCell>}
                                    {(props.position) && <TableCell style={{textTransform: 'capitalize'}}>{u.position}</TableCell>}
                                    {(props.type !== 'registered-viewers') ? <TableCell
                                        align="right"
                                        className="cursor-pointer hover_color-blue"
                                        onClick={() => props.onViewClick(u)}>View Profile</TableCell> :
                                        <TableCell></TableCell>}
                                    {(props.deletable) && <TableCell
                                        align="right"
                                        className="cursor-pointer hover_color-red"
                                        onClick={() => handleDelete(u)}>{props.deleteText || 'Delete'}</TableCell>}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>

                <TablePagination
                    className={classes.tablePagination}
                    component="div"
                    count={users.length}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    page={page}
                    onChangePage={handleChangePage}/>
            </TableContainer>}

            {/* Delete confirmation modal */}
            {(userToDelete && userToDelete._id) && <Modal
                open={true}
                onClose={handleCloseDeleteConfirmation}
                className={classes.deleteModal}>
                <Paper elevation={0} className={classes.deleteModalPaper}>
                    <p style={{
                        marginBottom: 0
                    }}>Are you sure you want to delete</p>
                    <p>{userToDelete.firstName} {userToDelete.lastName}?</p>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.deleteModalActionBtn}
                            onClick={handleConfirmDelete}>
                            Yes, delete this user
                        </Button>
                    </div>

                    <span
                        className={classes.deleteModalDismiss}
                        onClick={handleCloseDeleteConfirmation}>No, dismiss</span>
                </Paper>
            </Modal>}
        </div>
    );
}

const useStyles = makeStyles({
    tableContainer: {
        marginBottom: 25,
        paddingBottom: 55, //to make space for pagination
        minHeight: 300,
        position: 'relative'
    },
    tablePagination: {
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    deleteModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    deleteModalPaper: {
        maxWidth: '500px',
        padding: '30px 50px',
        textAlign: 'center'
    },
    deleteModalActionBtn: {
        margin: '40px 0 20px 0',
        color: '#fff',
        textTransform: 'none',
        padding: '10px 40px'
    },
    deleteModalDismiss: {
        fontSize: '16px',
        color: '#7b7b7b',
        cursor: 'pointer',
        '&:hover': {
            color: '#444'
        }
    },
    statusInactive: {
        color: '#dc5616'
    },
    statusActive: {
        color: '#64dc27'
    }
});