import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper
} from '@material-ui/core';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVideo} from '@fortawesome/free-solid-svg-icons';
// import Avatar from 'react-avatar';

import SectionTitle from 'components/dashboard/SectionTitle';
import EmptyArea from 'components/dashboard/EmptyArea';
import FeedbackAPI from 'api/feedback';
import Util from 'service/util';

export default(props) => {
    const classes = useStyles();

    const [feedbacks,
        setFeedbacks] = useState(null);
    const [page,
        setPage] = React.useState(0);
    const pageSize = props.pageSize || 10;

    const getFeedbacks = () => {
        FeedbackAPI
            .list()
            .then(response => {
                const {data, err} = response;

                if (err) {
                    setFeedbacks([]);
                    console.error('Error getting feedback: ', err);
                    cogoToast.error('There was a problem getting feedback');
                    return;
                }

                setFeedbacks(data);
            });
    }

    useEffect(() => {
        getFeedbacks();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div>
            <SectionTitle title={props.title}/> {/*  */}

            {(!feedbacks) && <EmptyArea title="Loading broadcast feedbacks"/>}

            {(Array.isArray(feedbacks) && feedbacks.length === 0) && <EmptyArea {...getEmptyAreaProps()}/>}

            {(Array.isArray(feedbacks) && feedbacks.length > 0) && <React.Fragment>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Procedure</TableCell>
                                <TableCell size="small">Procedure Date</TableCell>
                                <TableCell size="small">Feedback Date</TableCell>
                                <TableCell align="right" size="small"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {feedbacks
                                .slice(page * pageSize, page * pageSize + pageSize)
                                .map((fb) => {
                                    return (
                                        <TableRow key={fb.surgery._id} className={classes.tableRow}>
                                            <TableCell component="th" scope="row">
                                                {Util.getFullNameWithTitle(fb.user)}

                                                {(fb.user._id === fb.surgery.broadcaster)
                                                    ? <FontAwesomeIcon
                                                            icon={faVideo}
                                                            title="Broadcaster"
                                                            style={{
                                                            marginLeft: '7px',
                                                            color: '#039ce2',
                                                            fontSize: '14px'
                                                        }}/>
                                                    : ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {fb.surgery.topic}
                                            </TableCell>
                                            <TableCell size="small">{moment
                                                    .tz(fb.surgery.start_time, fb.surgery.timezone)
                                                    .format('M/D/YY')}</TableCell>
                                            <TableCell size="small">{moment
                                                    .tz(fb.created_at)
                                                    .format('M/D/YY')}</TableCell>
                                            <TableCell
                                                align="right"
                                                size="small"
                                                className="cursor-pointer hover_color-blue"
                                                onClick={() => props.onSelect(fb)}>View Answers</TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>

                    <TablePagination
                        className={classes.tablePagination}
                        component="div"
                        count={feedbacks.length}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        page={page}
                        onChangePage={handleChangePage}/>
                </TableContainer>
            </React.Fragment>}
        </div>
    );
}

const getEmptyAreaProps = (role, type) => {
    return {title: "No Feedback for Surgeries", subTitle: "Feedback users leave for surgeries they hosted or watched will appear here"};
}

const useStyles = makeStyles({
    tableContainer: {
        marginBottom: 25,
        paddingBottom: 55, //to make space for pagination
        fontSize: '12px !important',
        minHeight: 300,
        position: 'relative'
    },
    tablePagination: {
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    tableRow: {
        '&:hover': {
            backgroundColor: '#fafafa'
        }
    },
    timeDetail: {
        display: 'inline-block',
        overflow: 'hidden'
    },
    tableRowDetail: {
        marginLeft: '5px',
        fontSize: '10px',
        color: '#363d56',
        border: '1px solid #c1c1c1',
        borderRadius: '50px',
        padding: '3px 7px',
        maxWidth: '95px',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    deleteModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    deleteModalPaper: {
        maxWidth: '500px',
        padding: '30px 50px',
        textAlign: 'center'
    },
    deleteModalActionBtn: {
        margin: '40px 0 20px 0',
        color: '#fff',
        textTransform: 'none',
        padding: '10px 40px'
    },
    deleteModalDismiss: {
        fontSize: '16px',
        color: '#7b7b7b',
        cursor: 'pointer',
        '&:hover': {
            color: '#444'
        }
    }
});