import React from 'react';

export const BroadcasterModalContent = (props) => {
  const { firstName, lastName, email, dob, bio } = props;
  return (
    <>
      <div>First Name (required):</div>
      <input type='text' disabled={true} name='firstName' value={firstName}></input>
      <div>Last Name:</div>
      <input type='text' disabled={true} name='lastName' value={lastName}></input>
      <div>E-mail:</div>
      <input type='text' disabled={true} name='email' value={email}></input>
      <div>DOB:</div>
      <input type='text' disabled={true} name='email' value={dob}></input>
      <div>Bio:</div>
      <textarea type='text' disabled={true} name='email' value={bio}></textarea>
    </>
  )
};