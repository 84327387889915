import React from 'react';

import LogoWhite from 'images/surgtime-logo_1-white.png';

export default() => {
    return (
        <footer className="site-footer">
            <div className="site-footer__container">
                <div className="site-footer__grid">
                    <div className="site-footer__column site-footer__column-1">
                        <img src={LogoWhite} alt="SurgTime Logo" width={120}/>
                    </div>
                    {/* <div className="site-footer__column site-footer__column-2">

                        <nav className="site__footer__list">
                            <Link className="site-footer__anchor" to="/contact">Contact Us</Link>
                            <Link className="site-footer__anchor" to="/tos">Terms Of Service</Link>
                            <Link className="site-footer__anchor" to="/privacy">Privacy</Link>
                        </nav>
                    </div> */}
                </div>
            </div>
        </footer>
    );
}