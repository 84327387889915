import React, { Component } from "react";
import { DashboardSidebar } from "./components/DashboardSidebar";
import Axios from "axios";
import Modal from "../../components/Modal";

class BroadcasterProfile extends Component {
  state = {
    bio: "",
    confirmPassword: "",
    dob: "",
    email: "",
    firstName: "",
    isDisabled: true,
    lastName: "",
    meetsRequirements: true,
    modalToggle: false,
    modalContent: "",
    newPassword: "",
    oldPassword: "",
    userId: null,
  };

  componentDidMount() {
    Axios.get('/api/user/profile').then(user => {
      const {
        bio,
        dob,
        email,
        firstName,
        lastName,
      } = user.data;
      this.setState({
        bio,
        dob,
        email,
        firstName,
        lastName,
      })
    })
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const {
      bio,
      dob,
      firstName,
      lastName,
      email,
      oldPassword,
      newPassword,
      confirmPassword
    } = this.state;
    if (newPassword !== "" && confirmPassword !== "") {
      if (
        newPassword !== confirmPassword ||
        newPassword.length < 6
      ) {
        return this.setState({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
          meetsRequirements: false
        });
      }
    }
    Axios.put("/api/user/profile/update", {
      bio,
      dob,
      firstName,
      lastName,
      email,
      newPassword,
      oldPassword
    })
      .then(res => {
        this.setState({
          meetsRequirements: true,
          modalToggle: true,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
          isDisabled: true
        });
      })
      .catch(err => {
        this.setState({
          modalToggle: true,
          modalContent: err.response.data.Error,
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        });
      });
  };

  hideModal = event => {
    this.setState({
      modalToggle: false,
      modalContent: ""
    });
  };

  makeEditable = event => {
    event.preventDefault();
      this.setState({
        isDisabled: false
      });
  }

  render() {
    if (!this.props.state.loggedIn || this.props.state.role !== "broadcaster") {
      return (
        <div>
          <p>Please Log In</p>
        </div>
      );
    }
    return (
      <div className="dashboard--has-sidebar">
        <DashboardSidebar
          {...this.props}
        />
        <div className="dashboard">
          <main className="wrapper">
            <section className="section">
              <div className="content">
                <div className="admin-dashboard__grid">
                  <div className="latest-posts">
                    <header className="d-flex w-100 justify-content-between align-items-center mb-4">
                      <h1 className="title mb-0 h4">User Profile</h1>
                    </header>

                    <form className="form-horizontal">
                      <div className="form-group form-row">
                        <label className="col-lg-3 control-label">
                          First name:
                        </label>
                        <div className="col-lg-9">
                          <input
                            className="form-control"
                            name="firstName"
                            type="text"
                            value={this.state.firstName}
                            onChange={this.handleInputChange}
                            disabled={this.state.isDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-group form-row">
                        <label className="col-lg-3 control-label">
                          Last name:
                        </label>
                        <div className="col-lg-9">
                          <input
                            className="form-control"
                            name="lastName"
                            type="text"
                            value={this.state.lastName}
                            onChange={this.handleInputChange}
                            disabled={this.state.isDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-group form-row">
                        <label className="col-lg-3 control-label">Email:</label>
                        <div className="col-lg-9">
                          <input
                            className="form-control"
                            name="email"
                            type="text"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            disabled={this.state.isDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-group form-row">
                        <label className="col-lg-3 control-label">D.O.B.:</label>
                        <div className="col-lg-9">
                          <input
                            className="form-control"
                            name="dob"
                            type="text"
                            value={this.state.dob}
                            onChange={this.handleInputChange}
                            disabled={this.state.isDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-group form-row">
                        <label className="col-lg-3 control-label">Bio:</label>
                        <div className="col-lg-9">
                          <textarea
                            className="form-control"
                            name="bio"
                            type="text"
                            value={this.state.bio}
                            onChange={this.handleInputChange}
                            disabled={this.state.isDisabled}
                          />
                        </div>
                      </div>
                      <header className="d-flex w-100 justify-content-between align-items-center mb-4">
                        <h1 className="title mb-0 h4">Change Password</h1>
                      </header>
                      <div className="form-group form-row">
                        <label className="col-md-3 control-label">
                          Old password:
                        </label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            name="oldPassword"
                            type="password"
                            value={this.state.oldPassword}
                            onChange={this.handleInputChange}
                            disabled={this.state.isDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-group form-row">
                        <label className="col-md-3 control-label">
                          New password:
                        </label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            name="newPassword"
                            type="password"
                            value={this.state.newPassword}
                            onChange={this.handleInputChange}
                            disabled={this.state.isDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-group form-row">
                        <label className="col-md-3 control-label">
                          Confirm new password:
                        </label>
                        <div className="col-md-9">
                          <input
                            className="form-control"
                            name="confirmPassword"
                            type="password"
                            value={this.state.confirmPassword}
                            onChange={this.handleInputChange}
                            disabled={this.state.isDisabled}
                          />
                          {!this.state.meetsRequirements ? (
                            <div style={{ color: "red" }}>
                              Passwords must match and be min 6 characters long
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group form-row">
                        <label className="col-md-3 control-label" />
                        <div className="col-md-9">
                          {this.state.isDisabled ?
                              <input
                              type="submit"
                              className="button button--rounded mt-3"
                              value="Edit"
                              onClick={this.makeEditable}
                              />
                              :
                              <input
                                type="submit"
                                className="button button--rounded mt-3"
                                value="Update"
                                onClick={this.handleSubmit}
                                disabled={this.state.isDisabled}
                              />
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <Modal show={this.state.modalToggle} handleClose={this.hideModal}>
          {this.state.modalContent === "" ? (
            <div>Update complete</div>
          ) : (
            <div>Error: {this.state.modalContent}</div>
          )}
        </Modal>
      </div>
    );
  }
}

export default BroadcasterProfile;
