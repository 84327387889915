import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import cogoToast from 'cogo-toast';
import Avatar from 'react-avatar';
import {withRouter} from 'react-router-dom';

import SectionTitle from 'components/dashboard/SectionTitle';
import EmptyArea from 'components/dashboard/EmptyArea';
import UsersAPI from 'api/users';
import Util from 'service/util';

export default withRouter((props) => {
    const classes = useStyles();

    const [subscribers,
        setSubscribers] = useState(null);
    const [page,
        setPage] = React.useState(0);
    const pageSize = props.pageSize || 7;

    useEffect(() => {
        getSubscribers();
    }, []);

    const getSubscribers = async() => {
        try {
            const res = await UsersAPI.getBroadcasterSubscribers();
            const {data, err} = res;

            if (err) {
                throw new Error('Error getting subscribers: ', err);
            }

            let s = data;
            if (props.exclude && props.exclude.length > 0) {
                s = data.filter(d => !props.exclude.includes(d._id));
            }

            let subs = (s && s.length)
                ? s.reverse()
                : []; //Reverse so newest are first

            setSubscribers(subs);
        } catch (err) {
            console.error('Error getting subscribers: ', err);
            cogoToast.error('Problem getting subscribers');
            setSubscribers([]);
        }
    }

    const [selectedSubscribers,
        setSelectedSubscribers] = useState(props.selected || []);

    const getSubIndex = (ID) => {
        if (!selectedSubscribers || selectedSubscribers.length === 0) {
            return -1;
        }

        return selectedSubscribers.findIndex(s => s._id === ID);
    }

    const handleSelect = (subscriber) => {
        let s = selectedSubscribers;
        const i = getSubIndex(subscriber._id);

        if (i === -1) {
            s.push(subscriber);
        } else {
            s.splice(i, 1);
        }

        setSelectedSubscribers([...s]);
        props.onUpdateSelection([...s])
    }

    const handleSelectAll = (event) => {
        const s = event.target.checked
            ? [...subscribers]
            : [];
        setSelectedSubscribers(s);
        props.onUpdateSelection(s);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleViewAll = () => {
        props
            .history
            .push('/app/g/subscribersGroup');
    }

    const rowCount = subscribers
        ? subscribers.length
        : 0;
    let numSelected = selectedSubscribers.length;
    const hasSubscribers = Array.isArray(subscribers) && subscribers.length > 0;

    return (
        <div>
            <section className={classes.titleContainer}>
                <SectionTitle title={props.title}/> {/*  */}

                {(hasSubscribers && props.showViewAll) && <span className={classes.viewAll} onClick={handleViewAll}>View all</span>}
            </section>

            {(!subscribers) && <EmptyArea title="Loading subscribers"/>}

            {(Array.isArray(subscribers) && subscribers.length === 0) && <EmptyArea title={props.noSubsMessage || 'No Subscribers'}/>}

            {(hasSubscribers) && <TableContainer className={classes.tableContainer} component={Paper}>
                <Table
                    className={classes.table}
                    style={{
                    ...props.tableStyle
                }}>
                    <TableHead>
                        <TableRow>
                            {props.selection && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={numSelected > 0 && numSelected < rowCount}
                                        checked={rowCount > 0 && numSelected === rowCount}
                                        onChange={handleSelectAll}
                                        color="primary"
                                        inputProps={{
                                        'aria-label': 'select all subscribers'
                                    }}/>
                                </TableCell>
                            )}
                            <TableCell size="small" padding="none"></TableCell>
                            <TableCell
                                style={{
                                paddingLeft: 0
                            }}>Name</TableCell>
                            {props.email && (
                                <TableCell>Email</TableCell>
                            )}
                            <TableCell align="right">Position</TableCell>
                            {props.profile && (
                                <TableCell align="right"></TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {subscribers
                            .slice(page * pageSize, page * pageSize + pageSize)
                            .map((s) => {
                                const isSelected = getSubIndex(s._id) !== -1
                                    ? true
                                    : false;

                                return (
                                    <TableRow
                                        key={s._id}
                                        hover
                                        role="checkbox"
                                        selected={isSelected}
                                        aria-checked={isSelected}>
                                        {props.selection && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isSelected}
                                                    onClick={() => handleSelect(s)}
                                                    inputProps={{
                                                    'aria-labelledby': s._id
                                                }}/>
                                            </TableCell>
                                        )}
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            size="small"
                                            padding="none"
                                            style={{
                                            textAlign: 'center'
                                        }}>
                                            <Avatar
                                                src={Util.getDisplayableAvatar(s.profileImg)}
                                                name={`${s.firstName} ${s.lastName}`}
                                                round={true}
                                                size='35'/>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                            paddingLeft: 0
                                        }}>
                                            {`${s.firstName} ${s.lastName}`}
                                        </TableCell>
                                        {props.email && (
                                            <TableCell>{s.email}</TableCell>
                                        )}
                                        <TableCell align="right">{s.position}</TableCell>
                                        {props.profile && (
                                            <TableCell
                                                align="right"
                                                className="cursor-pointer hover_color-blue"
                                                onClick={() => props.onViewProfile(s)}>View Profile</TableCell>
                                        )}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>

                <TablePagination
                    className={classes.tablePagination}
                    component="div"
                    count={subscribers.length}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    page={page}
                    onChangePage={handleChangePage}/>
            </TableContainer>}
        </div>
    );
});

const useStyles = makeStyles({
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    viewAll: {
        fontSize: '14px',
        '&:hover': {
            cursor: 'pointer',
            color: '#0ca3d9'
        }
    },
    tableContainer: {
        marginBottom: 25,
        minHeight: 300,
        paddingBottom: 55, //to make space for pagination
        position: 'relative'
    },
    tablePagination: {
        position: 'absolute',
        bottom: 0,
        right: 0
    }
});
