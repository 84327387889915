import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Grid, makeStyles, Button, Tooltip, Checkbox, FormControlLabel, Popover, Typography} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons'

import TimezoneSelect from 'components/TimezoneSelect';
import ImagesAPI from 'api/images';
import Util from 'service/util';

const defaultForm = {
    topic: "",
    description: "",
    startDate: moment().format("YYYY-MM-DD"),
    startTime: "12:00",
    durationHr: 0,
    durationMin: 0,
    timezone: "America/Los_Angeles",
    image: "_",
    imageList: [],
    isPublic: false
};


export default (props) => {
    const classes = useStyles();
    const [form,
        setForm] = useState(props.mode === 'create'? defaultForm : props.form);

    const [invalidFields, setInvalidFields] = useState({});

    const getImages = async() => {
        ImagesAPI
            .getAll()
            .then(response => {
                const {data, err} = response;

                if (err) {
                    console.error('Error getting images: ', err);
                    return;
                }

                setForm({
                    ...form,
                    imageList: data
                });
            });
    }

    useEffect(() => {
        getImages();
    }, []);

    useEffect(() => {
        if(props.invoker === 'start-save') {
            handleStartSubmit();
        }
    }, [props.invoker]);

    const handleInputChange = (event) => {
        let {type, value, name, checked} = event.target;
        value = type === "checkbox"
            ? checked
            : value;

        setForm({
            ...form,
            ...{
                [name]: value
            }
        });
    }

    const isFormValid = () => {
        let invalid = {};

        ['topic', 'description', 'durationHr'].map((field) => {
            if(Util.isFieldEmpty(form[field]) || (field === 'durationHr' && form[field] === '0')){
                invalid[field] = 'true';
            }

            return null;
        });

        setInvalidFields(invalid);

        if(Object.keys(invalid).length > 0) {
            return false;
        }

        return true;
    }

    const handleStartSubmit = () => {
        if (!isFormValid()) {
            return;
        }

        props.onStartSubmit(form);
    }

    const handleSubmit = (e) => {
        if (!isFormValid()) {
            return;
        }

        props.onSubmit(form);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handlePopoverClose = () => {
        setAnchorEl(null);
      };

    const open = Boolean(anchorEl);

    const id = open
        ? 'mouse-over-popover'
        : undefined;

    return (
        <React.Fragment>
            <Grid
            container
            className={classes.gridContainer}
            spacing={6}
            justify='space-between'>
            <Grid item xs={12} md={8} className={classes.gridItem}>
                {(props.mode === 'edit') && <div style={{width: '150px', marginTop: '10px'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.actionBtn}
                        onClick={handleSubmit}>
                            {props.isLoading
                            ? 'Saving...'
                            : 'Save'}
                    </Button>
                </div>}
                <form className={classes.form}>
                    <label htmlFor="form--topic">
                        <span className={classes.formLabelText}>Procedure Name</span>
                        <input
                            id="form--topic"
                            className={`${classes.formInput} ${invalidFields['topic']? '--invalid' : ''}`}
                            type="text"
                            name="topic"
                            value={form.topic}
                            onChange={handleInputChange}
                            autoFocus
                            required/>
                        {(invalidFields['topic']) && <span className={classes.formInputError}>Procedure name can't be empty</span>}
                    </label>

                    {form.isComplete && <label htmlFor="form--youtubeLink">
                        <span className={classes.formLabelText}>
                            YouTube URL
                            <Tooltip disableFocusListener title="Copy the URL from YouTube and paste it here">
                                <Button className={classes.ytInstructionButton}>
                                    <FontAwesomeIcon icon={faQuestionCircle}/>
                                </Button>
                            </Tooltip>
                        </span>
                        <input
                            id="form--youtubeLink"
                            className={classes.formInput}
                            type="text"
                            name="youtubeLink"
                            value={form.youtubeLink}
                            onChange={handleInputChange}/>
                    </label>}

                    <label htmlFor="form--description">
                        <span className={classes.formLabelText}>Case Details</span>
                        <textarea
                            id="form--description"
                            className={`${classes.formInput} ${invalidFields['description']? '--invalid' : ''}`}
                            style={{
                                paddingTop: '15px'
                            }}
                            name="description"
                            value={form.description}
                            onChange={handleInputChange}
                            required>
                            Case details
                        </textarea>
                        {(invalidFields['description']) && (<span className={classes.formInputError}>Case details can't be empty</span>)}
                    </label>
                </form>
            </Grid>

            <Grid item xs={12} md={4} className={classes.gridItem}>
                <label htmlFor="form--date">
                    <span className={classes.formLabelText}>Surgery Date</span>
                    <input
                        id="form--date"
                        disabled={form.isComplete}
                        className={classes.formInput}
                        type="date"
                        name="startDate"
                        value={form.startDate}
                        onChange={handleInputChange}
                        required/>
                </label>
                <div className={classes.verticalItems}>
                    <label htmlFor="form--time" style={{flex: '1'}}>
                        <span className={classes.formLabelText}>Time</span>
                        <input
                            id="form--time"
                            disabled={form.isComplete}
                            className={classes.formInput}
                            type="time"
                            name="startTime"
                            value={form.startTime}
                            onChange={handleInputChange}
                            required/>
                    </label>
                    <label htmlFor="form--time" style={{flex: '2', marginLeft: '10px'}}>
                        <span className={classes.formLabelText}>Timezone</span>

                        <TimezoneSelect
                            disabled={form.isComplete}
                            selected={form.timezone}
                            className={classes.formInput}
                            name="timezone"
                            onChange={handleInputChange}/>
                    </label>
                </div>
                <label htmlFor="form--duration">
                    <span className={classes.formLabelText}>Duration</span>
                    <section className={classes.durationContainer}>
                        <section className={classes.durationContainerItem}>
                            <select
                                name="durationHr"
                                disabled={form.isComplete}
                                className={`${classes.durationContainerItemSelect} ${invalidFields['durationHr']? '--invalid' : ''}`}
                                value={form.durationHr}
                                onChange={handleInputChange}
                                required>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </select>
                            Hr
                        </section>

                        <section className={classes.durationContainerItem}>
                            <select
                                name="durationMin"
                                disabled={form.isComplete}
                                className={classes.durationContainerItemSelect}
                                value={form.durationMin}
                                onChange={handleInputChange}
                                required>
                                <option value="0">0</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                            </select>
                            Min
                        </section>
                    </section>

                    {(invalidFields['durationHr']) && (<span className={classes.formInputError}>Duration can't be zero</span>)}
                </label>

                <label>
                    <span className={classes.formLabelText}>Choose Your Surgery Graphic</span>
                    <select
                        name="image"
                        disabled={form.isComplete}
                        value={form.image}
                        className={classes.formInput}
                        onChange={handleInputChange}
                        required>
                        <option disabled value="_">Select a graphic</option>
                        {form
                            .imageList && form
                            .imageList
                            .map(image => (
                                <option key={image._id} value={image.name}>
                                    {image.name}
                                </option>
                            ))}
                        <option disabled value="__">-------</option>
                        <option value="">No relevant graphic</option>
                    </select>
                </label>
                <div>
                    <FormControlLabel
                        classes={{
                            label: classes.formLabel
                        }}
                        control={
                        <Checkbox type="checkbox"
                        color="primary"
                        name="isPublic"
                        checked={form.isPublic}
                        onChange={handleInputChange} />}
                        label="Publicly available"
                        />
                        <span
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}>
                        <FontAwesomeIcon icon={faQuestionCircle} className={classes.questionIcon}/>
                        <Popover
                           id={id}
                           open={open}
                           anchorEl={anchorEl}
                           onClose={handlePopoverClose}
                           anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          disableRestoreFocus>
                               <Typography
                                className={classes.hoverText}>If you would like to allow SurgTime to display some information about the broadcast to the public, check this box</Typography>
                           </Popover>
                        </span>
                </div>
            </Grid>

        </Grid>

        <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            onClick={handleSubmit}>
            {props.isLoading
                ? 'Saving...'
                : 'Save'}
        </Button>
    </React.Fragment>
    );
}

const formInputStyle = {
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: '5px',
    marginBottom: '30px',
    width: '100%',
    fontSize: '16px',
    appearance: 'none',
    padding: '.5em 1em',
    '&:focus': {
        outline: 'none',
        boxShadow: '0px 3px 30px 4px #e6e6e6'
    },
    '&.--invalid': {
        border: '1px solid #e63500',
    }
};

const useStyles = makeStyles((theme) => ({
    instructionText: {
        marginTop: '-10px',
        fontSize: '16px',
        marginBottom: '40px'
    },
    ytInstructionButton: {
        width: 'auto',
        padding: '0',
        background: 'transparent',
        margin: '0',
        minWidth: '0',
        marginLeft: '5px',
        marginTop: '-3px',
        '&:focus': {
            outline: 'none'
        }
    },
    gridContainer: {},
    gridItem: {
        display: 'flex',
        flexDirection: 'column'
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    actionBtn: {
        color: '#fff',
        textTransform: 'none',
        padding: '10px 40px',
        marginTop: '0'
    },
    formLabelText: {
        display: 'block',
        fontSize: '16px',
        marginBottom: '20px',
        color: '#353d57'
    },
    formInput: {
        ...formInputStyle
    },
    formInputError: {
        marginTop: '-25px',
        display: 'block',
        fontSize: '15px',
        color: '#e63500',
        marginBottom: '3px'
    },
    verticalItems: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    durationContainer: {
        display: 'flex',
        marginBottom: '30px'
    },
    durationContainerItem: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '20px'
    },
    durationContainerItemSelect: {
        ...formInputStyle,
        margin: '0 !important',
        width: '70%'
    },
    formLabel: {
        width: '130px',
        fontSize: '16px',
        color: '#353d57'
    },
    questionIcon: {
        marginBottom: '5px',
        marginLeft: '-10px',
    },
    hoverText: {
        fontSize: '13px',
        padding: '10px',
    }
}));