import React, {useState} from 'react';
import {makeStyles, Button} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';

import InvitesModal from 'components/dashboard/InvitesModal';

export default(props) => {
    const classes = useStyles();
    const [isSendInvitesVisible,
        setIsSendInvitesVisible] = useState(false);

    const handleSendInvites = () => {
        setIsSendInvitesVisible(true);
    }

    const handleHideSendInvites = () => {
        setIsSendInvitesVisible(false);
    }

    return (
        <React.Fragment>
            <Button
                className={classes.inviteBtn}
                disabled={props.disabled}
                style={{...props.style}}
                variant='contained'
                color='primary'
                size={props.btnSize || 'small'}
                onClick={handleSendInvites}>
                <span>
                    <FontAwesomeIcon icon={faPaperPlane}/>
                    <span style={{
                        marginLeft: '5px'
                    }}>
                        Send Invitations</span>
                </span>
            </Button>
            {(isSendInvitesVisible) && <InvitesModal visible={true} surgery={props.surgery} isFullName={props.isFullName} onHide={handleHideSendInvites}/>}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    inviteBtn: {
        color: '#fff',        
        textTransform: 'none',
    }
}));