import React, {useState, useEffect} from 'react';
import {withRouter, Switch, Link, Route} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import cogoToast from 'cogo-toast';

import SurgeriesAPI from 'api/surgeries';
import SurgeriesSvc from 'service/surgeries';
import {SurgeriesGrid} from 'pages/app/Surgeries';
import UsersAPI from 'api/users';
import UserProfileCard from 'components/dashboard/UserProfileCard';
import UserProfileCardSm from 'components/dashboard/UserProfileCardSm';

import "./Explore.scss";

const Explore = (props) => {
  const [surgeries,
    setSurgeries] = useState([]);
  const [broadcasters,
    setBroadcasters] = useState([]);

  const getSurgeries = () => {
    SurgeriesAPI
      .listForViewer()
      .then(response => {
        const {data, err} = response;

        if (err) {
          setSurgeries({live: [], upcoming: [], previous: []});
          console.error('Error getting surgeries: ', err);
          cogoToast.error('Problem getting surgeries');
          return;
        }

        const live = SurgeriesSvc.getLiveSurgeries(data) || [];
        const upcoming = SurgeriesSvc.getUpcomingSurgeries(data) || [];
        let previous = SurgeriesSvc.getPreviousSurgeries(data) || [];
        const all = []
          .concat(live)
          .concat(upcoming)
          .concat(previous.reverse());
        setSurgeries(all);
      });
  }

  const getBroadcasters = () => {
    UsersAPI
      .getDiscoverableBroadcasters()
      .then(response => {
        const {data, err} = response;

        if (err || !data) {
          console.error('Error getting broadcasters: ', err);
          setBroadcasters([]);
          cogoToast.error('Problem getting broadcasters');
          return;
        }

        setBroadcasters([...data]);
      });
  }

  useEffect(() => {
    getSurgeries();
    getBroadcasters();
  }, []);

  const handleViewUser = (u) => {
    const p = u.role === 'user'
      ? 'v'
      : 'b';
    props
      .history
      .push(`/app/${p}/` + u.id, {user: u});
  }

  const BroadcasterList = ({items}) => {
    if (!items || !items.length) {
      return <p>There are no broadcasters</p>;
    }

    return <div>
      {items.map((b, i) => <div key={i}><UserProfileCardSm user={b} onViewProfile={handleViewUser}/></div>)}
    </div>
  }

  const Overview = () => {
    return <div className="ExploreContent">
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <h3>Surgeries</h3>
          <SurgeriesGrid data={surgeries} showMore={false} type="upcoming"/>
        </Grid>
        <Grid item xs={12} md={4}>
          <h3 style={{
            marginBottom: '30px'
          }}>Broadcasters</h3>
          <BroadcasterList items={broadcasters}/>
        </Grid>
      </Grid>
    </div>;
  }

  const Surgeries = () => {
    return <div className="ExploreContent">
      <h3>Surgeries</h3>
      <SurgeriesGrid data={surgeries} type="upcoming"/>
    </div>;
  }

  const Broadcasters = () => {
    return <div className="ExploreContent">
      <h3>Broadcasters</h3>
      <div>
        {broadcasters !== []
          ? (
            <Grid container spacing={3}>
              {broadcasters.map((u, k) => (
                <Grid item xs={12} md={3} key={k}>
                  <UserProfileCard user={u} onViewProfile={handleViewUser}/>
                </Grid>
              ))}
            </Grid>
          )
          : null}
      </div>
    </div>;
  }

  const Tabs = () => {
    return (
      <div>
        <div className="ExploreNavbar">
          <Link to="/app/explore">Overview</Link>
          <Link to="/app/explore/surgeries">Surgeries</Link>
          <Link to="/app/explore/broadcasters">Broadcasters</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="ExploreRoot">
      <Tabs/>

      <Switch>
        <Route exact path="/app/explore">
          <Overview/>
        </Route>
        <Route path="/app/explore/surgeries">
          <Surgeries/>
        </Route>
        <Route path="/app/explore/broadcasters">
          <Broadcasters/>
        </Route>
      </Switch>
    </div>
  )
}

export default withRouter(Explore);

// const useStyles = makeStyles((theme) => ({ }));