import React from 'react';
import {withRouter} from 'react-router-dom';

import SurgeriesTable from 'components/dashboard/SurgeriesTable';

const PreviousSurgeries = ({history}) => {
    const onClickSurgery = (s) => {
        history.push('/app/surgery/' + s._id, {surgery: s});
        // TODO: modify API so it returns only those surgs where user was registered
    }

    return (
        <div>
            <SurgeriesTable
                userRole="user"
                type="past"
                title="Archived surgeries"
                broadcaster
                playback
                pageSize={10}
                onEditClick={onClickSurgery}/>
        </div>
    )
}

export default withRouter(PreviousSurgeries);